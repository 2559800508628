import styled from "styled-components";

const ScanChartsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  max-width: calc(100vw - 250px);
  > div {
  }
`;

export { ScanChartsStyled };
