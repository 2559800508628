import styled from "styled-components";

const DeleteEntityButtonStyled = styled.div`
  .ant-btn.ant-btn-link.ant-btn-icon-only {
    width: auto;
    height: 26px;
    &:hover {
      border-color: transparent !important;
    }
    &:before {
      background-color: transparent;
    }
  }
`;

export { DeleteEntityButtonStyled };
