/* eslint-disable @typescript-eslint/no-useless-constructor */
import ProductScansService from "./product-scan.service";
import ProductScans from "./product-scan.model";
import PageStore from "../page.store";

class ProductScansStore extends PageStore {
  constructor(productScansService: ProductScansService) {
    super(productScansService);
  }

  url(): string {
    return "/product-scans";
  }

  get model(): any {
    return ProductScans;
  }

  scanPageAndGetLandingPage = (group_id: string, qr_id: number) => {
    return this.apiService.scanPageAndGetLandingPage(group_id, qr_id);
  };

  createScan = (data: any) => {
    this.create(data).save();
  };
}

export default ProductScansStore;
