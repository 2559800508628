import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AppLoaderStyled } from "./styled";

interface Props extends RouteComponentProps<any> {}

class AppLoader extends React.PureComponent<Props, any> {
  render() {
    return (
      <AppLoaderStyled>
        <img
          src="/images/wiser-loader-1s-200px.svg"
          alt="wiser-loader-1s-200px.svg"
        />
      </AppLoaderStyled>
    );
  }
}
export default withRouter(AppLoader);
