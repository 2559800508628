import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { LoginStyled } from "./styled";
import { Input, Button, Form } from "antd";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import AuthStore from "../../../stores/auth/auth.store";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class Login extends React.PureComponent<Props, any> {
  @observable error: null | string = null;

  componentDidMount = async () => {};

  onFinish = async (values: any) => {
    try {
      // await (await this.props.authStore!.login(values).promise).json();

      const { promise } = this.props.authStore!.login(values);
      const response = await promise;
      const data = await response.json();

      if (response.status === 200) {
      } else {
        this.error = data.message;
      }
    } catch (error) {
      console.error(error);
    }
  };

  onFinishFailed = (errorInfo: any) => {};

  render() {
    return (
      <LoginStyled>
        <div className="auth b-graym">
          <div>
            <div className="marginHor20 ">
              <div>
                <div className="f35 marginHor10 bold">Login</div>
                <div className="f16 gray2">Sign in to your account</div>
              </div>
            </div>
            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: "Username is required!" }]}
                className="full"
              >
                <Input
                  // onChange={(e) =>
                  //   (this.props.authStore!.userInfo!.username = e.target.value)
                  // }

                  size={"large"}
                  prefix={<UserOutlined />}
                  className="marginTop10 full radius br-black input"
                  type={"email"}
                  placeholder={"email"}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Password is required!" }]}
                className="full"
              >
                <Input
                  // onChange={(e) =>
                  //   (this.props.authStore!.userInfo!.password = e.target.value)
                  // }
                  size={"large"}
                  prefix={<LockOutlined />}
                  className="marginTop10 full radius br-black input"
                  type={"password"}
                  placeholder={"Password"}
                />
              </Form.Item>

              <div className="red">{this.error}</div>

              <div className="flex">
                <Button
                  type="primary"
                  size={"large"}
                  htmlType="submit"
                  className="b-grayM radius red br-red full marginTop20"
                >
                  Login
                </Button>

                <Link
                  to="/auth/forgot-password"
                  className="full red f12 forgot"
                >
                  Forgot Password?
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </LoginStyled>
    );
  }
}
export default withRouter(Login);
