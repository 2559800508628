import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { DashboardMapStyled } from "./styled";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { exampleMapStyles } from "./MapStyle";
import DashboardStore from "../../../../stores/dashboard/dashboard.store";
import { observer, inject } from "mobx-react";
const API_KEY = "AIzaSyDDvphMh_sfgrpqvOOw2HOgel-NagtwUUo";

let lat: string;
let lng: string;

const GoogleMapWrapper = inject()(
  observer((props: any) => {
    return (
      <GoogleMap
        key={new Date().getTime()}
        defaultOptions={{
          scrollwheel: true,
          draggable: true,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          minZoom: 3,
          rotateControl: false,
          fullscreenControl: false,
          styles: exampleMapStyles,
        }}
        defaultZoom={5}
        defaultCenter={{
          lat: 31.8675032,
          lng: 35.2697794,
        }}
      >
        {props?.traffix?.length &&
          props?.traffix?.map((marker: any, i: number) => {
            return (
              <Marker
                label={{ text: `${marker?.count}`, color: "white" }}
                position={{
                  lat: Number(marker?.lat),
                  lng: Number(marker?.lng),
                }}
                key={i}
              />
            );
          })}
      </GoogleMap>
    );
  })
);
const MapWithAMarker = withScriptjs(
  withGoogleMap((props: any) => <GoogleMapWrapper traffix={props.traffix} />)
);

interface Props extends RouteComponentProps<any> {
  dashboardStore?: DashboardStore;
}

@inject("dashboardStore")
@observer
class DashboardMap extends React.PureComponent<Props, any> {
  componentDidMount = async () => {
    try {
      this.props.dashboardStore!.fetchMapsLocations();
    } catch (error) {}
  };

  render() {
    return (
      <DashboardMapStyled>
        <div className="title">
          <div className="big">Global Heat Map</div>
        </div>
        <div style={{ height: "550px" }}>
          <MapWithAMarker
            googleMapURL={` https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: `100%` }} />}
            traffix={this.props.dashboardStore?.mapMarkers}
          />
        </div>
      </DashboardMapStyled>
    );
  }
}
export default withRouter(DashboardMap);
