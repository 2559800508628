import { observable } from "mobx";

class UiStore {
  @observable menuPages = [
    {
      group: "top",
      link: "dashboard",
      name: "Dashboard",
      icon: "/images/menu/cil-graph.svg",
    },

    {
      group: "top",
      link: "alerts",
      name: "Alerts",
      icon: "/images/menu/cil-bell.svg",
    },

    {
      group: "top",
      link: "product-scans",
      name: "Product Scans",
      icon: "/images/menu/cil-tablet.svg",
    },

    {
      group: "top",
      link: "leads",
      name: "Leads",
      icon: "/images/menu/cil-cart.svg",
    },

    {
      group: "create",
      link: "qr-code-managers",
      name: "QR Codes",
      icon: "/images/menu/cil-qr-code.svg",
    },

    {
      group: "create",
      link: "landing-pages",
      name: "Landing Pages",
      icon: "/images/menu/cil-devices.svg",
    },

    {
      group: "manage",
      link: "products",
      name: "Products",
      icon: "/images/menu/cil-3d.svg",
    },
    {
      group: "manage",
      link: "distributors",
      name: "Distributors",
      icon: "/images/menu/cil-truck.svg",
    },
    {
      group: "manage",
      link: "categories",
      name: "Categories",
      icon: "/images/menu/cil-lan.svg",
    },

    {
      group: "admin",
      link: "subscribers",
      name: "Subscribers",
      icon: "/images/menu/cil-thumb-up.svg",
    },

    {
      group: "admin",
      link: "users",
      name: "Users",
      icon: "/images/menu/cil-shield-alt.svg",
    },

    {
      group: "admin",
      link: "activity-log",
      name: "Activity log",
      icon: "/images/menu/cil-list-rich.svg",
    },
  ];
}

export default UiStore;
