import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IAlert } from "./alert.interface";
import { observable, computed } from "mobx";

class Alert extends BaseModel implements IAlert {
  id = null;
  title: any = 123;
  @observable key: string = "asd";
  @observable name: string = "asd";
  @observable type: string = "asd";
  @observable first_scan: string = "asd";
  @observable last_scan: string = "asd";
  @observable details: string = "asd";
  @observable scan_count: number = 0;
  @observable actions: any[] = [];
  @observable product: any = {};
  @observable category: any = {};
  @observable subCategory: any = {};
  @observable distributor: any = {};
  @observable inValid_scan_count: number = 0;
  @observable qr_code_id: number = 0;
  @observable qr: any = {};

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "alert";
  }
  responseModelsKey(): string {
    return `alerts`;
  }

  get url(): string {
    return `/alerts`;
  }

  @computed
  get toJson(): { [key: string]: any } {
    return this._toJson({
      id: this.id,
      alert_type: this.type,
      category: this.category,
      last_scan: this.last_scan,
      first_scan: this.first_scan,
    });
  }
}

export default Alert;
