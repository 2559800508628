import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ConfirmPopupStyled } from "./styled";
import { Modal, Button } from "antd";
import { observable } from "mobx";

interface Props extends RouteComponentProps<any> {
  visible: any;
  handledeleteItem: any;
  handleCancelDeleteItem: any;
  productsConnected?: number;
  subCategoriesConnected?: number;
}

class ConfirmPopup extends React.PureComponent<Props, any> {
  render() {
    return (
      <ConfirmPopupStyled>
        <Modal
          footer={null}
          visible={this.props.visible}
          afterClose={this.props.handleCancelDeleteItem}
          className="are_you_sure textCenter padding30"
        >
          <div className="f35">Are you sure?</div>
          <div className="f27">
            This item will be deleted with no option to restore
          </div>
          <br />
          {(this.props?.subCategoriesConnected || 0) > 0 && (
            <div>
              {this.props.subCategoriesConnected} Sub categories connected
            </div>
          )}
          {(this.props?.productsConnected || 0) > 0 && (
            <div>{this.props.productsConnected} Products connected</div>
          )}
          <div className="flex justify-center">
            <Button
              // disabled={
              //   (this.props?.subCategoriesConnected || 0) > 0 ||
              //   (this.props?.productsConnected || 0) > 0
              // }
              type="primary"
              size="large"
              className="br-grayM transparent white radius"
              onClick={() => this.props.handledeleteItem()}
            >
              Yes, Delete
            </Button>

            <Button
              type="primary"
              size="large"
              className="br-grayM transparent white radius"
              onClick={this.props.handleCancelDeleteItem}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </ConfirmPopupStyled>
    );
  }
}
export default withRouter(ConfirmPopup);
