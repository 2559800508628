import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ResetPassStyled } from "./styled";
import { Input, Button, Form } from "antd";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import AuthStore from "../../../stores/auth/auth.store";
import Validation from "../../../services/validation";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class ResetPass extends React.PureComponent<Props, any> {
  @observable error: null | string = null;
  @observable sucsses: null | string = null;
  @observable page_id: null | string = null;
  componentDidMount = async () => {
    try {
      //   await this.props.authStore?.fetchPage({ page: 1 });
      var url = window.location.href;
      var parts = url.split("/");
      this.page_id = parts[parts.length - 2];
    } catch (error) {
      console.error(error);
    }
  };

  onFinish = async (values: any) => {
    values.reset_password_token = this.page_id;
    try {
      const { promise } = this.props.authStore!.resetPassword(values);
      const response = await promise;
      const data = await response.json();
      if (response.status === 200) {
        if (data.status) {
          this.error = "";
          this.sucsses = "Paswword updated, you can login to the system";
          setTimeout(() => {
            this.props.history.push("/auth/login");
          }, 2000);
        } else {
          this.error = "Error on updating your password";
        }
      } else {
        this.error = data.message;
      }
    } catch (error) {
      console.error(error);
    }
  };

  onFinishFailed = (errorInfo: any) => {};

  render() {
    return (
      <ResetPassStyled>
        <div className="auth b-graym">
          <div>
            <div className="marginHor20 ">
              <div>
                <div className="f35 marginHor10 bold">Reset Password</div>
                <div className="f16 gray2">Please type your new password</div>
              </div>
            </div>

            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                label="password"
                className="full"
                name="Password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        value &&
                        Validation.validateSubscriberPassword(value)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Password must be longer than 4 charecters"
                      );
                    },
                  }),
                ]}
              >
                <Input
                  size={"large"}
                  prefix={<LockOutlined />}
                  className="marginTop10 full radius br-black input"
                  type="password"
                />
              </Form.Item>

              <div className="red">{this.error}</div>

              <div className="flex">
                <Form.Item>
                  <Button
                    //   onClick={this.login}
                    htmlType="submit"
                    type="primary"
                    size={"large"}
                    className="b-grayM radius red br-red full marginTop20"
                  >
                    Send
                  </Button>
                </Form.Item>
                <Link to="/auth/login" className="full red f12 forgot">
                  login
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </ResetPassStyled>
    );
  }
}
export default withRouter(ResetPass);
