import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { QrCodesStyled } from "./styled";
import Header from "../../components/Header/Header";
import moment from "moment";
import { Button, AutoComplete, Input, Modal } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import QrCodesStore from "../../stores/qr-codes/qr-codes.store";
import { inject, observer } from "mobx-react";
import debounce from "../../utils/debounce";
import { observable, action, computed } from "mobx";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import FilterPage from "../../components/FilterPage/FilterPage";
import QrCode from "../../stores/qr-codes/qr-codes.model";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import QRCode from "qrcode";
import DownloadModal from "./components/DownloadModal/DownloadModal";
import jsPDF from "jspdf";
import ActionsCell from "./components/ActionsCell";
import numeral from "numeral";

interface Props extends RouteComponentProps<any> {
  qrCodeStore: QrCodesStore;
}

const ModalFooter = observer(
  ({
    closeQrPrintWidget,
    downloadLoading,
    download,
    singleOrMulti,
    qrCreateCount,
    qrsCount,
  }: any) => {
    return (
      <>
        {qrCreateCount > 1 && qrCreateCount < qrsCount && (
          <div style={{ textAlign: "left" }}>
            Created: {Math.ceil((qrCreateCount / qrsCount) * 100)}%
          </div>
        )}
        <div>
          <Button key="back" onClick={closeQrPrintWidget}>
            close
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={downloadLoading}
            onClick={() => {
              download(singleOrMulti);
            }}
          >
            Download
          </Button>
        </div>
      </>
    );
  }
);

@inject("qrCodeStore")
@observer
class QrCodes extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable selectedRecord: any = {};
  @observable visible: boolean = false;
  @observable record: any = {};
  @observable singleOrMulti: string = "Single";
  @observable downloadLoading: boolean = false;
  @observable qrCreateCount: number = 0;

  abortRequest: Function | undefined;
  qrsCount: number = 0;

  columns = [
    {
      title: "Time & Date",
      dataIndex: "createdAt",
      render: (createdAt: any) =>
        moment(new Date(createdAt)).format("MMMM DD [at] hh:mm A"),
      key: "createdAt",
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "category",
      render: (product: any, record: any) => record?.category?.name || " - ",
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (product: any, record: any) => record?.subCategory?.name || " - ",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (Category: any, record: any) => record?.product?.name || " - ",
    },

    {
      title: "Distributor",
      dataIndex: "Distributor",
      render: (Distributor: any, record: any) => (
        <div>{record?.distributor?.name}</div>
      ),
      width: "10%",
      key: "distributer_id",
    },

    {
      title: "Group Count",
      dataIndex: "groupCount",
      render: (Distributor: any, record: any) => (
        <div>{numeral(record?.groupCount).format("0,0")}</div>
      ),
      key: "groupCount",
    },
    {
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      render: (text: string, record: any) => (
        <ActionsCell
          handleDelete={(record: any) => {
            this.props!.qrCodeStore!.openDeleteItem();
            this.props!.qrCodeStore.selectedRecord = record;
          }}
          openQrPrintWidget={this.openQrPrintWidget}
          record={record}
        />
      ),
    },
  ];

  componentDidMount = async () => {
    try {
      await this.props.qrCodeStore.fetchPage({
        page: 1,
      });
    } catch (error) {
      console.error(error);
    }
  };

  closeQrPrintWidget = async () => {
    this.visible = false;
  };

  openQrPrintWidget = async (record: any) => {
    this.visible = true;
    this.qrsCount = record.groupCount;
    this.record = record;
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { qrCodeStore } = this.props;
    await qrCodeStore?.fetchPage({
      page,
      pageSize,
      filter: qrCodeStore!.filter,
      params: { child_number: [3] },
    });
    // router.navigate("templates", { page, pageSize });
  };

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  onSearchDebounce = debounce(async (value: string) => {
    this.props.qrCodeStore!.searchLoading = true;
    this.props.qrCodeStore!.onFilterChanged({
      ...this.props!.qrCodeStore!.filter,
      name: value,
    });
  });

  handleExportData = () => {
    const { qrCodeStore } = this.props;
    let rows = qrCodeStore!.selectedRows.map((row: QrCode) => {
      const toJson = row.dataToExport;
      const data: any = {};
      const excludeFields = [
        "Quantity",
        "quantity",
        "group_id",
        "scanValid",
        "createdAt",
        "qr",
        "category",
        "distributor",
      ];
      for (let [key, value] of Object.entries(toJson)) {
        if (!excludeFields.includes(key)) {
          data[`${capitalizeFirstLetter(key).split("_").join(" ")}`] = value;
        }
      }
      data.Category = row.category.name;
      data.Distributor = row.distributor.name;
      data["Created at"] = moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss");
      return data;
    });
    qrCodeStore!.downloadCsv(rows, "leads");
  };

  @action updateCounterAsync = async (counter: any) => {
    return await new Promise((resolve) =>
      setTimeout(() => {
        this.qrCreateCount = counter;
        resolve();
      }, 0)
    );
  };

  @action updateCounter = async (counter: any) => {
    this.qrCreateCount = counter;
  };

  @action generateQrs = async (qrs: any) => {
    const images: any = [];

    for await (let qr of qrs[Symbol.iterator]()) {
      images.push({
        id: qr.id,
        url: qr.url,
        series: qr.series,
        data: await QRCode.toDataURL(qr.url),
      });
      await this.updateCounterAsync((this.qrCreateCount += 1));
      if (!this.visible) {
        this.downloadLoading = false;
        this.qrCreateCount = 0;
        return [];
      }
    }
    return images;
  };

  download = async (type: string) => {
    this.downloadLoading = true;
    let group_id = this.record.group_id;

    const { promise } = await this.props.qrCodeStore!.fetchQrsListByGroup(
      group_id
    );

    const response = await promise;
    let qrs: any = await response.json();

    this.qrsCount = qrs.length;
    let pdf;
    try {
      let images = await this.generateQrs(qrs);
      if (!this.visible) return;

      if (type === "multi") {
        pdf = this.placeImagesOnPdfManyPerPage(images);
      } else {
        pdf = this.placeImagesOnPdfOnePerPage(images);
      }

      if (!this.visible) return;

      pdf.save("wiser_qr.pdf");
      this.visible = false;
      this.downloadLoading = false;
    } catch (error) {}
  };

  placeImagesOnPdfOnePerPage = (qrs: any) => {
    const FONT_SIZE = 13;
    const pdf = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
    });
    pdf.setFont("helvetica");
    pdf.setFontSize(FONT_SIZE);

    const QR_PADDING = 20;
    const QR_WIDTH: number = 250;
    const QR_HEIGHT: number = 250;
    const PAGE_WIDTH = pdf.internal.pageSize.getWidth();
    const PAGE_HEIGHT = pdf.internal.pageSize.getHeight();
    const QR_WIDTH_PADDED: number = QR_WIDTH + QR_PADDING;
    const QR_HEIGHT_PADDED: number = QR_HEIGHT + QR_PADDING;

    qrs.forEach((qr: any, index: number) => {
      const x = (PAGE_WIDTH - QR_WIDTH_PADDED) / 2;
      const y = (PAGE_HEIGHT - QR_HEIGHT_PADDED) / 2;

      pdf.addImage(qr.data, "JPEG", x, y, QR_WIDTH_PADDED, QR_HEIGHT_PADDED);
      pdf.text(`${qr.id}`, x + QR_PADDING + 5, y + QR_HEIGHT + 15);
      if (qr.series) {
        pdf.text(`${qr.series}`, x + QR_PADDING + 5, y + QR_HEIGHT + 30);
      }

      if (index + 1 !== qrs.length) {
        pdf.addPage();
      }
      if (!this.visible) {
        this.downloadLoading = false;
        this.qrCreateCount = 0;
        return [];
      }
    });

    return pdf;
  };
  placeImagesOnPdfManyPerPage = (qrs: any) => {
    console.log({ qrs });
    const FONT_SIZE = 6.5;
    const pdf = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
    });
    pdf.setFont("helvetica");
    pdf.setFontSize(FONT_SIZE);

    const PAGE_PADDING = 20;
    const QR_PADDING = 10;
    const PAGE_WIDTH = pdf.internal.pageSize.getWidth();
    const PAGE_WIDTH_PADDED = PAGE_WIDTH - PAGE_PADDING * 2;
    const PAGE_HEIGHT = pdf.internal.pageSize.getHeight();
    const PAGE_HEIGHT_PADDED = PAGE_HEIGHT - PAGE_PADDING * 2;
    const QR_WIDTH: number = 40;
    const QR_WIDTH_PADDED: number = QR_WIDTH + QR_PADDING;
    const QR_HEIGHT: number = 40;
    const QR_HEIGHT_PADDED: number = QR_HEIGHT + QR_PADDING;
    const QR_PER_LINE: number = Math.floor(PAGE_WIDTH_PADDED / QR_WIDTH_PADDED);
    const LINES_PER_PAGE: number = Math.floor(
      PAGE_HEIGHT_PADDED / QR_HEIGHT_PADDED
    );
    const QR_MARGIN_RIGHT: number =
      (PAGE_WIDTH_PADDED % (QR_PER_LINE * QR_WIDTH_PADDED)) / (QR_PER_LINE - 1);
    const QR_MARGIN_BOTTOM: number =
      (PAGE_HEIGHT_PADDED % (LINES_PER_PAGE * QR_HEIGHT_PADDED)) /
      (LINES_PER_PAGE - 1);

    qrs.forEach((qr: any, index: number) => {
      const xIndex = index % QR_PER_LINE;
      const yIndex = Math.floor(index / QR_PER_LINE) % LINES_PER_PAGE;
      const firstXIndex = xIndex === 0;
      const firstYIndex = yIndex === 0;
      const x = firstXIndex
        ? xIndex * QR_WIDTH_PADDED + PAGE_PADDING
        : xIndex * (QR_WIDTH_PADDED + QR_MARGIN_RIGHT) + PAGE_PADDING;
      const y = firstYIndex
        ? yIndex * QR_HEIGHT_PADDED + PAGE_PADDING
        : yIndex * (QR_HEIGHT_PADDED + QR_MARGIN_BOTTOM) + PAGE_PADDING;

      pdf.addImage(qr.data, "JPEG", x, y, QR_WIDTH, QR_HEIGHT);
      pdf.text(`${qr.id}`, x + 2, y + QR_HEIGHT + 2);
      if (qr.series) {
        pdf.text(`${qr.series}`, x + 2, y + QR_HEIGHT + 10);
      }

      if (yIndex + 1 === LINES_PER_PAGE && xIndex + 1 === QR_PER_LINE) {
        pdf.addPage();
      }

      if (!this.visible) {
        this.downloadLoading = false;
        this.qrCreateCount = 0;
        return [];
      }
    });

    return pdf;
  };

  onSelected = (singleOrMulti: string) => {
    this.singleOrMulti = singleOrMulti;
  };

  render() {
    const { qrCodeStore } = this.props;
    return (
      <QrCodesStyled>
        <Header
          title={"QR Codes"}
          rightMenu={
            <div className="flex flexVerCenter">
              <Link to="/qr-code-managers/create">
                <Button className="br br-green green b-gray radius marginVer10 lineHeight0">
                  + Generate New QR Code
                </Button>
              </Link>
              <FilterPage
                color="#5edc89"
                store={qrCodeStore}
                filters={["distributor", "serial", "QR ID"]}
              />
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    qrCodeStore.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          loading={this.props?.qrCodeStore?.fetchPageLoading}
          dataSource={this.props?.qrCodeStore?.currentPage.map((row: any) => {
            row.key = row.group_id;
            return row;
          })}
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.qrCodeStore!.selectedRows;
            let groupsIds = rows.map((row: any) => row.group_id);

            this.props!.qrCodeStore!.changeUsersStatus(action, groupsIds);
          }}
          handleExportData={() => {
            let rows = this.props!.qrCodeStore!.selectedRows.map(
              (row: any) => ({
                "Time & Date": moment(row.createdAt).format(
                  "MMMM DD [at] hh:mm A"
                ),
                Category: row.category.name,
                "Sub Category": row.subCategory?.name || "-",
                Product: row.product?.name || "-",
                Distributor: row.distributor?.name || "-",
                "Group Count": row.groupCount,
              })
            );
            this.props!.qrCodeStore!.downloadCsv(rows, "qrs");
          }}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.qrCodeStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.qrCodeStore!.selectedRows = selectedRows;
          }}
          disableMultiSelectAction={true}
          total={this.props!.qrCodeStore!.total}
          pageSize={this.props!.qrCodeStore!.pageSize}
          currentPageNumber={this.props!.qrCodeStore!.currentPageNumber}
          onPageSettingsChange={this.onPageSettingsChange}
        />

        <ConfirmPopup
          visible={this.props!.qrCodeStore.visible}
          handledeleteItem={this.props!.qrCodeStore.deleteItem}
          handleCancelDeleteItem={this.props!.qrCodeStore.cancelDeleteItem}
        />

        <Modal
          wrapClassName="download-qr-code"
          title="Download QR Code"
          className="are_you_sure"
          visible={this.visible}
          footer={
            <ModalFooter
              closeQrPrintWidget={this.closeQrPrintWidget}
              downloadLoading={this.downloadLoading}
              download={this.download}
              singleOrMulti={this.singleOrMulti}
              qrCreateCount={this.qrCreateCount}
              qrsCount={this.qrsCount}
            />
          }
        >
          <DownloadModal
            onSelected={this.onSelected}
            selectedValue={this.singleOrMulti}
          />
        </Modal>
      </QrCodesStyled>
    );
  }
}
export default withRouter(QrCodes);
