import { Button, Modal } from "antd";
import React from "react";
import { NotDeleteabbleModalStyled } from "./styled";

interface Props {
  visible: boolean;
  onCancel: () => void;
  itemName: string;
}

class NotDeleteabbleModal extends React.PureComponent<Props, any> {
  render() {
    return (
      <NotDeleteabbleModalStyled>
        <Modal
          footer={null}
          visible={this.props.visible}
          // afterClose={this.props.handleCancelDeleteItem}
          className="are_you_sure textCenter padding30"
        >
          <div className="f35">Alert!</div>
          <div className="f27">
            <span className="capitalize">{this.props.itemName}</span> cannot be
            deleted because there are QR codes connected to it
          </div>
          <br />

          <div className="flex justify-center">
            <Button
              type="primary"
              size="large"
              className="br-grayM transparent white radius"
              onClick={this.props.onCancel}
            >
              Close
            </Button>
          </div>
        </Modal>
      </NotDeleteabbleModalStyled>
    );
  }
}
export default NotDeleteabbleModal;
