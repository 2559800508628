import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IProductScan } from "./product-scan.interface";
import { observable, computed } from "mobx";

class ProductScan extends BaseModel implements IProductScan {
  @observable scanValid: number = 0;
  @observable group_id: string = "";
  @observable category_id: number | undefined = 1;
  @observable Category: any | undefined = 1;
  @observable qr_code_id: number = 0;
  @observable subscription_id: number = 0;
  @observable distributer_id: number = 0;
  @observable createdAt: any = new Date();
  @observable qr: any | undefined = {};
  @observable geo: any = {};
  @observable product: any = {};
  @observable category: any = {};
  @observable subCategory: any = {};
  @observable category_name: string | null = null;
  @observable sub_category_name: string | null = null;
  @observable product_name: string | null = null;
  @observable distributer_territories: string = "";
  @observable territoryScanValid: number = 0;
  @observable countScanValid: number = 0;

  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "productScans";
  }

  responseModelsKey(): string {
    return "product-scans";
  }

  get url(): string {
    return "/product-scans";
  }

  @computed
  get toJson(): { [key: string]: any } {
    return this._toJson({
      scanValid: this.scanValid,
      group_id: this.group_id,
      category_id: this.category_id,
      subscription_id: this.subscription_id,
      distributer_id: this.distributer_id,
      qr_code_id: this.qr_code_id,
      territoryScanValid: this.territoryScanValid,
      countScanValid: this.countScanValid,
      geo: this.geo,
    });
  }
}

export default ProductScan;
