/* eslint-disable @typescript-eslint/no-useless-constructor */
import AlertService from "./qr-codes.service";
import QrCode from "./qr-codes.model";
import PageStore from "../page.store";
import parseObjectToUrlParams from "../../utils/parseObjectToUrlParams";
import { reaction } from "mobx";
import AuthStore from "../auth/auth.store";

class QrCodesStore extends PageStore {
  authStore: any;
  constructor(qrCodeService: AlertService, authStore: AuthStore) {
    super(qrCodeService);
    this.authStore = authStore;
    reaction(
      () => this.authStore.user,
      (user, reaction) => {
        if (user) {
          this.fetch({ noGroup: 1 });
        }
      }
    );
  }

  url(): string {
    return `/qr-codes`;
  }

  get model(): any {
    return QrCode;
  }

  fetchQrsByGroup = async (group_id: string) => {
    const models = await this.apiService.get(
      "/qr-codes/?" + parseObjectToUrlParams({ qr: { group_id } })
    );
  };

  fetchQrsListByGroup = async (group_id: string) => {
    return this.apiService.get(
      "/qr-codes/list/?" + parseObjectToUrlParams({ qr: { group_id } })
    );
  };

  createGroup = (group: any) => {
    return this.apiService.createGroup(this.url(), group);
  };

  onCreateGroup = (data: any[]) => {
    const models: any = data.map((qrData: any) => this.create(qrData));

    this.models = this.models.concat(models);
  };
}

export default QrCodesStore;
