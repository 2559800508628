import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { CreateUserStyled } from "./styled";
import { Input, message, Avatar, Button, Form, Checkbox } from "antd";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import UsersStore from "../../../../stores/users/user.store";
import User from "../../../../stores/users/user.model";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import Validation from "../../../../services/validation";
import AuthStore from "../../../../stores/auth/auth.store";
import UiStore from "../../../../stores/ui/ui.store";
import {
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

interface Props extends RouteComponentProps<any> {
  usersStore: UsersStore;
  authStore: AuthStore;
  uiStore: UiStore;
}

@inject("usersStore", "authStore", "uiStore")
@observer
class CreateUser extends React.PureComponent<Props, any> {
  @observable user: User = this.props.usersStore?.create();
  @observable roles: any = ["Distributor", "Factory", "Administrator"];
  @observable profile_preview: string = "";
  @observable loading: boolean = false;

  create = async () => {
    const userData = {
      file: this.user.file,
      name: this.user.name,
      email: this.user.email,
      password: this.user.password,
      permission: this.user.permission,
      role: this.user.role,
    };
    this.loading = true;
    const { promise } = this.props.usersStore.createUser(userData);
    const response = await promise;
    if (response.status === 200) {
      message.success({
        content: "Success to create new user",
        className: "wiser-message landing-page-message",
        icon: <CheckOutlined />,
      });
      this.props.history.push("/users/");
    } else {
      const data = await response.json();
      message.error({
        content: data.message || "Something went wrong on creating new user.",
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }
    this.loading = false;
  };

  togglePermmisions(e: any) {
    var idx = this.user.permission.indexOf(e.target.value);

    if (idx >= 0) {
      this.user.permission.splice(idx, 1);
    } else {
      this.user.permission.push(e.target.value);
    }
  }

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  onFinish = (values: any) => {
    this.create();
  };

  onFinishFailed = (errorInfo: any) => {};

  render() {
    return (
      <CreateUserStyled>
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <div className="flex" style={{ maxWidth: "980px" }}>
            <div className="form-row flex1 br-right br-grayM paddingRight30">
              <div className="f21 white">Add new user</div>

              <div className="marginTop20 flex">
                <Avatar
                  src={this.profile_preview}
                  size={75}
                  className="margin10 transparent br br-gray2"
                />
                <UploadFile
                  title={"Upload Profile Picture (.jpg, .png)"}
                  accept={".jpg, .png"}
                  multiple={false}
                  maxCount={1}
                  action={`${process.env.REACT_APP_API_URL}/users/import`}
                  onChange={(file) => {
                    let fileObj = file.file;

                    this.getBase64(
                      fileObj.originFileObj,
                      (imageUrl: any) => (this.profile_preview = imageUrl)
                    );

                    this.user.file = fileObj.originFileObj;
                  }}
                />
              </div>

              <Form.Item
                label="Name"
                name="username"
                rules={[
                  { required: true, message: "Please add your username!" },
                ]}
              >
                <Input
                  disabled={this.loading}
                  className="show filed"
                  value={this.user.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.user.name = event.target.value;
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value && Validation.validateEmail(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Please add a valid email");
                    },
                  }),
                ]}
              >
                <Input
                  disabled={this.loading}
                  className="show filed"
                  type="email"
                  value={this.user.email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.user.email = event.target.value;
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please add your role!" }]}
              >
                <Input
                  disabled={this.loading}
                  className="show filed"
                  value={this.user.role}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.user.role = event.target.value;
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="Password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value && Validation.validateUserPassword(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "8 character (minimum) , 1 capital and 1 digit"
                      );
                    },
                  }),
                ]}
              >
                <Input
                  disabled={this.loading}
                  className="show filed"
                  type="password"
                  value={this.user.password}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.user.password = event.target.value;
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Link to="/users">
                  <Button className="br  gray radius marginVer10 lineHeight0">
                    <StopOutlined /> Cancel
                  </Button>
                </Link>

                <Button
                  disabled={this.loading}
                  htmlType="submit"
                  type="primary"
                  className="br-red red b-gray"
                  // onClick={this.isUpdate ? this.update : this.create}
                >
                  {this.loading ? <LoadingOutlined /> : <PlusOutlined />}
                  Save
                </Button>
              </Form.Item>
            </div>

            <div className="form-row flex1 paddingVer30">
              <div className="marginTop20">
                <div className="f21 white">Permissions</div>

                <div className="flex white br-bottem br-grayM paddingHor10 marginBottom10">
                  <div className="flex1">
                    <span className="nav-text">Check all</span>
                  </div>
                  <div className="">
                    <Checkbox
                      className="fRight marginBottom20"
                      onChange={(event: any) => {
                        event.target.checked
                          ? (this.user.permission = [
                              "dashboard",
                              "alerts",
                              "product-scans",
                              "leads",
                              "qr-code-managers",
                              "landing-pages",
                              "products",
                              "distributors",
                              "categories",
                              "users",
                              "activity-log",
                            ])
                          : (this.user.permission = []);
                      }}
                    ></Checkbox>
                  </div>
                </div>

                {this.props.uiStore?.menuPages.map((page) => {
                  return page.link !== "subscribers" ? (
                    <div className="flex white br-bottem br-grayM paddingHor10 marginBottom10">
                      <div className="flex1">
                        <img alt="svg" src={page.icon} />
                        <span className="nav-text marginLeft10">
                          {page.name}
                        </span>
                      </div>
                      <div className="">
                        <Checkbox
                          checked={this.user.permission.includes(page.link)}
                          disabled={this.loading}
                          value={page.link}
                          onChange={(e) => this.togglePermmisions(e)}
                        ></Checkbox>
                      </div>
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
            </div>
          </div>
        </Form>
      </CreateUserStyled>
    );
  }
}
export default withRouter(CreateUser);
