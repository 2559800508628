import { inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CategoriesStore from "../../stores/categories/category.store";
import DistributorsStore from "../../stores/distributors/distributor.store";
import DashboardMap from "./components/DashboardMap/DashboardMap";
import FilterDashboardData from "./components/FilterDashboardData/FilterDashboardData";
import InfoBox from "./components/InfoBox/InfoBox";
import ScanCharts from "./components/ScanCharts/ScanCharts";
import { DashboardCustomersStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  categoriesStore?: CategoriesStore;
  distributorsStore?: DistributorsStore;
}

@inject("categoriesStore", "distributorsStore")
@observer
class DashboardCustomers extends React.PureComponent<Props, any> {
  componentDidMount = () => {
    // this.props!.categoriesStore!.fetchAllCategories();
    // this.props!.distributorsStore!.fetchAllItems();
  };
  render() {
    return (
      <DashboardCustomersStyled>
        <FilterDashboardData />
        <InfoBox />
        <ScanCharts />
        {/* <Alerts /> */}
        <DashboardMap />
      </DashboardCustomersStyled>
    );
  }
}
export default withRouter(DashboardCustomers);
