
	import BaseService from "../base.service";

	class AlertSettingsService extends BaseService {
		constructor(baseUrl: string = "") {
		super(baseUrl);
		}
	}
	
	export default AlertSettingsService;
		
	