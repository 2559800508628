import styled from "styled-components";

const AlertsStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);
  .table_action {
    .anticon {
      margin: 5px;
    }
  }

  .settings-button {
    svg {
      fill: #ce3b41 !important;
    }
  }
  .ant-btn.br.br-blue.blue.b-gray.radius.marginVer10.lineHeight0.ant-btn-lg {
    color: #ce3b41 !important;
    border-color: #ce3b41 !important;
  }

  .alert-details {
    max-width: 1300px;
    margin: 0 auto;
    .label {
      width: 180px;
      font-size: 16px;
    }
  }
  .critical {
    td:first-child {
      position: relative;
      &:before {
        position: absolute;
        top: 0;
        width: 2px;
        height: 100%;
        background-color: red;
        content: " ";
        display: block;
        left: 0;
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #63c2de;
  }
`;

export { AlertsStyled };
