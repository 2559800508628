import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { EditUserStyled } from "./styled";
import { inject, observer } from "mobx-react";
import UsersStore from "../../../../stores/users/user.store";
import { action, observable } from "mobx";
import { Avatar, Divider, Form, Select, Table, message, Checkbox } from "antd";
import User from "../../../../stores/users/user.model";
import DynamicTable from "../../../../components/DynamicTable/DynamicTable";
import moment from "moment";
import AuthStore from "../../../../stores/auth/auth.store";
import UiStore from "../../../../stores/ui/ui.store";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  usersStore?: UsersStore;
  authStore?: AuthStore;
  uiStore?: UiStore;
}

@inject("usersStore", "authStore", "uiStore")
@observer
class EditUser extends React.PureComponent<Props, any> {
  @observable user: User = this.props.usersStore?.create();
  @observable data: any = {};
  @observable roles: any = ["Distributor", "Factory", "Administrator"];

  componentDidMount = () => {
    this.fetchUser(Number(this.props.match.params.id));
  };

  @action fetchUser = async (id: number) => {
    const { promise } = this.props.usersStore!.fetchOne(id);
    const response: any = await promise;
    const { status } = response;

    if (status === 200) {
      this.user?._updateData(await response.json());
    }
  };

  update = async () => {
    const { promise } = this.user.save();
    const response = await promise;
    if (response.status === 200) {
      message.success({
        content: "Success update new permissions",
        className: "wiser-message landing-page-message",
        icon: <CheckOutlined />,
      });
    } else {
      message.error({
        content: "Something went wrong when updating permissions.",
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }
  };

  columns = [
    {
      title: "Time",
      dataIndex: "time",
      render: (text: string, record: any) => (
        <div>{moment(record.createdAt).format("MMM D, YYYY, HH:mm")}</div>
      ),
      key: "time",
    },
    {
      title: "Activity",
      dataIndex: "activity",
      render: (text: string, record: any) => <div>{record.name}</div>,
      key: "activity",
    },
  ];

  togglePermissions(event: any) {
    if (event.target.checked) {
      this.user.permission.push(event.target.value);
    } else {
      this.user.permission = this.user.permission.filter(
        (role: string) => role !== event.target.value
      );
    }

    this.update();
  }

  render() {
    const user = this.user;
    return (
      <EditUserStyled>
        <div className="flex ">
          <div className="">
            <Avatar size={64} src={user.profile_img} className="" />
          </div>
          <div className="">
            <div className="f27 bold ">{user.name}</div>
            <div className="f18 uppercase">{user.role}</div>
            <div className="flex">
              <div className="flex">
                <span className="bold marginRight10">Email Address</span>{" "}
                {user.email}
              </div>
              <Divider type="vertical" className={"b-grayM2 margin7"} />
              <div className="flex">
                <span className="bold marginRight10">Password</span>{" "}
                <span className="password">***********</span>
              </div>
            </div>
          </div>
        </div>

        <div className="permissions marginTop40 ">
          <div className="f27 bold">Permissions</div>

          {/* <Form.Item className="marginTop20" name="permission">
            <Select
              showSearch
              className="filed full"
              placeholder=""
              optionFilterProp="children"
              onSelect={(value) => (this.user.permission = value)}
              onChange={(e) => {
                this.user.permission = e;
                this.update();
              }}
              onFocus={() => {}}
              onBlur={() => {}}
              defaultValue={this.user.permission || undefined}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.roles.map((permission: any) => (
                <Option value={permission} key={permission}>
                  {permission}
                </Option>
              ))}
            </Select>
          </Form.Item> */}

          <div className="flex white br-bottem br-grayM paddingHor10 marginBottom10">
            <div className="flex1">
              <span className="nav-text">Check all</span>
            </div>
            <div className="">
              <Checkbox
                className="fRight marginBottom20"
                onChange={(event: any) => {
                  event.target.checked
                    ? (this.user.permission = [
                        "dashboard",
                        "alerts",
                        "product-scans",
                        "leads",
                        "qr-code-managers",
                        "landing-pages",
                        "products",
                        "distributors",
                        "categories",
                        "users",
                        "activity-log",
                      ])
                    : (this.user.permission = []);
                }}
              ></Checkbox>
            </div>
          </div>

          <div className="edit-permmision">
            {this.props.uiStore?.menuPages.map((page) => {
              return page.link !== "subscribers" ? (
                <div className="flex white br-bottem br-grayM paddingHor10 marginBottom10">
                  <div className="flex1">
                    <img alt="svg" src={page.icon} />
                    <span className="nav-text marginLeft10">{page.name}</span>
                  </div>
                  <div className="">
                    <Checkbox
                      checked={user.permission.includes(page.link)}
                      value={page.link}
                      onChange={(event: any) => this.togglePermissions(event)}
                    ></Checkbox>
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        </div>

        <div className="permissions marginTop40">
          <div className="f27 bold">Activity Log</div>
          <Table
            className="marginTop20"
            dataSource={this.user?.activities?.map((row: any) => {
              row.key = row.id;
              return row;
            })}
            columns={this.columns}
          />
        </div>
      </EditUserStyled>
    );
  }
}
export default withRouter(EditUser);
