import styled from "styled-components";

const QrCodesStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);
  .sc-fznyAO.hdDwJr {
    display: initial;
    margin: 11px;
  }
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #4dbd74 !important;
  }
`;

export { QrCodesStyled };
