/* eslint-disable @typescript-eslint/no-useless-constructor */
import LeadsService from './lead.service';
import Leads from './lead.model';
import PageStore from '../page.store';

class LeadsStore extends PageStore {
	constructor(leadsService: LeadsService) {
		super(leadsService);
	}

	url(): string {
		return '/leads';
	}

	get model(): any {
		return Leads;
	}
}

export default LeadsStore;
