import BaseService from "../base.service";

class AuthService extends BaseService {
  constructor(baseUrl: string = "") {
    super(baseUrl);
  }

  login = (user: any) => {
    return this.post(`/auth/login`, user);
  };

  forgotPassword = (user: any) => {
    return this.post(`/auth/forgot-password`, user);
  };

  resetPassword = (user: any) => {
    return this.post(`/auth/reset-password`, user);
  };

  getCurrentUser = () => {
    return this.get("/auth/me");
  };
}

export default AuthService;
