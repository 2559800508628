import styled from "styled-components";

const LeadsStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);

  .ant-table-row-expand-icon::after,
  .ant-table-row-expand-icon::before,
  .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
  button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
    color: #63c2de !important;
    border-color: #63c2de !important;
  }

  .table_action {
    padding-left: 0;
  }
  .table_action {
    .ant-divider:first-child {
      margin-left: 0 !important;
    }
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #63c2de;
  }
`;

export { LeadsStyled };
