import styled from "styled-components";

const GenerateQrDetailsStyled = styled.div`
  .ant-input-number {
    width: 100% !important;
  }
  .plus-minus-wrapper {
    padding: 34px 0 0 20px;
    align-items: flex-start;
    :nth-child(2) {
      margin-left: 20px;
    }
  }

  .max200 {
    max-width: 400px;
  }
`;

export { GenerateQrDetailsStyled };
