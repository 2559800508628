/* eslint-disable @typescript-eslint/no-useless-constructor */
import AlertSettingsService from "./AlertSetting.service";
import AlertSettings from "./AlertSetting.model";
import PageStore from "../page.store";
import { computed } from "mobx";

class AlertSettingsStore extends PageStore {
  constructor(AlertSettingsService: AlertSettingsService) {
    super(AlertSettingsService);
  }

  url(): string {
    return "/alert-settings";
  }

  get model(): any {
    return AlertSettings;
  }

  @computed get currentModel(): any {
    console.log(["this.models[0]", this.models[0]]);
    return this.models[0] || {};
  }
}

export default AlertSettingsStore;
