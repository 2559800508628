import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IActivities } from "./Activities.interface";
import { observable, computed } from "mobx";

class Activities extends BaseModel implements IActivities {
  @observable user: any = null;
  @observable name: string = "";
  @observable createdAt: string = "";

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "activity";
  }
  responseModelsKey(): string {
    return "activities";
  }

  get url(): string {
    return "/activities";
  }

  @computed get toJson(): { [key: string]: any } {
    return this._toJson({
      user: this.user,
      name: this.name,
    });
  }
}

export default Activities;
