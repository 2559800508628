import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { FilterDashboardDataStyled } from "./styled";
import FilterPage from "../../../../components/FilterPage/FilterPage";
import { observer, inject } from "mobx-react";
import DashboardStore from "../../../../stores/dashboard/dashboard.store";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { action } from "mobx";

interface Props extends RouteComponentProps<any> {
  dashboardStore?: DashboardStore;
}
@inject("dashboardStore")
@observer
class FilterDashboardData extends React.PureComponent<Props, any> {
  @action deleteFilter = (key: string) => {
    this.props!.dashboardStore!.filter = Object.keys(
      this.props!.dashboardStore!.filter
    )
      .filter((k: any) => k !== key)
      .reduce((filter: any, k: any) => {
        filter[k] = this.props!.dashboardStore!.filter[k];
        return filter;
      }, {});
  };

  render() {
    const hasFilters =
      Object.keys(this.props?.dashboardStore?.filter).length > 0;
    return (
      <FilterDashboardDataStyled className="flex">
        <FilterPage
          filters={["distributor", "territory", "category"]}
          store={this.props!.dashboardStore}
          title="Filter Dashboard"
        />
        {hasFilters && (
          <div className="filters flex flexVerCenter">
            <div className="f21 filter-title">Active filter: </div>
            {Object.keys(this.props?.dashboardStore?.filter)
              .filter((key: any) =>
                Array.isArray(this.props?.dashboardStore?.filter[key])
                  ? this.props?.dashboardStore?.filter[key].length > 0
                  : true
              )
              .map((key: string) => (
                <Button type="primary" className="white b-gray">
                  <CloseOutlined
                    onClick={() => {
                      this.deleteFilter(key);
                    }}
                  />
                  {key}
                </Button>
              ))}
          </div>
        )}
      </FilterDashboardDataStyled>
    );
  }
}
export default withRouter(FilterDashboardData);
