import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { InfoBoxStyled } from "./styled";
import { inject, observer } from "mobx-react";
import DashboardStore from "../../../../stores/dashboard/dashboard.store";
import Range from "../../../../components/Range/Range";
import numeral from "numeral";

interface Props extends RouteComponentProps<any> {
  dashboardStore?: DashboardStore;
}

@inject("dashboardStore")
@observer
class InfoBox extends React.PureComponent<Props, any> {
  componentDidMount = () => {
    this.props.dashboardStore!.fetchBoxInfo();
  };
  render() {
    const { dashboardStore } = this.props;
    return (
      <InfoBoxStyled>
        <div className="grid grid5 gap30">
          <div className="radius flex b-blue padding10 white info-box">
            <div className="textLeft f21 flex1">QRs Generated</div>
            <div className="textRight f35 ">
              {dashboardStore?.loadingBoxInfo ? (
                <img
                  src="/images/spinners/box-info-spinner-1s-200px.svg"
                  alt="loader"
                />
              ) : (
                numeral(dashboardStore!.boxInfo.qrCount).format("0,0")
              )}
            </div>
          </div>
          <div className="radius flex b-green padding10 white info-box">
            <div className="textLeft flex1">
              <div className="f21"> Valid Scans</div>
              <div className="f12">
                {`${Math.floor(
                  (dashboardStore!.boxInfo.validScanCount /
                    (dashboardStore!.boxInfo.validScanCount +
                      dashboardStore!.boxInfo.invalidScanCount) || 0) * 100
                )}% of Total`}
              </div>

              <Range
                value={
                  (dashboardStore!.boxInfo.validScanCount /
                    (dashboardStore!.boxInfo.validScanCount +
                      dashboardStore!.boxInfo.invalidScanCount) || 0) * 100
                }
              />
            </div>
            <div className="textRight f35 flex info-box-count">
              {/* <span>
                <input type="range" min="0" max="100" value="50" />
              </span> */}
              <span>
                {dashboardStore?.loadingBoxInfo ? (
                  <img
                    src="/images/spinners/box-info-spinner-1s-200px.svg"
                    alt="loader"
                  />
                ) : (
                  numeral(dashboardStore!.boxInfo.validScanCount).format("0,0")
                )}
              </span>
            </div>
          </div>
          <div className="radius flex b-red padding10 white info-box">
            <div className="textLeft flex1">
              <div className="f21"> Invalid Scans</div>
              <div className="f12">{`${Math.floor(
                (dashboardStore!.boxInfo.invalidScanCount /
                  (dashboardStore!.boxInfo.validScanCount +
                    dashboardStore!.boxInfo.invalidScanCount) || 0) * 100
              )}% of Total`}</div>
              <Range
                value={
                  (dashboardStore!.boxInfo.invalidScanCount /
                    (dashboardStore!.boxInfo.validScanCount +
                      dashboardStore!.boxInfo.invalidScanCount) || 0) * 100
                }
              />
            </div>
            <div className="textRight f35 flex info-box-count">
              {/* <span>
                <input type="range" min="0" max="100" value="50" />
              </span> */}
              <span>
                {dashboardStore?.loadingBoxInfo ? (
                  <img
                    src="/images/spinners/box-info-spinner-1s-200px.svg"
                    alt="loader"
                  />
                ) : (
                  numeral(dashboardStore!.boxInfo.invalidScanCount).format(
                    "0,0"
                  )
                )}
              </span>
            </div>
          </div>
          <div className="radius flex b-yellow padding10 white info-box">
            <div className="textLeft flex1">
              <div className="f21">Not Scanned</div>
              <div className="f12">{`${Math.floor(
                (dashboardStore!.boxInfo.unScanCount /
                  dashboardStore!.boxInfo.qrCount || 0) * 100
              )}% of Total`}</div>
              <Range
                value={
                  (dashboardStore!.boxInfo.unScanCount /
                    dashboardStore!.boxInfo.qrCount || 0) * 100
                }
              />
            </div>
            <div className="textRight f35 flex info-box-count">
              {/* <span>
                <input type="range" min="0" max="100" value="50" />
              </span> */}
              <span>
                {dashboardStore?.loadingBoxInfo ? (
                  <img
                    src="/images/spinners/box-info-spinner-1s-200px.svg"
                    alt="loader"
                  />
                ) : (
                  numeral(dashboardStore!.boxInfo.unScanCount).format("0,0")
                )}
              </span>
            </div>
          </div>
          <div className="radius flex b-blue padding10 white info-box">
            <div className="textLeft f21 flex1">End-Consumer Leads</div>
            <div className="textRight f35 ">
              {dashboardStore?.loadingBoxInfo ? (
                <img
                  src="/images/spinners/box-info-spinner-1s-200px.svg"
                  alt="loader"
                />
              ) : (
                numeral(dashboardStore!.boxInfo.leadsCount).format("0,0")
              )}
            </div>
          </div>
        </div>
      </InfoBoxStyled>
    );
  }
}
export default withRouter(InfoBox);
