import styled from "styled-components";

const SubscribersStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);

  .period_status {
    width: 10px;
    height: 10px;
    background: #ffc107;
    display: inline-flex;
    border-radius: 50px;
    margin-left: 20px;
  }

  .first {
    width: 119px;
  }

  thead {
    th:nth-child(5) {
      display: none;
    }
  }

  td.ant-table-cell.ant-table-row-expand-icon-cell {
  }

  .table_action {
    width: 19px;
    padding-left: 0px !important;
  }

  .ant-table-row-expand-icon-cell {
    padding-right: 0;
  }

  .ant-table-row-expand-icon-cell .b-grayM2 {
    height: 23px;
    margin-left: 15px;
    margin-top: 3px;
  }

  th.ant-table-cell.ant-table-row-expand-icon-cell {
    height: 100%;
    display: revert;
  }

  th.ant-table-cell.textLeft {
    display: block !important;
  }
`;

export { SubscribersStyled };
