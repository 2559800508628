import styled from "styled-components";

const ScanStyled = styled.div`
  .ant-col,
  label {
    word-wrap: break-word !important;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .loader-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #3b4149;
  }
  .box-message {
    margin: 0 auto;
    text-align: center;
    top: 0;
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    max-width: 600px;
  }

  .header {
    width: 100%;
    background: #959595;
    position: relative;
    text-align: center;
    z-index: 2;
  }

  .footer {
    background: #818286;
    height: 30px;
    text-align: center;
  }

  .footer .flex {
    margin: 0 auto;
    font-stretch: expanded;
    padding-top: 3px;
    width: 225px;
  }

  .ant-form-item-label > label {
    height: auto;
  }

  .footer img {
    height: 12px;
    margin: 5px;
  }

  .title {
    padding: 20px;
    word-wrap: break-word;
  }

  option {
    background: #282d32;
  }
  img.scan-image {
    max-width: 400px;
    display: block;
    margin: 0 auto;
  }

  label {
    display: block;
    text-align: left;
    margin-top: 25px;
  }

  input.ant-input {
    display: block;
    width: 100%;
    background: #282d32;
    border: none;
    border: 1px solid #8a8e91;
    border-radius: 0px;
    height: 22px;
    color: #ffffff;
    padding: 24px;
  }

  button:focus {
    outline: 0;
  }

  .company-logo {
    max-height: 70px;
    margin: 21px auto;
  }

  .header-content {
    z-index: 22;
    position: relative;
  }

  .ant-picker {
    background: #282d32;
  }

  input#when_did_purchase {
    color: white;
  }

  button {
    background: #ffffff !important;
    margin-top: 20px;
    width: 254px;
    padding: 27px;
    border-radius: 60px !important;
    font-weight: bold;
    line-height: 0.5px;
    font-size: 20px;
  }
  .scan-page {
    background: #3a4149;
  }

  .ant-row.ant-form-item {
    width: 100%;
    text-align: left;
    margin: 12px 0px !important;
  }

  .ant-select-selector {
    height: 50px !important;
    border-radius: 0px !important;
  }

  span.ant-select-selection-item {
    padding-top: 9px !important;
  }

  @media screen and (max-width: 600px) {
    img.scan-image {
      max-width: 100%;
    }

    .box-message {
      margin: 7px !important;
      min-height: calc(100vh - 344px);
    }
  }
`;

export { ScanStyled };
