import Category from "./category.model";
import PageStore from "../page.store";
import { observable, computed, reaction, action } from "mobx";
import AuthStore from "../auth/auth.store";
import DistributorsService from "../distributors/distributor.service";

class CategoriesStore extends PageStore {
  authStore: any;

  @observable itemsForCreateQR: [] = [];
  @observable allItems: Category[] = [];
  @observable landingPageCategories: [] = [];

  constructor(distributorsService: DistributorsService, authStore: AuthStore) {
    super(distributorsService);
    this.authStore = authStore;

    reaction(
      () => this.authStore.user,
      (user, reaction) => {
        if (user) {
          this.init();
        } else {
        }
      }
    );
  }

  init = () => {
    this.fetchAllCategories();
  };

  url(): string {
    return "/categories";
  }

  get model(): any {
    return Category;
  }

  fetchForQrCategories = async () => {
    try {
      const response = await this.apiService.get(
        `${this.url()}?filterForCreateQR=1`
      ).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }

      this.itemsForCreateQR = data.categories;
    } catch (error) {
      console.error(error);
    }
  };

  fetchAllCategories = async () => {
    try {
      const response = await this.apiService.get(this.url(), {}).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }

      this.allItems = data.categories;
    } catch (error) {
      console.error(error);
    }
  };

  fetchLandingPageCategories = async () => {
    try {
      const response = await this.apiService.get(this.url() + "/landing-page")
        .promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }

      this.landingPageCategories = data.categories || [];
    } catch (error) {
      console.error(error);
    }
  };

  @computed get productParents() {
    return this.allItems.filter((category: any) => category.child_number === 2);
  }

  separateCategoryTree = (categories: Category[]) => {
    return categories.reduce(
      (tree: any, c: any) => {
        if (c.parent_id === null) {
          tree.categories.push(c);
        }
        if (c.child_number === 2) {
          tree.subCategories.push(c);
        }
        if (c.child_number === 3) {
          tree.products.push(c);
        }
        return tree;
      },
      { categories: [], subCategories: [], products: [] }
    );
  };

  @action onUpdateCategory = (data: any) => {
    this.allItems = this.allItems.map((c: any) =>
      c.id !== data.id ? c : data
    );
  };
}

export default CategoriesStore;
