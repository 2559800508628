import styled from "styled-components";

const CreateQrCodeStyled = styled.div`
  max-width: 847px;
  background: #3b4149;
  padding: 20px;

  .inputs-wrapper {
    border-top: 1px solid #818286;
    .ant-select,
    input {
      width: 100% !important;
    }
    .quantity {
      max-width: 154px;
      margin-right: 30px;
    }
  }

  .ant-radio-inner::after {
    background: #4bad6f !important;
  }

  .download-template {
    text-align: right;
    flex: 1;
  }
`;

export { CreateQrCodeStyled };
