
	import BaseService from "../base.service";

	class DistributorsService extends BaseService {
		constructor(baseUrl: string = "") {
		super(baseUrl);
		}
	}
	
	export default DistributorsService;
		
	