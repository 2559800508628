import BaseHttpService, { OptionsRequest } from "../services/base.http.service";
import BaseStore from "./base.store";
import { action, observable, runInAction } from "mobx";

abstract class BaseModel {
  abstract get url(): string;

  /**
   * Hold status if update, delete, or save in server ( all action to server)
   */
  @observable loading: boolean = false;
  @observable updateLoading: boolean = false;
  @observable deleteLoading: boolean = false;

  id: number | null = null;
  httpService: BaseHttpService;
  store: BaseStore;

  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    this.store = store;
    this.httpService = httpService;
  }
  get rootUrl(): string {
    return `${this.url}/${this.id || ""}`;
  }

  abstract get regularModelKey(): string;

  @action
  public _updateData(data: any) {
    const _this: any = this;
    Object.keys(data).forEach((key: any) => {
      typeof _this[key] !== "undefined" && (_this[key] = data[key]);
      if (key === "children" && data[key].length > 0) {
        _this[key] = data[key].map((child: any) => this.store.create(child));
      }
    });
  }

  save(): OptionsRequest {
    const response = this.id
      ? this.httpService.put(this.rootUrl, this.toJson)
      : this.httpService.post(this.rootUrl, this.toJson);

    response.promise.then(async (res) => {
      const data = await res.clone().json();
      this._updateData(data[this.regularModelKey] || {});
    });

    return response;
  }

  @action async destroy() {
    try {
      this.deleteLoading = true;
      const { promise } = this.httpService.del(this.rootUrl, this.toJson);
      await promise;
      this.deleteLoading = false;
      this.store.del(this);
      return promise;
    } catch (error) {
      this.deleteLoading = false;
      console.error(error);
      return error;
    }
  }

  abstract get toJson(): { [key: string]: any };

  protected _toJson = (json: any) => JSON.parse(JSON.stringify(json));

  get dataToExport() {
    return this.toJson;
  }
}

export default BaseModel;
