import DashboardService from "./dashboard.service";
import { observable, reaction, action } from "mobx";
import { IAlert } from "../alerts/alert.interface";

interface IBoxInfo {
  qrCount: number;
  validScanCount: number;
  invalidScanCount: number;
  unScanCount: number;
  leadsCount: number;
}

interface IScansStats {
  scans: [];
  unScans: [];
}

class DashboardStore {
  apiService: any;

  @observable loadingFetchScansStats: boolean = false;
  @observable scansStats: IScansStats = {
    scans: [],
    unScans: [],
  };
  @observable leadsStats: any = {};
  @observable filter: any = {};
  @observable loadingFetchCriticalAlerts: boolean = false;
  @observable criticalAlerts: IAlert[] = [];
  @observable mapMarkers: any = [];
  @observable loadingBoxInfo: boolean = false;
  @observable boxInfo: IBoxInfo = {
    qrCount: 0,
    validScanCount: 0,
    invalidScanCount: 0,
    unScanCount: 0,
    leadsCount: 0,
  };

  constructor(dashboardService: DashboardService) {
    this.apiService = dashboardService;

    reaction(
      () => this.filter,
      (filter, reaction) => {
        this.fetchBoxInfo();
        this.fetchScansStats();
        this.fetchLeadsStats();
        this.fetchMapsLocations();
        this.fetchCriticalAlerts();
      }
    );
  }

  @action
  clearFilters = () => {
    return (this.filter = {});
  };

  @action onFilterChanged = (filter: any) => {
    this.filter = filter;
  };
  fetchBoxInfo = async () => {
    try {
      this.loadingBoxInfo = true;
      const response = await this.apiService.get(
        `/dashboard/info-counter?${this.apiService.parseObjectToUrlParams({
          filter: JSON.parse(JSON.stringify(this.filter)),
        })}`
      ).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }
      this.boxInfo = data as IBoxInfo;
    } catch (error) {
      console.error(error);
    }
    this.loadingBoxInfo = false;
  };

  fetchLeadsStats = async () => {
    try {
      const response = await this.apiService.get(
        `/dashboard/leadsStats?${this.apiService.parseObjectToUrlParams({
          filter: JSON.parse(JSON.stringify(this.filter)),
        })}`
      ).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }
      this.leadsStats = data.map((month: any) => ({
        ...month,
        Count: month.count,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  fetchCriticalAlerts = async () => {
    try {
      this.loadingFetchCriticalAlerts = true;
      const response = await this.apiService.get(
        `/dashboard/critical-alerts?${this.apiService.parseObjectToUrlParams({
          filter: JSON.parse(JSON.stringify(this.filter)),
        })}`
      ).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }
      this.criticalAlerts = data as IAlert[];
    } catch (error) {
      console.error(error);
    }
    this.loadingFetchCriticalAlerts = false;
  };

  fetchScansStats = async () => {
    try {
      this.loadingFetchScansStats = true;
      const response = await this.apiService.get(
        `/dashboard/scans-stats?${this.apiService.parseObjectToUrlParams({
          filter: JSON.parse(JSON.stringify(this.filter)),
        })}`
      ).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }

      data.scans = data.scans.map((scan: any) => ({
        ...scan,
        Count: scan.count ? scan.count : 0,
      }));

      data.unScans = data.unScans.map((unScan: any) => ({
        ...unScan,
        Count: unScan.qrUnScanCount,
      }));

      this.scansStats = data;
    } catch (error) {
      console.error(error);
    }
    this.loadingFetchScansStats = false;
  };

  fetchMapsLocations = async () => {
    try {
      this.loadingFetchScansStats = true;
      const response = await this.apiService.get(
        `/dashboard/maps-markers?${this.apiService.parseObjectToUrlParams({
          filter: JSON.parse(JSON.stringify(this.filter)),
        })}`
      ).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }
      this.mapMarkers = data;
    } catch (error) {
      console.error(error);
    }
  };
}

export default DashboardStore;
