/* eslint-disable @typescript-eslint/no-useless-constructor */
import Distributors from "./distributor.model";
import PageStore from "../page.store";
import Distributor from "./distributor.model";
import { action, observable, reaction } from "mobx";
import CategoriesService from "../categories/category.service";
import AuthStore from "../auth/auth.store";

class DistributorsStore extends PageStore {
  authStore: any;

  @observable allItems: Distributor[] = [];

  constructor(categoriesService: CategoriesService, authStore: AuthStore) {
    super(categoriesService);
    this.authStore = authStore;

    reaction(
      () => this.authStore.user,
      (user, reaction) => {
        if (user) {
          this.init();
        } else {
          // here user can reset data after logout
        }
      }
    );
  }

  /**
   * Run after user changed - usual after login
   */
  init = () => {
    this.fetchAllItems();
  };

  url(): string {
    return "/distributors";
  }

  get model(): any {
    return Distributors;
  }

  fetchAllItems = async (force: boolean = false) => {
    try {
      if (!force && this.allItems.length) {
        return;
      }
      const response = await this.apiService.get(this.url(), {}).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }
      this.allItems = data.distributors;
    } catch (error) {
      console.error(error);
    }
  };

  @action onUpdateCategory = (data: any) => {
    this.allItems = this.allItems.map((c: any) =>
      c.id !== data.id ? c : data
    );
  };
}

export default DistributorsStore;
