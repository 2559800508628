import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import SuperAdminDashboard from "../SuperAdminDashboard/SuperAdminDashboard";
import DashboardCustomers from "../DashboardCustomers/DashboardCustomers";
import { observer, inject } from "mobx-react";
import AuthStore from "../../stores/auth/auth.store";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class Dashboard extends React.PureComponent<Props, any> {
  displaySuperAdminDashboard: boolean = false;

  render() {
    return this.props?.authStore?.user?.superAdmin ? (
      <SuperAdminDashboard />
    ) : (
      <DashboardCustomers />
    );
  }
}
export default withRouter(Dashboard);
