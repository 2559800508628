import styled from "styled-components";

const SidebarStyled = styled.div`
  background-color: #3e444c;
  .ant-menu-item,
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    position: relative;
    margin: 0;
    :after {
      position: absolute;
      content: "";
      width: 80%;
      bottom: 0;
      background-color: #151515;
      opacity: 1;
      transform: none;
      height: 1px;
      margin: 0 auto;
      left: 0;
      top: auto;
    }
  }
  li {
    font-weight: 300;
    font-size: 18px;
  }
  li.group-title {
    padding-left: 24px;
    text-transform: uppercase;
    color: #a0a3a6;
    margin: 20px 0 10px 0;
    font-size: 12px;
    line-height: initial;
    font-weight: 400;
  }

  .icon-red .anticon.anticon-user {
    color: #b92333;
  }

  .icon-yellow .anticon.anticon-user {
    color: #e6b111;
  }
  .icon-green .anticon.anticon-user {
    color: #4bae70;
  }
  .icon-blue .anticon.anticon-user {
    color: #63c2de;
  }

  .gap10 {
    gap: 10px;
  }
  /* .nav-text {
		font-size: 18px; */
  /* } */

  .ant-menu img {
    width: 16px;
    margin-right: 10px;
  }

  .logo {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #23282c;
    img {
      max-width: 90%;
    }
  }

  .profile_menu {
    left: 20px;
    bottom: 0px;
    width: 162px;
    border-top: 1px solid #151515;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .profile_menu img {
    width: 35px !important;
    height: 35px;
    margin-right: 37px !important;
    object-fit: cover;
  }

  .ant-dropdown li {
    background: transparent !important;
    border-bottom: 1px solid #9f9f9f;
  }

  ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    background: #282d32 !important;
  }
`;

export { SidebarStyled };
