import React from "react";
import { Redirect, Route } from "react-router-dom";

import { inject, observer } from "mobx-react";
import AuthStore from "../../stores/auth/auth.store";

interface Props {
  authStore?: AuthStore;
  component?: any;
  path: string;
  location?: any;
}

@inject("authStore")
@observer
class PrivateRoute extends React.PureComponent<Props, any> {
  componentDidMount() {
    console.log(this.props);
  }

  render() {
    const { component: Component, ...rest } = this.props;
    localStorage.setItem("referrer", this.props?.location?.pathname);
    const isAuthenticated = this.props!.authStore!.isAuthenticated;
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to="/auth/login" />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
