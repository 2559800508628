import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ScanStyled } from "./styled";
import CategoriesStore from "../../stores/categories/category.store";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { Input, Button, message, Form, Select, Radio, DatePicker } from "antd";
import Lead from "../../stores/leads/lead.model";
import LeadsStore from "../../stores/leads/lead.store";
import axios from "axios";
import ProductScansStore from "../../stores/product-scans/product-scan.store";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import AuthStore from "../../stores/auth/auth.store";
import Validation from "../../services/validation";

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  categoriesStore: CategoriesStore;
  leadsStore?: LeadsStore;
  authStore?: AuthStore;
  productScansStore: ProductScansStore;
}

@inject("authStore", "leadsStore", "categoriesStore", "productScansStore")
@observer
class Scan extends React.PureComponent<Props, any> {
  @observable data: any = {};
  @observable lead: Lead = this.props.leadsStore?.create();
  @observable success_message: string = "";
  @observable error_message: string = "";
  @observable loading: boolean = true;
  @observable disableSubmit: boolean = false;
  userGeoLocation: any = {};

  formFileds: any = [
    "Please Select",
    "Online advertising",
    "Online search",
    "From a friend",
    "Tv ad",
    "Newspaper ad",
  ];

  componentDidMount = async () => {
    debugger
    this.loadData();
  };

  loadLadingPage = (): any => {
    var url = window.location.href;
    var parts = url.split("/");
    const group_id = parts[parts.length - 3];
    const qr_id = Number(parts[parts.length - 2]);

    this.lead.qr_code_id = qr_id;
    this.lead.group_id = group_id;

    const promise = this.props
      .productScansStore!.scanPageAndGetLandingPage(group_id, qr_id)
      .then(async (response: any) => {
        const data = await response.json();
        this.data = data;
        return;
      });

    return promise;
  };

  loadUserInfo = (): any => {
    const promise = axios
      .get(`${process.env.REACT_APP_API_URL}/userInfo`)

      .then((response: any) => {
        debugger
        this.userGeoLocation = {
          lat: response.data.data.location.latitude,
          lng: response.data.data.location.longitude,
          // city: response?.data?.city,
          country: response.data.data.location.country.name,
        };
        return;
      });
    return promise;
  };

  loadData = async () => {
    try {
      debugger
      await Promise.all([this.loadLadingPage(), this.loadUserInfo()]);
      const places: any = await this.getPlaces(this.userGeoLocation);
      let territory = undefined;
      let i = 0;
      do {
        territory = places.find(
          (place: any) => place.formatted_address === this.data.territories[i]
        );
        i = i + 1;
      } while (territory === undefined && i < this.data.territories.length);
      this.createScan(territory !== undefined ? 1 : 0);
    } catch (error) {console.log(error)}
  };

  createScan = async (territoryScanValid: number, territory: any = null) => {
    const countScanValid = this.data.scanValid;
    this.data.scanValid = Math.min(this.data.scanValid, territoryScanValid);
    debugger
    try {
      const response = await this.props.productScansStore
        .create({
          ...this.data,
          territoryScanValid,
          countScanValid,
          geo: this.userGeoLocation,
        })
        .save().promise;
      const data = await response.json();
      this.data.scanValid = data.displayPageValid;
      this.loading = false;
    } catch (error) {}
  };

  getPlaces = ({ lat, lng }: any) => {
    return new Promise((resolve, rejected) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          return resolve(results);
        }
        rejected([]);
      });
    });
  };

  generateForm(fields: any) {
    let form: any = <div className="" />;

    for (let i: number = 0; i < fields.length; i++) {
      switch (fields[i]) {
        case "First Name":
          form = [
            form,
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                { required: true, message: "Please enter first name" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value && Validation.onlyLetters(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      !value ? "" : "First name should contain letters only"
                    );
                  },
                }),
              ]}
            >
              <Input
                value={this.lead.first_name}
                type="text"
                onChange={(event: any) =>
                  (this.lead.first_name = event.target.value)
                }
              />
            </Form.Item>,
          ];

          break;

        case "Last Name":
          form = [
            form,
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                { required: true, message: "Please enter last name" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value && Validation.onlyLetters(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      !value ? "" : "Last name should contain letters only"
                    );
                  },
                }),
              ]}
            >
              <Input
                value={this.lead.last_name}
                type="text"
                onChange={(event: any) =>
                  (this.lead.last_name = event.target.value)
                }
              />
            </Form.Item>,
          ];

          break;

        case "Phone":
          form = [
            form,
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                { required: true, message: "Please enter phone number" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value && Validation.validatePhone(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      !value ? "" : "Phone number should contain numbers only"
                    );
                  },
                }),
              ]}
            >
              <Input
                onKeyPress={(e) => Validation.allowNumbersOnly(e)}
                value={this.lead.phone}
                type="phone"
                onChange={(event: any) =>
                  (this.lead.phone = event.target.value)
                }
              />
            </Form.Item>,
          ];
          break;

        case "Mobile Phone":
          form = [
            form,

            <Form.Item
              label="Mobile Phone"
              name="mobile_phone"
              rules={[
                { required: true, message: "Please enter mobile phone number" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value && Validation.validatePhone(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      !value
                        ? ""
                        : "Mobile phone number should contain numbers only"
                    );
                  },
                }),
              ]}
            >
              <Input
                onKeyPress={(e) => Validation.allowNumbersOnly(e)}
                type="mobile_phone"
                onChange={(event: any) =>
                  (this.lead.mobile_phone = event.target.value)
                }
              />
            </Form.Item>,
          ];
          break;

        case "Email":
          form = [
            form,
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter email address" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      value &&
                      Validation.validateEmail(value) &&
                      !Validation.noEmojies(value)
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      !value ? "" : "Email format is incorrect"
                    );
                  },
                }),
              ]}
            >
              <Input
                value={this.lead.email}
                type="email"
                onChange={(event: any) => {
                  this.lead.email = event.target.value;
                }}
              />
            </Form.Item>,
          ];
          break;

        case "Address":
          form = [
            form,
            <Form.Item
              rules={[{ required: true, message: "Please add  address" }]}
              label="Address"
              name="address"
            >
              <Input
                type="text"
                onChange={(event: any) =>
                  (this.lead.address = event.target.value)
                }
              />
            </Form.Item>,
          ];

          break;

        case "Where did you purchase the product?":
          form = [
            form,
            <>
              <Form.Item
                label="Where did you purchase the product?"
                name="purchase_location"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Radio.Group
                  className="flex"
                  onChange={(event: any) => {
                    this.lead.purchase_location = event.target.value;
                  }}
                  value={this.lead.purchase_location}
                >
                  <Radio.Button value="online">Online</Radio.Button>
                  <Radio.Button value="local">Local Store</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {this.lead.purchase_location === "online" ? (
                <>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter website name or address",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value && Validation.validateURL(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            !value ? "" : "Please add a valid url"
                          );
                        },
                      }),
                    ]}
                    label="On which website did you make this purchase?"
                    name="which_site"
                  >
                    <Input
                      type="text"
                      onChange={(event: any) =>
                        (this.lead.which_site = event.target.value)
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter the seller name",
                      },
                    ]}
                    label="What is the seller name?"
                    name="seller_name"
                  >
                    <Input
                      type="text"
                      onChange={(event: any) =>
                        (this.lead.seller_name = event.target.value)
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please choose the date of purchase",
                      },
                    ]}
                    label="When did you purchase the product?"
                    name="when_did_purchase"
                  >
                    <DatePicker
                      format={"MM/DD/YYYY"}
                      onChange={(date: any, dateString: string) =>
                        (this.lead.when_did_purchase = dateString)
                      }
                    />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter store name" },
                  ]}
                  label="What is the store name?"
                  name="store_name"
                >
                  <Input
                    type="text"
                    onChange={(event: any) =>
                      (this.lead.store_name = event.target.value)
                    }
                  />
                </Form.Item>
              )}
            </>,
          ];

          break;

        case "What was the price that you paid for this product?":
          form = [
            form,
            <Form.Item
              label="What was the price that you paid for this product?"
              name="purchase_price"
              rules={[
                {
                  required: true,
                  message: "Please enter the product price",
                },
              ]}
            >
              <Input
                type="text"
                onChange={(event: any) =>
                  (this.lead.purchase_price = event.target.value)
                }
              />
            </Form.Item>,
          ];

          break;

        case "Where did you hear about this product?":
          form = [
            form,

            <Form.Item
              label="Where did you hear about this product?"
              name={"purchase_hear_about"}
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              {/* <Select
                placeholder=""
                optionFilterProp="children"
                onChange={(values: any) => {
                  this.lead.purchase_hear_about = values;
                }}
                // onChange={() => {}}
                onFocus={() => {}}
                onBlur={() => {}}
                onSearch={() => {}}
                // value={this.lead.purchase_hear_about}
                // defaultValue={this.lead.purchase_hear_about}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              ></Select> */}

              <select
                onChange={(values: any) => {
                  this.lead.purchase_hear_about = values.target.value;
                }}
                className="ant-input"
              >
                {this.formFileds.map((filed: any, i: number) => (
                  <option value={filed} key={i}>
                    {filed}
                  </option>
                ))}
              </select>
            </Form.Item>,
          ];

          break;
      }
    }

    form = [
      form,
      <div className="marginBottom100">
        <Form.Item className="marginCenter">
          <Button
            disabled={this.disableSubmit}
            htmlType="submit"
            className="transparent radius marginCenter show"
          >
            SUBMIT
          </Button>
        </Form.Item>

        {this.success_message.length > 1 ? (
          <div className="green">&#10004; {this.success_message}</div>
        ) : (
          ""
        )}

        {this.error_message.length > 1 ? (
          <div className="red f20 bold">&#10004; {this.error_message}</div>
        ) : (
          ""
        )}
      </div>,
    ];

    return form;
  }

  createLead = async () => {
    this.lead.geo = this.userGeoLocation;
    debugger
    this.lead.scanValid = this.data.scanValid;
    this.lead.landing_page_id = this.data.LandingPage.id;
    this.lead.category_id = this.data.category_id;
    this.lead.distributer_id = this.data.distributer_id;
    this.lead.territory = this.userGeoLocation.country.name;
    const { promise } = this.lead.save();
    const response = await promise;
    this.disableSubmit = true;

    if (response.status === 200) {
      // message.success({
      //   content: "The message was delivered successfully.",
      //   // duration: 1000000,
      //   className: "wiser-message landing-page-message",
      //   icon: <CheckOutlined />,
      // });

      this.success_message = "Thank You for Submitting.";
    } else {
      // message.error({
      //   content: "Something went wrong when create new lead.",
      //   // duration: 1000000,
      //   className: "wiser-message subscriber-message",
      //   icon: <CloseOutlined />,
      // });

      this.error_message = "Something went wrong when create new lead.";
      this.disableSubmit = true;
    }
  };

  onFinish = (values: any) => {
    this.createLead();
  };

  onFinishFailed = (errorInfo: any) => {};

  render() {
    let { LandingPage, scanValid } = this.data;
console.log(LandingPage)
    // scanValid = true;
    if (!this.loading && LandingPage?.is_redirect) {
      window.location.replace(LandingPage.redirect_url);
      return;
    }

    return (
      <ScanStyled className="ScanStyled">
        {LandingPage ? (
         
        
            <div className="scan-page">
              <div className="header">
                <div className="header-content">
                  {this.data.subscription?.logo ? (
                    <img
                      alt="svg"
                      src={this.data.subscription?.logo}
                      className="company-logo"
                    />
                  ) : null}

                  {scanValid &&
                  LandingPage["valid_page_photo"] !== "undefined" ? (
                    <img
                      className="scan-image"
                      src={LandingPage["valid_page_photo"]}
                    />
                  ) : null}

                  {!scanValid &&
                  LandingPage["inValid_page_photo"] !== "undefined" ? (
                    <img
                      className="scan-image"
                      src={LandingPage["inValid_page_photo"]}
                    />
                  ) : null}

                  <div
                    className="f55  title"
                    style={{ color: LandingPage.color_title, fontSize: 45 }}
                  >
                    {scanValid
                      ? LandingPage.valid_page_title
                      : LandingPage.inValid_page_title}
                  </div>
                  {(LandingPage.valid_page_sub_title ||
                    LandingPage.inValid_page_sub_title) && (
                    <div
                      className="f35  title"
                      style={{ color: LandingPage.color_title, fontSize: 30 }}
                    >
                      {scanValid
                        ? LandingPage.valid_page_sub_title
                        : LandingPage.inValid_page_sub_title}
                    </div>
                  )}
                </div>
              </div>
              <div className="body">
                <Form
                  className="box-message"
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                >
                  {scanValid
                    ? this.generateForm(LandingPage.valid_page_qa)
                    : this.generateForm(LandingPage.inValid_page_qa)}
                </Form>

                <div className="footer">
                  <div className="flex">
                    <div>Powered by</div>
                    <img alt="svg" src="/images/wiser-logo.png" />
                  </div>
                </div>
              </div>
            </div>
          
        ) : (
          "Error to load landing page"
        )}
      </ScanStyled>
    );
  }
}
export default withRouter(Scan);
