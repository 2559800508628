import styled from "styled-components";

const RangeStyled = styled.div`
  border-radius: 5px;
  background: white;
  position: relative;
  height: 4px;
  background: #2f3539;
  overflow: hidden;
  div {
    height: 100%;
    position: absolute;
    background: white;
  }
`;

export { RangeStyled };
