import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IDistributor } from "./distributor.interface";
import { observable, computed } from "mobx";

class Distributor extends BaseModel implements IDistributor {
  @observable id: number | null = null;
  @observable name: string | null = null;
  @observable phone_number: string | null = null;
  @observable email: string | null = null;
  @observable categories: string[] = [];
  @observable territories: any[] = [];

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "distributor";
  }

  responseModelsKey(): string {
    return "distributors";
  }

  get url(): string {
    return "/distributors";
  }

  @computed get toJson(): { [key: string]: any } {
    return this._toJson({
      id: this.id,
      name: this.name,
      phone_number: this.phone_number,
      email: this.email,
      categories: this.categories,
      territories: this.territories,
    });
  }
}

export default Distributor;
