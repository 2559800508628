/* eslint-disable @typescript-eslint/no-useless-constructor */
import AuthService from "./auth.service";
import BaseStore from "../base.store";
import UsersStore from "../users/user.store";
import User from "../users/user.model";
import fetchIntercept from "fetch-intercept";
import { observable, action, runInAction } from "mobx";
import PageStore from "../page.store";

class AuthStore extends PageStore {
  private readonly LOCAL_STORAGE_TOKEN = "as98dj2193jo12njkn0-T";
  private readonly LOCAL_STORAGE_REFRESH_TOKEN = "lkm243o502934l2k3m-R";

  usersStore: any;
  authService: any;

  @observable init: boolean = false;

  constructor(authService: AuthService, usersStore: UsersStore) {
    super(authService);
    this.usersStore = usersStore;
    this.authService = authService;

    fetchIntercept.register({
      request: (url, config) => {
        // if (config.url?.includes("api") && this.getToken()) {
        config.headers = { ...config.headers, ...this.getAuthHeaders() };
        // }
        return [url, config];
      },

      requestError: (error) => {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
      },

      response: (response: any) => {
        const parseData = async (response: any) => {
          try {
            if (response.status === 401 || response.status === 403) {
              this.clear();
              return;
            }
            const data = await response.clone().json();

            if (response.status === 500 && data.message.includes("jwt exp")) {
              this.clear();
              return;
            }

            if (data.token) this.storeToken(data.token);
            if (data.token && data.user) this.storeUser(data.user);
          } catch (error) {
            console.error(error);
          }
        };

        parseData(response);

        return response;
      },

      responseError: (error) => {
        // Handle an fetch error
        return Promise.reject(error);
      },
    });

    window.addEventListener("storage", () => {
      if (!this.getToken()) this.fetchMe();
    });
    this.fetchMe();
  }

  private fetchMe = async () => {
    try {
      const data = await (
        await this.authService.getCurrentUser().promise
      ).json();

      if (!data.token) {
        runInAction(() => this.clear());
      }

      if (data.token) this.storeToken(data.token);
      if (data.user) this.storeUser(this.usersStore.create(data.user));
    } catch (error) {
      console.error(error);
      runInAction(() => this.clear());
    }
    this.init = true;
  };

  storeUser(user: any): void {
    this.user = user;
  }

  getAuthHeaders() {
    const token = this.getToken();

    return token ? { Authorization: "Bearer " + this.getToken() } : {};
  }

  storeToken(token: string): void {
    localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);
  }

  private getToken(): string | null {
    return localStorage.getItem(this.LOCAL_STORAGE_TOKEN);
  }

  @action clear = () => {
    this.user = null;
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(this.LOCAL_STORAGE_REFRESH_TOKEN);
  };

  url(): string {
    return "/auth";
  }

  get model(): any {
    return User;
  }

  login = (user: any) => {
    return this.authService.login(user);
  };

  forgotPassword = (user: any) => {
    return this.authService.forgotPassword(user);
  };

  resetPassword = (user: any) => {
    return this.authService.resetPassword(user);
  };

  @action logout = () => {
    this.clear();
  };
}

export default AuthStore;
