import styled from "styled-components";

const TableActionsCellStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export { TableActionsCellStyled };
