import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ActivityLogStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import moment from "moment";
import {
  LinkOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
  ArrowDownOutlined,
  CopyOutlined,
  EditOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { Avatar, Input, AutoComplete } from "antd";
import { inject, observer } from "mobx-react";
import ActivitiesStore from "../../stores/Activities/Activities.store";
import AuthStore from "../../stores/auth/auth.store";
import Header from "../../components/Header/Header";
import { action, observable, computed } from "mobx";
import debounce from "../../utils/debounce";
interface Props extends RouteComponentProps<any> {
  activitiesStore?: ActivitiesStore;
  authStore?: AuthStore;
}

@inject("activitiesStore", "authStore")
@observer
class ActivityLog extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable searchLoading: boolean = false;
  abortRequest: Function | undefined | null;
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string, record: any) => (
        <div className="flex">
          <Avatar src={record?.user?.profile_img} />
          <div className="margin5">{record?.user?.name}</div>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (value: string, record: any) => record?.user?.role,
    },

    {
      title: "Date & Time",
      dataIndex: "createdAt",
      render: (createdAt: any) => {
        return moment(createdAt).format("MMM D YYYY, HH:mm");
      },
      key: "createdAt",
    },

    {
      title: "Activity",
      dataIndex: "name",
      key: "name",
    },
  ];

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }
  componentDidMount = async () => {
    try {
      await this.props.activitiesStore?.fetchPage({ page: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { activitiesStore } = this.props;
    await activitiesStore!.fetchPage({
      page,
      pageSize,
      filter: activitiesStore!.filter,
    });
  };

  // onSearchDebounce = debounce(async (value: string) => {
  //   try {
  //     if (this.abortRequest) {
  //       this.abortRequest();
  //     }
  //     this.searchLoading = true;
  //     const { abort, promise } = await this.props!.activitiesStore!.onSearch({
  //       filter: { ...this.props!.activitiesStore!.filter, name: value },
  //     });

  //     this.abortRequest = abort;
  //     await promise;
  //     this.abortRequest = null;
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   this.searchLoading = false;
  // });

  onSearchDebounce = debounce(async (value: string) => {
    this.props.activitiesStore!.searchLoading = true;
    this.props.activitiesStore!.onFilterChanged({
      ...this.props!.activitiesStore!.filter,
      name: value,
    });
  });
  render() {
    const { activitiesStore } = this.props;
    return (
      <ActivityLogStyled>
        <Header
          title={"Activity Log"}
          rightMenu={
            <div className="flex flexVerCenter">
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    activitiesStore!.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          dataSource={this.props?.activitiesStore?.currentPage.map(
            (row: any) => {
              row.key = row.id;
              return row;
            }
          )}
          disableMultiSelectAction={true}
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.activitiesStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.activitiesStore!.changeUsersStatus(action, rowsIds);
          }}
          handleExportData={() => {
            let rows = this.props!.activitiesStore!.selectedRows.map(
              (row: any) => ({
                Name: row.user.name,
                Role: row.user.role,
                Time: moment(row.createdAt).format("MMM D, HH:mm"),
                Activity: row.name,
              })
            );
            this.props!.activitiesStore!.downloadCsv(rows, "activities");
          }}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.activitiesStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.activitiesStore!.selectedRows = selectedRows;
          }}
          total={this.props?.activitiesStore?.total || 0}
          pageSize={this.props?.activitiesStore?.pageSize || 0}
          currentPageNumber={
            this.props?.activitiesStore?.currentPageNumber || 0
          }
          NotAllActionOption={true}
          onPageSettingsChange={this.onPageSettingsChange}
        />
      </ActivityLogStyled>
    );
  }
}
export default withRouter(ActivityLog);
