/* eslint-disable @typescript-eslint/no-useless-constructor */
import UsersService from "./user.service";
import User from "./user.model";
import PageStore from "../page.store";
import { OptionsRequest } from "../../services/base.http.service";

class UsersStore extends PageStore {
  constructor(usersService: UsersService) {
    super(usersService);
  }

  url(): string {
    return "/users";
  }

  get model(): any {
    return User;
  }

  createUser = (user: any): OptionsRequest => {
    let formData = new FormData();

    for (var key in user) {
      if (Array.isArray(user[key])) {
        for (var i = 0; i < user[key].length; i++) {
          formData.append("permission[]", user[key][i]);
        }
      } else {
        formData.append(key, user[key]);
      }
    }

    return this.apiService.formData(`/users`, formData);
  };
}

export default UsersStore;
