import styled from "styled-components";

const DashboardMapStyled = styled.div`
  position: relative;
  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 2;
    .big {
      font-size: 29px;
      font-weight: bold;
      line-height: initial;
    }
    .small {
      font-size: 18px;
    }
  }
`;

export { DashboardMapStyled };
