import styled from "styled-components";

const ResetPassStyled = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #3b4149;

  .auth {
    width: 400px;
    margin: 0 auto;
    border: 1px solid #0a0a0a57;
    padding: 40px;
    border-radius: 5px;
  }

  .forgot {
    padding-top: 26px;
    text-align: right;
  }

  .input {
    padding: 0px;
  }

  span.ant-input-prefix {
    padding: 14px;
    border-right: 1px solid;
    margin: 0px;
  }

  input {
    background: #3b4149 !important;
    border-top-left-radius: none !important;
    border-bottom-left-radius: none !important;
    padding-left: 13px !important;
  }
`;

export { ResetPassStyled };
