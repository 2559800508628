import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RangeStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  value: number;
}

class Range extends React.PureComponent<Props, any> {
  render() {
    return (
      <RangeStyled>
        <div style={{ width: `${this.props.value}%` }}></div>
      </RangeStyled>
    );
  }
}
export default withRouter(Range);
