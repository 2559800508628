import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { FilterDataStyled } from "./styled";
import { Select, Radio, DatePicker, Input } from "antd";
import { observer, inject } from "mobx-react";
import CategoriesStore from "../../../../stores/categories/category.store";
import DistributorsStore from "../../../../stores/distributors/distributor.store";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

import DashboardStore from "../../../../stores/dashboard/dashboard.store";
import { observable } from "mobx";
import Countries from "../../../../services/countries";
import debounce from "../../../../utils/debounce";
import QrCodesStore from "../../../../stores/qr-codes/qr-codes.store";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

interface Props extends RouteComponentProps<any> {
  date?: Boolean;
  filter: any;
  dashboardStore?: DashboardStore;
  categoriesStore?: CategoriesStore;
  qrCodeStore?: QrCodesStore;
  distributorsStore?: DistributorsStore;
  onFilterChanged: (filter: any) => void;
  filterSelected: any;
  loading?: boolean;
  color?: string;
}

@inject("categoriesStore", "distributorsStore", "dashboardStore", "qrCodeStore")
@observer
class FilterData extends React.PureComponent<Props, any> {
  dateOptions = ["Today", "Past Week", "Past Month", "Past Quarter"];
  option_status = ["active", "disable"];
  @observable dateTypeFilter = "";

  getCollection = (filter: string): any[] => {
    switch (filter) {
      case "QR ID":
        return this.props.qrCodeStore!.models;
      case "category":
        return this.props.categoriesStore!.allItems;
      case "only_category":
        return this.props.categoriesStore!.allItems;

      case "territory":
        // const children: any = [];
        // for (let i = 10; i < Countries.length; i++) {
        //   children.push(<Option value={Countries[i]}>{Countries[i]}</Option>);
        // }
        return Countries.map((country: string) => ({
          id: country,
          name: country,
        }));
      case "distributor":
        return this.props.distributorsStore!.allItems;
      case "status":
        return [
          { id: 1, name: "Active" },
          { id: 0, name: "Disable" },
        ];
      case "subscription_period":
        return [
          { id: "1", name: "Active" },
          { id: "2", name: "Before Expired - (month)" },
          { id: "3", name: "Expired" },
        ];
      case "serial":
        return [
          { id: "1", name: "All" },
          { id: "0", name: "Only with serial" },
        ];
      case "scanValid":
        return [
          { id: 1, name: "Valid" },
          { id: 0, name: "Invalid" },
        ];
    }
    return [];
  };

  onRangeDateChange = (value: any, [start, end]: [string, string]) => {
    this.props.onFilterChanged({
      ...this.props.filterSelected,
      date: {
        start: new Date(start).getTime(),
        end: new Date(end).getTime(),
      },
    });
  };

  onDateTypeChange = (event: any) => {
    const end = moment().unix() * 1000;
    let start: any = moment().unix();
    switch (event.target.value) {
      case "Today":
        this.dateTypeFilter = "Today";
        start = moment().unix() * 1000;
        break;
      case "Past Week":
        this.dateTypeFilter = "Past Week";
        start = moment().subtract(1, "weeks").unix() * 1000;
        break;
      case "Past Month":
        this.dateTypeFilter = "Past Month";
        start = moment().subtract(1, "month").unix() * 1000;
        break;
      case "Past Quarter":
        this.dateTypeFilter = "Past Quarter";
        start = moment().subtract(1, "Q").unix() * 1000;
        break;
    }

    this.props.onFilterChanged({
      ...this.props.filterSelected,
      date: {
        start,
        end,
      },
    });
  };

  onSearchIdDebounce = debounce(async (value: string) => {
    console.log("onFilterChanged");
    console.log(value);
    this.props.onFilterChanged({
      ...this.props.filterSelected,
      id: value,
    });
  });

  onSearchId = (event: any) => this.onSearchIdDebounce(event.target.value);

  render() {
    const { loading } = this.props;
    const dates: any = this.props.filterSelected?.date?.start
      ? [
          moment(this.props.filterSelected.date.start),
          moment(this.props.filterSelected.date.end),
        ]
      : [];
    return (
      <FilterDataStyled color={this.props.color}>
        <div className="flex">
          <div className="flex1 bold">Filter Data</div>
          {loading && (
            <div>
              <LoadingOutlined /> loading
            </div>
          )}
        </div>
        <div className="flex">
          {this.props.date ? (
            <div className="date">
              <div className="marginTop10">
                <div className="f18 marginBottom10">By Date</div>

                <RangePicker value={dates} onChange={this.onRangeDateChange} />

                {!this.props.filter.includes("date_custom") && (
                  <Radio.Group
                    value={
                      this.props.filterSelected["date"] && this.dateTypeFilter
                    }
                    options={this.dateOptions}
                    defaultValue={"Today"}
                    onChange={this.onDateTypeChange}
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="fileds">
            {this.props.filter.map((filter: string, index: number) => {
              return (
                <div className="marginTop10" key={filter}>
                  <div className="f18 capitalize">
                    By{" "}
                    {filter === "only_category"
                      ? "Category"
                      : filter === "territory"
                      ? "Distributor's Territory"
                      : filter === "distributor"
                      ? "Distributor's Name"
                      : filter.split("_").join(" ")}
                  </div>
                  {filter !== "scan_valid" && filter !== "id" ? (
                    <Select
                      optionFilterProp="children"
                      className="transparent white radius marginRight10 "
                      placeholder="select"
                      mode="multiple"
                      value={this.props.filterSelected[filter]}
                      style={{ width: 320 }}
                      onChange={(value: any) =>
                        this.props.onFilterChanged({
                          ...this.props.filterSelected,
                          [filter]: value,
                        })
                      }
                      filterOption={(input: any, option: any) => {
                        return (
                          `${option?.children}`
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {!filter.includes("category") ? (
                        this.getCollection(filter).map((row: any) => (
                          <Option value={row.id} key={row.id}>
                            {filter === "QR ID" ? row.id : row.name}
                          </Option>
                        ))
                      ) : (
                        <>
                          {this.getCollection(filter).filter(
                            (c) => c.parent_id === null
                          ).length && (
                            <OptGroup label="Category">
                              {this.getCollection(filter)
                                .filter((c) => c.parent_id === null)
                                .map((row: any) => (
                                  <Option value={row.id} key={row.id}>
                                    {row.name}
                                  </Option>
                                ))}
                            </OptGroup>
                          )}
                          {this.getCollection(filter).filter(
                            (c) => c.child_number === 2
                          ).length && (
                            <OptGroup label="Sub Category">
                              {this.getCollection(filter)
                                .filter((c) => c.child_number === 2)
                                .map((row: any) => (
                                  <Option value={row.id} key={row.id}>
                                    {row.name}
                                  </Option>
                                ))}
                            </OptGroup>
                          )}
                          {filter !== "only_category" &&
                            this.getCollection(filter).filter(
                              (c) => c.child_number === 3
                            ).length && (
                              <OptGroup label="Products">
                                {this.getCollection(filter)
                                  .filter((c) => c.child_number === 3)
                                  .map((row: any) => (
                                    <Option value={row.id} key={row.id}>
                                      {row.name}
                                    </Option>
                                  ))}
                              </OptGroup>
                            )}
                        </>
                      )}
                    </Select>
                  ) : filter === "scan_valid" ? (
                    <Radio.Group
                      className="flex"
                      onChange={(event: any) => {
                        if (event.target.value === "reset") {
                          delete this.props.filterSelected[filter];
                          this.props.onFilterChanged({
                            ...this.props.filterSelected,
                          });
                          return;
                        }
                        this.props.onFilterChanged({
                          ...this.props.filterSelected,
                          [filter]: event.target.value,
                        });
                      }}
                      value={this.props.filterSelected[filter]}
                    >
                      <Radio value={1}>Valid</Radio>
                      <Radio value={0}>Invalid</Radio>
                      <Radio value={"reset"}>All</Radio>
                      {/* <Button
                    onClick={() => {
                      delete this.props.filterSelected[filter];
                      this.props.onFilterChanged({
                        ...this.props.filterSelected,
                      });
                    }}
                  >
                    reset
                  </Button> */}
                    </Radio.Group>
                  ) : (
                    <Input onChange={this.onSearchId} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </FilterDataStyled>
    );
  }
}
export default withRouter(FilterData);
