import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { LandingPagesStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import { Button, AutoComplete, Input, Divider, Tooltip, Popover } from "antd";
import { inject, observer } from "mobx-react";
import LandingPagesStore from "../../stores/landing-pages/landing-page.store";
import { observable, computed, action } from "mobx";
import debounce from "../../utils/debounce";
import { TableActionsCellStyled } from "../../styled";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import { ReactComponent as DeleteIcon } from "../../assets/action/cil-trash.svg";
import DeleteEntityButton from "../../components/DeleteEntityButton/DeleteEntityButton";

interface Props extends RouteComponentProps<any> {
  landingPagesStore?: LandingPagesStore;
}

@inject("landingPagesStore")
@observer
class LandingPages extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable searchLoading: boolean = false;

  abortRequest: Function | undefined | null;

  columns = [
    {
      title: "Title",
      dataIndex: "page_name",
      width: "20%",
      key: "page_name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category: any) => category?.name || " - ",
    },

    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (subCategory: any) => subCategory?.name || " - ",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product: any) => product?.name || " - ",
    },

    {
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      render: (text: string, record: any) => {
        const linkLabel = record.redirect_url ? "Redirect url" : "Link to demo";
        return (
          <TableActionsCellStyled>
            <Tooltip placement="bottom" title={linkLabel}>
              {record.redirect_url ? (
                <a
                  href={record.redirect_url}
                  target="_blank"
                  title="redirect link"
                >
                  <img
                    src="/images/action/cil-link_green.svg"
                    alt="cil-link_green.svg"
                  />
                </a>
              ) : (
                <Link to={`/landing-pages/demo/${record.id}/`}>
                  <img
                    src="/images/action/cil-link_green.svg"
                    alt="cil-link_green.svg"
                  />
                </Link>
              )}
            </Tooltip>

            <Divider type="vertical" className={"b-grayM2"} />

            <Tooltip placement="bottom" title={"Edit"}>
              <Link to={`/landing-pages/${record.id}/edit/`}>
                <img
                  src="/images/action/cil-pencil_green.svg"
                  alt="cil-pencil_green.svg"
                />
              </Link>
            </Tooltip>
            <Divider type="vertical" className={"b-grayM2"} />

            <DeleteEntityButton
              className="landing-pages"
              isDeleteAbleFn={() =>
                this.isDeleteAble("landing_page", record.id)
              }
              onDelete={() => this.onDelete(record)}
              itemName={"Landing page"}
              connectedItemsNames={["Landing pages"]}
              icon={<DeleteIcon />}
            />
          </TableActionsCellStyled>
        );
      },
      key: "action",
    },
  ];

  isDeleteAble = (entityType: string, recordId: number): Promise<boolean> => {
    return this.props!.landingPagesStore!.isDeleteAble(entityType, recordId);
  };

  onDelete = (record: any) => record.destroy();
  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  componentDidMount = async () => {
    try {
      await this.props.landingPagesStore?.fetchPage({ page: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { landingPagesStore } = this.props;
    await landingPagesStore!.fetchPage({
      page,
      pageSize,
      filter: landingPagesStore!.filter,
    });
    // router.navigate("templates", { page, pageSize });
  };

  // onSearchDebounce = debounce(async (value: string) => {
  //   try {
  //     if (this.abortRequest) {
  //       this.abortRequest();
  //     }
  //     this.searchLoading = true;
  //     const { abort, promise } = await this.props!.landingPagesStore!.onSearch({
  //       filter: { ...this.props!.landingPagesStore!.filter, name: value },
  //     });

  //     this.abortRequest = abort;
  //     await promise;
  //     this.abortRequest = null;
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   this.searchLoading = false;
  // });

  onSearchDebounce = debounce(async (value: string) => {
    this.props.landingPagesStore!.searchLoading = true;
    this.props.landingPagesStore!.onFilterChanged({
      ...this.props!.landingPagesStore!.filter,
      name: value,
    });
  });

  render() {
    const { landingPagesStore } = this.props;
    return (
      <LandingPagesStyled>
        <Header
          title={"Landing Pages"}
          rightMenu={
            <div className="flex flexVerCenter">
              <Link to="/landing-pages/create">
                <Button className="br br-green green b-gray radius marginVer10 lineHeight0">
                  + Generate New Page
                </Button>
              </Link>
              {/* <FilterPage
                store={landingPagesStore}
                filters={["category", "territory", "distributor", "status"]}
              /> */}
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    landingPagesStore?.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          loading={this.props?.landingPagesStore?.fetchPageLoading}
          dataSource={this.props?.landingPagesStore?.currentPage.map(
            (row: any) => {
              row.key = row.id;
              return row;
            }
          )}
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.landingPagesStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.landingPagesStore!.changeUsersStatus(action, rowsIds);
          }}
          disableMultiSelectAction={true}
          handleExportData={() => {
            let rows = this.props!.landingPagesStore!.selectedRows.map(
              (row: any) => ({
                Title: row.page_name,
                Category: row.Category.name || "-",
                "Sub Category": row.subCategory.name || "-",
                Product: row.product.name || "-",
                // Distributor: row.product.name || "-",
                "Distributor Territory": row.distributer_territories || "-",
                "Redirect / Scratch":
                  row.is_redirect === 1 ? "Redirect" : "Scratch",
              })
            );
            this.props!.landingPagesStore!.downloadCsv(rows, "landing_pages");
          }}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.landingPagesStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.landingPagesStore!.selectedRows = selectedRows;
          }}
          total={this.props?.landingPagesStore?.total || 0}
          pageSize={this.props?.landingPagesStore?.pageSize || 0}
          currentPageNumber={
            this.props?.landingPagesStore?.currentPageNumber || 0
          }
          onPageSettingsChange={this.onPageSettingsChange}
        />
        <ConfirmPopup
          visible={this.props?.landingPagesStore?.visible}
          handledeleteItem={() => this.props?.landingPagesStore?.deleteItem()}
          handleCancelDeleteItem={
            this.props?.landingPagesStore?.cancelDeleteItem
          }
        />
      </LandingPagesStyled>
    );
  }
}

export default withRouter(LandingPages);
