import BaseService from "../base.service";

class QrCodesService extends BaseService {
  constructor(baseUrl: string = "") {
    super(baseUrl);
  }

  createGroup = (path: string, group: any) => {
    return this.post(path, group);
  };
}

export default QrCodesService;
