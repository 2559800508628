import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Redirect,
  Link,
} from "react-router-dom";
import { Typography, Radio, Button, message, Form } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { observable, action, computed, runInAction } from "mobx";
import { observer, inject } from "mobx-react";
import DistributorsStore from "../../../../stores/distributors/distributor.store";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import DistributorDetails from "./components/DistributorDetails/DistributorDetails";
import Distributor from "../../../../stores/distributors/distributor.model";
import { CreateDistributorStyled } from "./styled";
import { FormInstance } from "antd/lib/form";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
interface Props extends RouteComponentProps<any> {
  distributorsStore?: DistributorsStore;
}

@inject("distributorsStore")
@observer
class CreateDistributor extends React.PureComponent<Props, any> {
  @observable data: any = {};
  @observable method: "Manually" | "Upload from CSV/Excel file" = "Manually";
  @observable isUpdate: boolean = false;
  @observable distributor: Distributor = this.props.distributorsStore!.create();
  @observable uploaded: boolean = false;
  @observable fetchDataLoading: boolean = false;
  @observable loading: boolean = false;

  formRef = React.createRef<FormInstance>();
  methodOptions = ["Manually", "Upload from CSV/Excel file"];

  constructor(props: Props) {
    super(props);

    this.isUpdate =
      typeof props.match.params.id !== "undefined" &&
      props.match.params.id !== "create";
  }

  @action setMethod = (event: RadioChangeEvent) => {
    this.method = event.target.value;
  };

  @computed get displayUploadComponent(): boolean {
    return this.method === "Upload from CSV/Excel file";
  }

  // @action create = async () => {
  //   const { promise } = this.distributor.save();
  //   const response = await promise;

  //   if (response.status === 200) {
  //     message.info("Success to create new distributor");
  //     this.props.history.push("/distributors/");
  //     // this.distributor = this.props.distributorsStore!.create();
  //   } else {
  //     message.error("Something went wrong when create new distributor.");
  //   }
  // };

  // @action update = async () => {
  //   try {
  //     await this.distributor.save().promise;
  //     message.info("Success to update distributor");
  //     this.props.history.push("/distributors/");
  //   } catch (error) {
  //     message.error("Something went wrong when update distributor.");
  //     console.error(error);
  //   }
  // };

  @action save = async () => {
    // this.loading = true;
    const { promise } = this.distributor.save();
    const response = await promise;
    if (response.status === 200) {
      // this.category = this.props.categoriesStore.create({
      //   child_number: 3,
      // });

      if (!this.isUpdate) {
        this.props.distributorsStore!.allItems.push(this.distributor);
      } else {
        this.props.distributorsStore!.onUpdateCategory(this.distributor.toJson);
      }
      this.props.history.push("/distributors/");

      message.success({
        content: `Successfully to ${
          this.isUpdate ? "update" : "create"
        } distributor.`,
        // duration: 1000000,
        className: "wiser-message distributor-message",
        icon: <CheckOutlined />,
      });
    } else {
      message.error({
        content: "Something went wrong when create new product.",
        // duration: 1000000,
        className: "wiser-message distributor-message",
        icon: <CloseOutlined />,
      });
    }
    // this.loading = false;
  };

  componentDidMount = () => {
    if (this.isUpdate) {
      this.fetchDistributor(Number(this.props.match.params.id));
    }
  };

  @action fetchDistributor = async (id: number) => {
    try {
      this.fetchDataLoading = true;
      const response = await this.props.distributorsStore!.fetchOne(id).promise;
      const { status } = response;

      if (status === 200) {
        const data = await response.json();
        runInAction(() => this.distributor._updateData(data));
      }
    } catch (error) {}

    this.fetchDataLoading = false;
  };
  render() {
    return (
      <CreateDistributorStyled>
        <Form ref={this.formRef} onFinish={this.save}>
          <Title level={2}>
            {this.isUpdate ? "Update" : "Add New"} Distributor
          </Title>
          {/* {!this.isUpdate && (
          <Title level={3} className="white">
            Method
          </Title>
        )} */}

          <div>
            {/* {!this.isUpdate && (
            <div className="marginHor20">
              <Radio.Group
                options={this.methodOptions}
                onChange={this.setMethod}
                value={this.method}
              />
            </div>
          )} */}

            <div className="inputs-wrapper paddingHor20">
              {this.displayUploadComponent ? (
                this.uploaded ? (
                  <Redirect to={"/distributors"} />
                ) : (
                  <UploadFile
                    title={"Upload CSV/Excel file"}
                    accept={
                      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    }
                    multiple={false}
                    action={`${process.env.REACT_APP_API_URL}/distributors/import`}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        this.uploaded = true;
                        message.success({
                          content: "Successfully to import distributors",
                          // duration: 1000000,
                          className: "wiser-message distributor-message",
                          icon: <CheckOutlined />,
                        });
                      }
                    }}
                  />
                )
              ) : (
                <DistributorDetails
                  distributor={this.distributor}
                  isUpdate={this.isUpdate}
                  formRef={this.formRef}
                />
              )}
            </div>
            <div className={this.displayUploadComponent ? "marginHor20" : ""}>
              <Link to="/distributors">
                <Button className="br  gray radius marginVer10 lineHeight0">
                  <StopOutlined /> Cancel
                </Button>
              </Link>
              <Button
                disabled={this.loading}
                htmlType="submit"
                type="primary"
                className="br-yellow yellow b-gray"
                // onClick={this.isUpdate ? this.update : this.create}
              >
                {this.loading ? (
                  <LoadingOutlined />
                ) : !this.isUpdate ? (
                  <PlusOutlined />
                ) : (
                  <SaveOutlined />
                )}
                Save
              </Button>
            </div>
          </div>
        </Form>
      </CreateDistributorStyled>
    );
  }
}
export default withRouter(CreateDistributor);
