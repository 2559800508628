import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ScanChartsStyled } from "./styled";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DashboardStore from "../../../../stores/dashboard/dashboard.store";
import { observer, inject } from "mobx-react";
interface Props extends RouteComponentProps<any> {
  dashboardStore?: DashboardStore;
}

@inject("dashboardStore")
@observer
class ScanCharts extends React.PureComponent<Props, any> {
  componentDidMount = async () => {
    try {
      this.props.dashboardStore!.fetchScansStats();
    } catch (error) {}
  };

  CustomTooltip = (a: any) => {
    return (
      <div className="custom-tooltip">
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  };
  render() {
    const { dashboardStore } = this.props;
    return (
      <ScanChartsStyled>
        <div className="b-gray padding20">
          <div className="header flex justify-center flexVerCenter marginBottom20">
            <div className="title flex1 ">
              <div className="f25 white ">Total Scans</div>
              <div className="f16 white ">Monthly totals of QR scans</div>
            </div>
          </div>
          <ResponsiveContainer width="100%" aspect={4.5 / 3.0}>
            <BarChart data={dashboardStore?.scansStats.scans}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Count" fill="#d9b3ff" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="b-gray padding20">
          <div className="header flex justify-center flexVerCenter marginBottom20">
            <div className="title flex1 ">
              <div className="f25 white ">Total Leads</div>
              <div className="f16 white ">
                Monthly review of leads generated
              </div>
            </div>
          </div>
          <ResponsiveContainer width="100%" aspect={4.5 / 3.0}>
            <BarChart data={dashboardStore?.leadsStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Count" fill="#de63bd" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </ScanChartsStyled>
    );
  }
}
export default withRouter(ScanCharts);
