import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { ILead } from "./lead.interface";
import { observable, computed } from "mobx";

class Lead extends BaseModel implements ILead {
  @observable id = null;
  @observable key: string = "asd";
  @observable first_name: string = "";
  @observable last_name: string = "";
  @observable address: string = "";
  @observable email: string = "";
  @observable phone: string = "";
  @observable mobile_phone: string = "";
  @observable purchase_location: string = "";
  @observable purchase_price: string = "";
  @observable purchase_hear_about: string = "";
  @observable which_site: string = "";
  @observable seller_name: string = "";
  @observable when_did_purchase: string = "";
  @observable store_name: string = "";
  @observable geo: string = "";
  @observable createdAt: string = "";
  @observable scanValid: number = 1;
  @observable group_id: string = "";
  @observable qr_code_id: number = 1;
  @observable landing_page_id: number = 1;
  @observable landing_page: any = {};
  @observable qr: any = {};
  @observable product: any = {};
  @observable category: any = {};
  @observable subCategory: any = {};
  @observable category_id: number | null = null;
  @observable distributer_id: number | null = null;
  @observable territory: string | null = null;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "lead";
  }
  responseModelsKey(): string {
    return "leads";
  }

  get url(): string {
    return "/leads";
  }

  @computed
  get toJson(): { [key: string]: any } {
    return this._toJson({
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name,
      address: this.address,
      email: this.email,
      phone: this.phone,
      mobile_phone: this.mobile_phone,
      purchase_location: this.purchase_location,
      purchase_price: this.purchase_price,
      purchase_hear_about: this.purchase_hear_about,
      which_site: this.which_site,
      seller_name: this.seller_name,
      when_did_purchase: this.when_did_purchase,
      store_name: this.store_name,
      geo: this.geo,
      scanValid: this.scanValid,
      qr_code_id: this.qr_code_id,
      group_id: this.group_id,
      landing_page_id: this.landing_page_id,
      category_id: this.category_id,
      distributer_id: this.distributer_id,
      territory: this.territory,
    });
  }
}

export default Lead;
