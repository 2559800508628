import styled from "styled-components";

const ProductsStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);

  .product {
    .ant-table-row-indent.indent-level-1,
    .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
      display: none;
    }
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #e8b20f !important;
  }

  .products {
    button {
      svg {
        fill: #e8b20f;
      }
    }
  }
`;

export { ProductsStyled };
