import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { GenerateQrDetailsStyled } from "./styled";
import { Select } from "antd";
import { computed } from "mobx";
import { observer, inject } from "mobx-react";
import QrCodesStore from "../../../../../../stores/qr-codes/qr-codes.store";
import CategoriesStore from "../../../../../../stores/categories/category.store";
import DistributorsStore from "../../../../../../stores/distributors/distributor.store";
import QrDetailItem from "./QrDetailItem/QrDetailItem";
const { Option, OptGroup } = Select;

const children: any = [];
for (let i = 10; i < 36; i++) {
  const text: string = i.toString(36) + i;
  const key: string = i.toString(36) + i;
  children.push(
    <Option key={key} value={key}>
      {text}
    </Option>
  );
}
interface Props extends RouteComponentProps<any> {
  onChangeCategory: (data: any) => any;
  qrCodeStore?: QrCodesStore;
  categoriesStore?: CategoriesStore;
  distributorsStore?: DistributorsStore;
  group: any;
  loading: boolean;
  onRemoveDistributer: (index: number) => void;
  onAddDistributer: (distributerDetails: any) => void;
}

@inject("qrCodeStore", "categoriesStore", "distributorsStore")
@observer
class GenerateQrDetails extends React.PureComponent<Props, any> {
  componentDidMount = async () => {
    try {
      await this.props!.categoriesStore!.fetchForQrCategories();
      await this.props!.distributorsStore!.fetch();
    } catch (error) {
      console.error(error);
    }
  };

  // @action onSelectCategory = (value: string) => {
  //   const selected = this.props.categoriesStore?.models.find(
  //     (category: any) => category.id === Number(value)
  //   );
  //   if (selected) {
  //     this.props.onChangeData({ category_id: selected.id });
  //   }
  // };

  @computed get distributors(): any[] {
    return (
      this.props.distributorsStore?.models.reduce(
        (distributors: any[], distributer: any) => {
          if (
            !this.props.group.items
              .map((item: any) => item.distributer_id)
              .includes(distributer.id)
          ) {
            distributors.push(distributer);
          }
          return distributors;
        },
        []
      ) || []
    );
  }
  render() {
    const {
      onChangeCategory,
      onRemoveDistributer,
      onAddDistributer,
      group: { categories, items },
    } = this.props;

    const _categories: any = this.props.categoriesStore?.itemsForCreateQR.filter(
      (c: any) => c.parent_id === null
    );
    const sub_categories: any = this.props.categoriesStore?.itemsForCreateQR.filter(
      (c: any) => c.parent_id !== null && c.child_number !== 3
    );
    const products: any = this.props.categoriesStore?.itemsForCreateQR.filter(
      (c: any) => c.child_number === 3
    );
    return (
      <GenerateQrDetailsStyled className="details ">
        <div className="marginHor20">
          <div>
            <span className="red"> *</span> Select Product or Category
            <div>
              <Select
                disabled={this.props.loading}
                className="max200"
                placeholder=""
                optionFilterProp="children"
                onChange={onChangeCategory}
                value={categories}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <>
                  {_categories.length && (
                    <OptGroup label="Category">
                      {_categories.map((row: any) => (
                        <Option value={row.id} key={row.id}>
                          {row.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                  {sub_categories.length && (
                    <OptGroup label="Sub Category">
                      {sub_categories.map((row: any) => (
                        <Option value={row.id} key={row.id}>
                          {row.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                  {products.length && (
                    <OptGroup label="Product">
                      {products.map((row: any) => (
                        <Option value={row.id} key={row.id}>
                          {row.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </>
              </Select>
            </div>
          </div>
        </div>

        <QrDetailItem
          disablePlus={this.distributors.length === 0}
          displayMinus={false}
          displayPlus={true}
          item={{}}
          onRemoveDistributer={onRemoveDistributer}
          onAddDistributer={onAddDistributer}
          distributors={this.distributors}
          loading={this.props.loading}
        />

        {items.map((item: any, index: number) => (
          <>
            <QrDetailItem
              disabled={true}
              loading={this.props.loading}
              index={index}
              displayMinus={true}
              displayPlus={false}
              key={index}
              item={item}
              onRemoveDistributer={onRemoveDistributer}
              onAddDistributer={onAddDistributer}
              distributors={this.props.distributorsStore?.models || []}
            />
          </>
        ))}

        {/* <div className="flex">
          <div className="quantity">
            Quantity*
            <Input
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.props.onChangeData({ quantity: event.target.value });
              }}
            />
          </div>
          <div className="flex1">
            Distributer*
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder=""
              optionFilterProp="children"
              onChange={(value: string) =>
                this.props.onChangeData({ distributors: value })
              }
              onFocus={() => {}}
              onBlur={() => {}}
              onSearch={() => {}}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.props.distributorsStore?.models.map((distributor: any) => (
                <Option value={distributor.id} key={distributor.id}>
                  {distributor.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flexVerCenter flex plus-minus-wrapper">
            <PlusSquareOutlined
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
            <MinusSquareOutlined
              style={{ fontSize: "20px", marginLeft: 20, cursor: "pointer" }}
            />
          </div>
        </div> */}
      </GenerateQrDetailsStyled>
    );
  }
}
export default withRouter(GenerateQrDetails);
