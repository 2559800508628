import styled from "styled-components";

const CreateCategoryStyled = styled.div`
  max-width: 847px;
  background: #3b4149;
  padding: 20px;

  .inputs-wrapper {
    border-top: 1px solid #818286;
    max-width: 520px;
    .ant-select,
    input {
      width: 100% !important;
    }
    .quantity {
      max-width: 154px;
      margin-right: 30px;
    }
  }

  .ant-radio-inner::after {
    background: #ffc107 !important;
  }

  .ant-table-cell {
    height: 57px !important;
  }
  form .ant-row.ant-form-item {
    display: flex !important;
  }

  .ant-row.ant-form-item {
    flex-direction: column;
    .ant-col.ant-form-item-label {
      text-align: left;
    }
  }
`;

export { CreateCategoryStyled };
