import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import "./Breadcrumbs.scss";
import { Breadcrumb } from "antd";
import { observer } from "mobx-react";

type route = {
  path: string;
  text: string;
};

interface Props extends RouteComponentProps<any> {
  // routes: route[];
}

class Breadcrumbs extends React.PureComponent<Props, any> {
  render() {
    // const routes: any = this.props.match.url
    const routes: any = this.props.location.pathname
      .split("/")
      .filter((route: string) => route.length)
      .reduce(
        (_routes: any[], _path: string) => {
          const basePath = _routes[_routes.length - 1].path;
          const text = _path.split("-").join(" ");
          const path = `${basePath.length > 1 ? basePath : ""}/${_path}`;
          return _routes.concat({ path, text });
        },
        [{ path: "/", text: "Home" }]
      );

    return (
      <div className="Breadcrumbs flex flex1">
        <Breadcrumb>
          {routes.map((item: any, i: any) => (
            <Breadcrumb.Item key={i}>
              <Link to={item.path}>{item.text}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    );
  }
}

export default withRouter(observer(Breadcrumbs));
