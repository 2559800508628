import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Divider, Input, Tooltip } from "antd";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { ReactComponent as DeleteIcon } from "../../assets/action/cil-trash.svg";
import DeleteEntityButton from "../../components/DeleteEntityButton/DeleteEntityButton";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import FilterPage from "../../components/FilterPage/FilterPage";
import Header from "../../components/Header/Header";
import CategoriesStore from "../../stores/categories/category.store";
import { TableActionsCellStyled } from "../../styled";
import debounce from "../../utils/debounce";
import { CategoriesStyled } from "./styled";
interface Props extends RouteComponentProps<any> {
  categoriesStore: CategoriesStore;
}
@inject("categoriesStore")
@observer
class Categories extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable searchLoading: boolean = false;
  @observable productsConnected: number = 0; // for delete popup
  @observable subCategoriesConnected: number = 0; // for delete popup
  abortRequest: Function | undefined | null;
  customPath: string = "/categories/group";

  @observable modalMode: "CAUTION" | "DELETE" = "CAUTION";
  @observable rowCanDeleted: boolean = false;

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (text: string, record: any) => {
        const isSubCategory = record.parent_id !== null;
        return isSubCategory ? "" : record.name;
      },
    },

    {
      className: "textLeft",
      title: "Sub category",
      key: "Subcategory",
      width: "15%",
      dataIndex: "Subcategory",
      render: (text: string, record: any) => {
        const isSubCategory = record.parent_id !== null;
        return isSubCategory ? record.name : record.children?.length || 0;
      },
    },
    {
      title: "Product Quantity",
      key: "product_count",
      dataIndex: "product_count",
    },
    {
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      minWidth: 120,
      render: (text: string, record: any) => (
        <>
          <TableActionsCellStyled>
            <Tooltip placement="bottom" title={"Edit"}>
              <Link to={`/categories/${record.id}/edit/`}>
                <img
                  src="/images/action/cil-pencil_yellow.svg"
                  alt="cil-pencil_yellow.svg"
                />
              </Link>
            </Tooltip>
            <Divider type="vertical" className={"b-grayM2"} />

            <DeleteEntityButton
              className="categories"
              isDeleteAbleFn={() => this.isDeleteAble("category", record.id)}
              onDelete={() => this.onDelete(record)}
              itemName={"Category"}
              connectedItemsNames={["Products, Landing pages"]}
              icon={<DeleteIcon />}
            />
          </TableActionsCellStyled>
        </>
      ),
      key: "action",
    },
  ];

  isDeleteAble = (entityType: string, recordId: number) => {
    return this.props?.categoriesStore?.isDeleteAble(entityType, recordId);
  };

  onDelete = (record: any) => record.destroy();

  // @action handleDeleteRow = async (record: any) => {
  //   try {
  //     const { canDeleted } = await this.props?.categoriesStore?.isDeleteAble(
  //       "category",
  //       record.id
  //     );

  //     this.rowCanDeleted = canDeleted;
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //   }
  // };

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  componentDidMount = async () => {
    try {
      await this.props.categoriesStore.fetchPage({
        page: 1,
        params: { child_number: [1, 2] },
        customPath: this.customPath,
      });
    } catch (error) {
      console.error(error);
    }

    this.props!.categoriesStore!.filter.path = this.customPath;
    this.props!.categoriesStore!.filter.child_number = [1, 2];
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { categoriesStore } = this.props;
    await categoriesStore.fetchPage({
      filter: categoriesStore!.filter,
      page,
      pageSize,
      params: { child_number: [1, 2] },
      customPath: this.customPath,
    });
  };

  onSearchDebounce = debounce(async (value: string) => {
    this.props.categoriesStore!.searchLoading = true;
    this.props!.categoriesStore!.filter.path = this.customPath;
    this.props.categoriesStore!.onFilterChanged({
      ...this.props!.categoriesStore!.filter,
      name: value,
      child_number: [1, 2],
    });
  });

  @action deleteItem = async () => {};

  renderExpandIcon = ({ expanded, onExpand, record }: any) => {
    if (record.parent_id) return;

    return expanded ? (
      <TableActionsCellStyled>
        <Tooltip placement="bottom" title={"Close"}>
          <ReactSVG
            className="plus_icon"
            onClick={(e) => onExpand(record, e)}
            src="/images/action/close/Yellow-Minus.svg"
          />
        </Tooltip>
        <Divider type="vertical" className={"b-grayM2"} />
      </TableActionsCellStyled>
    ) : (
      <TableActionsCellStyled>
        <Tooltip
          placement="bottom"
          title={record.children ? "Details" : "No sub category"}
        >
          <ReactSVG
            className="plus_icon"
            onClick={(e) => onExpand(record, e)}
            src="/images/action/PlusIcon_yellow.svg"
          />
        </Tooltip>
        <Divider type="vertical" className={"b-grayM2"} />
      </TableActionsCellStyled>
    );
  };

  render() {
    const { categoriesStore } = this.props;
    return (
      <CategoriesStyled>
        <Header
          title={"Categories"}
          rightMenu={
            <div className="flex flexVerCenter">
              <Link to="/categories/create">
                <Button className="br br-yellow yellow b-gray radius marginVer10 lineHeight0">
                  + Add New Categories
                </Button>
              </Link>
              <FilterPage
                color="#ffd147"
                store={categoriesStore}
                filters={["only_category"]}
                date={false}
              />
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    categoriesStore!.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          expandIconColumnIndex={5}
          expandIcon={this.renderExpandIcon}
          loading={this.props.categoriesStore?.fetchPageLoading}
          dataSource={this.props?.categoriesStore?.currentPage.map(
            (row: any) => {
              row.key = row.id;
              return row;
            }
          )}
          disableMultiSelectAction={true}
          rowClassName={(record: any, index: number) =>
            record.parent_id === null ? "category" : "sub-category"
          }
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.categoriesStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.categoriesStore!.changeUsersStatus(action, rowsIds, {
              child_number: [1, 2],
              customPath: this.customPath,
            });
          }}
          handleExportData={() => {
            let rows = this.props!.categoriesStore!.selectedRows.map(
              (row: any) => ({
                Id: row.id,
                Name: row.name,
                "Sub Category": row.children.length,
                "Product Quantity": row.product_count,
              })
            );
            this.props!.categoriesStore!.downloadCsv(rows, "categories");
          }}
          // {
          //   title: "Name",
          //   dataIndex: "name",
          //   key: "name",
          //   width: "10%",
          //   render: (text: string, record: any) => {
          //     const isSubCategory = record.parent_id !== null;
          //     return isSubCategory ? "" : record.name;
          //   },
          // },

          // {
          //   className: "textLeft",
          //   title: "Subcategory",
          //   key: "Subcategory",
          //   width: "10%",
          //   dataIndex: "Subcategory",
          //   render: (text: string, record: any) => {
          //     const isSubCategory = record.parent_id !== null;
          //     return isSubCategory ? record.name : record.children?.length || 0;
          //   },
          // },
          // {
          //   title: "Product qty",
          //   key: "product_count",
          //   dataIndex: "product_count",
          // },

          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.categoriesStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.categoriesStore!.selectedRows = selectedRows;
          }}
          total={this.props?.categoriesStore?.total || 0}
          pageSize={this.props?.categoriesStore?.pageSize || 0}
          currentPageNumber={
            this.props?.categoriesStore?.currentPageNumber || 0
          }
          NotAllActionOption={true}
          onPageSettingsChange={this.onPageSettingsChange}
        />

        {/* <CantDeleteEntityMessage
          store={categoriesStore}
          entityName="Categories"
        /> */}
        {/* <ConfirmPopup
          mode={""}
          // productsConnected={this.productsConnected}
          // subCategoriesConnected={this.subCategoriesConnected}
          visible={this.props?.categoriesStore?.visible}
          handledeleteItem={async () => {
            await this.props?.categoriesStore?.deleteItem();
            this.props?.categoriesStore?.fetchPage({
              customPath: this.customPath,
            });
          }}
          handleCancelDeleteItem={this.props?.categoriesStore?.cancelDeleteItem}
        /> */}
      </CategoriesStyled>
    );
  }
}
export default withRouter(Categories);
