import styled from "styled-components";

const CreateLandingPagesStyled = styled.div`
  max-width: 847px;
  background: #3b4149;
  padding: 20px;

  .inputs-wrapper {
    border-top: 1px solid #818286;
    .ant-select,
    .quantity {
      margin-right: 30px;
    }
  }

  .ant-divider {
    background-color: red !important;
  }

  .ant-radio-inner::after {
    background: #4bad6f !important;
  }

  .label-comment {
    position: absolute;
    top: 9px;
    left: 207px;
    width: 230px;
    z-index: 22;
    font-size: 11px;
    color: #8e9195;
  }

  input,
  .ant-select {
    width: 400px !important;
  }
`;

export { CreateLandingPagesStyled };
