import styled from "styled-components";

const InfoBoxStyled = styled.div`
  .info-box {
    flex-direction: column;
    > div:first-child {
      justify-content: flex-start;
    }
    > div:last-child {
      justify-content: flex-end;
      line-height: initial;
    }

    .info-box-count {
      span {
        flex: 1;
        &:first-child {
          display: flex;
          justify-content: flex-start;
          align-content: center;
        }
        &:last-child {
          justify-content: flex-end;
          line-height: 35px;
        }
      }
    }
  }
  input[type="range"] {
    margin: auto;
    outline: none;
    padding: 0;
    width: 100%;
    height: 6px;
    background-color: #2f353a;
    background-image: -o-linear-gradient(#009688, #009688);
    background-image: linear-gradient(#ffffff, #ffffff);
    background-size: 50% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    height: 3px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    box-shadow: none;
    border: none;
    background: transparent;
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    display: none;
  }
  input[type="range"]::-webkit-slider-thumb:active {
    height: 18px;
    width: 18px;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  }

  input[type="range"]::slider-runnable-track {
    box-shadow: none;
    border: none;
    background: transparent;
    -webkit-appearance: none;
  }

  input[type="range"]::slider-thumb {
    width: 15px;
    height: 15px;
    border: 0;
    background: #009688;
    border-radius: 100%;
    box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
  }
  .zero-input[type="range"]::-webkit-slider-thumb {
    background: #fff;
    border: 2px solid #bbb;
  }
`;

export { InfoBoxStyled };
