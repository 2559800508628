import styled from "styled-components";

const DownloadModalStyled = styled.div`
  .type {
    padding: 8px;
    border: 1px solid transparent;
    &.selected {
      border-color: white;
    }
  }
`;

export { DownloadModalStyled };
