import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IQrCode } from "./qr-codes.interface";
import { observable, computed } from "mobx";

class QrCode extends BaseModel implements IQrCode {
  @observable id = 0;
  @observable series: number = 0;
  @observable sku: number = 0;
  @observable quantity: number = 0;
  @observable category_id: number = 0;
  @observable group_id: string = "";
  @observable distributer_id: number = 0;
  @observable distributor: any | undefined = 1;
  @observable createdAt: any | undefined = 1;
  @observable groupCount: number = 0;
  @observable product: any = {};
  @observable category: any = {};
  @observable subCategory: any = {};
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "qrCode";
  }

  responseModelsKey(): string {
    return `qrCodes`;
  }

  get url(): string {
    return this.store.url();
  }

  @computed get toJson(): { [key: string]: any } {
    return this._toJson({
      id: this.id,
      sku: this.sku,
      group_id: this.group_id,
      distributer_id: this.distributer_id,
      categories: this.category_id,
      quantity: this.quantity,
    });
  }
}

export default QrCode;
