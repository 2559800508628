import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { RedirectUrlStyled } from "./styled";
import { Input } from "antd";
import LandingPage from "../../../../../../stores/landing-pages/landing-page.model";
import { observer } from "mobx-react";
import Form from "antd/lib/form";

interface Props extends RouteComponentProps<any> {
  onChangeData: (data: any) => any;
  landingPage?: LandingPage;
}

@observer
class RedirectUrl extends React.PureComponent<Props, any> {
  render() {
    return (
      <RedirectUrlStyled>
        <div className="full">
          <div className="f21 white">
            Redirect URL<span className="red"> *</span>
          </div>
          <Form.Item
            name="redirect_url"
            rules={[{ required: true, message: "Redirect required" }]}
          >
            <Input
              className="show"
              value={this.props.landingPage!.redirect_url}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.props.onChangeData({ redirect_url: event.target.value });
              }}
            />
          </Form.Item>
        </div>
      </RedirectUrlStyled>
    );
  }
}
export default withRouter(RedirectUrl);
