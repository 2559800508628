import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { ICategory } from "./category.interface";
import { observable, computed } from "mobx";

class Category extends BaseModel implements ICategory {
  @observable id: number | null = null;
  @observable name: string = "";
  @observable parent: Category | null = null;
  @observable parent_id: number | undefined | null = null;
  @observable is_product: number = 0; // 0 | 1
  @observable product_count: number = 0; // 0 | 1
  @observable sub_categories: number = 0; // 0 | 1
  @observable child_number: number = 1; // 1-category | 2-category | 3-product
  @observable category_name: string = "";
  @observable sub_category_name: string = "";
  @observable product_name: string = "";
  @observable createdAt: string = "";
  @observable children: any[] = [];

  model: any;

  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "category";
  }

  responseModelsKey(): string {
    return "categories";
  }

  get url(): string {
    return "/categories";
  }

  @computed
  get toJson(): { [key: string]: any } {
    return this._toJson({
      id: this.id,
      name: this.name,
      parent_id: this.parent_id,
      is_product: this.is_product,
      child_number: this.child_number,
    });
  }
  get dataToExport() {
    const json = this.toJson;
    json.category_name = this.name;
    json.product_quantity = this.product_count;
    json.sub_category = this.parent_id !== null ? "Yes" : "No";
    delete json.parent_id;
    delete json.is_product;
    delete json.child_number;
    delete json.name;
    delete json.product_count;
    return json;
  }
}

export default Category;
