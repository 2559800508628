import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ProductsStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import { Button, AutoComplete, Input, Divider, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import CategoriesStore from "../../stores/categories/category.store";
import { observable, computed, action } from "mobx";
import debounce from "../../utils/debounce";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import { TableActionsCellStyled } from "../../styled";
import { ReactComponent as DeleteIcon } from "../../assets/action/cil-trash.svg";
import DeleteEntityButton from "../../components/DeleteEntityButton/DeleteEntityButton";
interface Props extends RouteComponentProps<any> {
  categoriesStore: CategoriesStore;
}

@inject("categoriesStore")
@observer
class Products extends React.PureComponent<Props, any> {
  @observable _width: number = 200;

  columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Category",
      dataIndex: "category_name",
      render: (category: any, { category_name }: any = {}) =>
        category_name || " - ",
      key: "category_name",
    },

    {
      title: "Sub Category",
      dataIndex: "sub_category_name",
      render: (category: any, { sub_category_name }: any = {}) =>
        sub_category_name || " - ",
      key: "sub_category_name",
    },

    {
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      render: (text: string, record: any) => (
        <TableActionsCellStyled>
          <Tooltip placement="bottom" title={"Edit"}>
            <Link to={`/products/${record.id}/edit/`}>
              <img
                src="/images/action/cil-pencil_yellow.svg"
                alt="cil-pencil_yellow.svg"
              />
            </Link>
          </Tooltip>

          <Divider type="vertical" className={"b-grayM2"} />

          <DeleteEntityButton
            className="products"
            isDeleteAbleFn={() => this.isDeleteAble("product", record.id)}
            onDelete={() => this.onDelete(record)}
            itemName={"Product"}
            connectedItemsNames={["Landing pages"]}
            icon={<DeleteIcon />}
          />
        </TableActionsCellStyled>
      ),
      key: "action",
    },
  ];

  isDeleteAble = (entityType: string, recordId: number): Promise<boolean> => {
    return this.props!.categoriesStore!.isDeleteAble(entityType, recordId);
  };

  onDelete = (record: any) => record.destroy();

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  componentDidMount = async () => {
    try {
      await this.props.categoriesStore.fetchPage({
        page: 1,
        params: { child_number: [3] },
      });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { categoriesStore } = this.props;
    await categoriesStore.fetchPage({
      page,
      pageSize,
      filter: categoriesStore!.filter,
      params: { child_number: [3] },
    });
    // router.navigate("templates", { page, pageSize });
  };

  onSearchDebounce = debounce(async (value: string) => {
    if (this.props!.categoriesStore!.filter.path) {
      delete this.props!.categoriesStore!.filter.path;
    }
    this.props.categoriesStore!.searchLoading = true;
    this.props.categoriesStore!.onFilterChanged({
      ...this.props!.categoriesStore!.filter,
      name: value,
      child_number: [3],
    });
  });

  render() {
    const { categoriesStore } = this.props;
    return (
      <ProductsStyled>
        <Header
          title={"Products"}
          rightMenu={
            <div className="flex flexVerCenter">
              <Link to="/products/create">
                <Button className="br br-yellow yellow b-gray radius marginVer10 lineHeight0">
                  + Add New Product
                </Button>
              </Link>
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    categoriesStore.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          rowClassName={(record: any, index: number) => "product"}
          loading={this.props?.categoriesStore?.fetchPageLoading}
          dataSource={this.props?.categoriesStore?.currentPage.map(
            (row: any) => {
              row.key = row.id;
              return row;
            }
          )}
          columns={this.columns}
          disableMultiSelectAction={true}
          handleTableAction={(action: any) => {
            let rows = this.props!.categoriesStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.categoriesStore!.changeUsersStatus(action, rowsIds);
          }}
          handleExportData={() => {
            let rows = this.props!.categoriesStore!.selectedRows.map(
              (row: any) => ({
                Product: row.name,
                Category: row.category_name,
                "Sub Category": row.sub_category_name,
              })
            );
            this.props!.categoriesStore!.downloadCsv(rows, "products");
          }}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.categoriesStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.categoriesStore!.selectedRows = selectedRows;
          }}
          total={this.props?.categoriesStore?.total || 1}
          pageSize={this.props?.categoriesStore?.pageSize || 10}
          currentPageNumber={
            this.props?.categoriesStore?.currentPageNumber || 1
          }
          onPageSettingsChange={this.onPageSettingsChange}
        />

        <ConfirmPopup
          visible={this.props?.categoriesStore?.visible}
          handledeleteItem={() =>
            this.props?.categoriesStore?.deleteItem({
              child_number: [3],
            })
          }
          handleCancelDeleteItem={this.props?.categoriesStore?.cancelDeleteItem}
        />
      </ProductsStyled>
    );
  }
}
export default withRouter(Products);
