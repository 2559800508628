import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Menu, Layout, Dropdown, Button, Avatar } from "antd";
import { Link } from "react-router-dom";
import { SidebarStyled } from "./styled";
import { inject, observer } from "mobx-react";
import AuthStore from "../../stores/auth/auth.store";
import { observable } from "mobx";
import UiStore from "../../stores/ui/ui.store";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
  uiStore?: UiStore;
}

const { Sider } = Layout;

const Menuu: any = inject("authStore")(
  observer(({ authStore }: any) => {
    return (
      <Menu>
        {/* <Menu.Item className="b-white">
          <Link className="b-white" to={`/users/${authStore.user?.id}/edit`}>
            Profile
          </Link>
        </Menu.Item> */}
        <Menu.Item className="b-white logout">
          <div className="b-white" onClick={authStore.logout}>
            Logout
          </div>
        </Menu.Item>
      </Menu>
    );
  })
);

@inject("authStore", "uiStore")
@observer
class Sidebar extends React.PureComponent<Props, any> {
  @observable menu_titels: any = [];
  componentDidMount = async () => {
    try {
      let user = await this.props.authStore?.user;

      this.props.uiStore?.menuPages.map((page: any) => {
        page.active = user.permission.includes(page.link);
      });
    } catch (error) {
      console.error(error);
    }
  };

  currentGroupTitle: string | undefined;

  getTitleGroup = (groupTitle: string) => {
    if (groupTitle === "top") return null;
    if (this.currentGroupTitle !== groupTitle) {
      this.currentGroupTitle = groupTitle;
      return (
        <li className="group-title" key={groupTitle}>
          {groupTitle}
        </li>
      );
    }
  };

  render() {
    return (
      <SidebarStyled>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {}}
          onCollapse={(collapsed, type) => {}}
        >
          <div className="logo">
            <img alt="svg" src="/images/wiser-logo.png" />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["4"]}>
            {this.props.uiStore?.menuPages.map((page: any) => {
              if (page.active) {
                return (
                  <>
                    {this.getTitleGroup(page.group)}
                    <li
                      className="ant-menu-item icon-red ant-menu-item-only-child"
                      key={page.link}
                    >
                      <Link to={`/${page.link}`}>
                        <img alt="svg" src={page.icon} />
                        <span className="nav-text">{page.name}</span>
                      </Link>
                    </li>
                  </>
                );
              }
            })}
            {(this.currentGroupTitle = undefined)}

            {/* <Dropdown
              className="profile_menu bottom"
              overlay={<Menuu />}
              placement="topCenter"
            >
              <li>
                {this.props.authStore!.user?.profile_img &&
                  !this.props.authStore!.user?.profile_img?.includes(
                    "object"
                  ) && (
                    <img
                      alt="svg"
                      src={this.props.authStore!.user?.profile_img}
                      className="circle"
                    />
                  )}
                Account Settings
              </li>
            </Dropdown> */}

            <Button
              onClick={this.props.authStore?.logout}
              className="gap10 profile_menu bottom br br-grayM white b-gray radius marginRight10 marginBottom10 lineHeight0"
              size="large"
            >
              {this.props.authStore!.user?.profile_img &&
              !this.props.authStore!.user?.profile_img?.includes("object") ? (
                <img
                  alt="svg"
                  src={this.props.authStore!.user?.profile_img}
                  className="circle"
                />
              ) : (
                <Avatar src={"/images/Avatar.svg"} />
              )}{" "}
              Logout
            </Button>
          </Menu>
        </Sider>
      </SidebarStyled>
    );
  }
}
export default withRouter(Sidebar);
