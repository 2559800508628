import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { CreateSubscriberStyled } from "./styled";
import {
  Avatar,
  Input,
  Select,
  Button,
  message,
  Typography,
  Divider,
  DatePicker,
  Form,
} from "antd";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import { observable, action, runInAction } from "mobx";
import { observer, inject } from "mobx-react";

import SubscriptionsStore from "../../../../stores/subscriptions/subscription.store";
import Subscription from "../../../../stores/subscriptions/subscription.model";
import Validation from "../../../../services/validation";
import { FormInstance } from "antd/lib/form";
import {
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import moment from "moment";
const { Option } = Select;
const { Title } = Typography;
interface Props extends RouteComponentProps<any> {
  subscriptionsStore: SubscriptionsStore;
}
const { RangePicker } = DatePicker;

@inject("subscriptionsStore")
@observer
class CreateSubscriber extends React.PureComponent<Props, any> {
  @observable
  subscription: Subscription = this.props.subscriptionsStore?.create();
  @observable logo_preview: string = "";
  @observable isUpdate: boolean = false;
  @observable fetchDataLoading: boolean = false;
  @observable loading: boolean = false;

  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.isUpdate =
      typeof props.match.params.id !== "undefined" &&
      props.match.params.id !== "create";
  }

  componentDidMount = async () => {
    if (this.isUpdate) {
      this.fetchModel(Number(this.props.match.params.id));
    }
  };

  @action fetchModel = async (id: number) => {
    try {
      this.fetchDataLoading = true;
      const response = await this.props.subscriptionsStore!.fetchOne(id)
        .promise;
      const { status } = response;
      if (status === 200) {
        const data = await response.json();

        runInAction(() => {
          data.subscription_period = data.start_date
            ? [moment(data.start_date), moment(data.end_date)]
            : [];
          this.subscription._updateData(data);

          this.formRef?.current?.setFieldsValue(this.subscription);
        });
      }
    } catch (error) {
      console.error(error);
    }

    this.fetchDataLoading = false;
  };
  @action create = async () => {
    const userData: any = {
      id: this.subscription.id,
      name: this.subscription.name,
      contact_name: this.subscription.contact_name,
      adress: this.subscription.adress,
      start_date: this.subscription.start_date,
      end_date: this.subscription.end_date,
      profession: this.subscription.profession,
      territory: this.subscription.territory,
      phone: this.subscription.phone,
      password: this.subscription.password,
      membership: this.subscription.membership,
      logo: this.subscription.logo,
      subscriber_email_login: this.subscription.subscriber_email_login,
    };

    if (this.subscription.email) {
      userData.email = this.subscription.email;
    }

    this.loading = true;
    const { promise } = this.props.subscriptionsStore.createSubscription(
      userData
    );
    const response = await promise;
    if (response.status === 200) {
      message.success({
        content: "Success to create new subscriber",
        className: "wiser-message landing-page-message",
        icon: <CheckOutlined />,
      });
      this.props.history.push("/subscribers/");
    } else {
      const data = await response.json();
      message.error({
        content:
          data.message || "Something went wrong on creating new subscriber.",
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }
    this.loading = false;
  };

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  onFinish = (values: any) => {
    this.create();
  };

  onFinishFailed = (errorInfo: any) => {};

  render() {
    return (
      <CreateSubscriberStyled>
        <div className="full marginBottom20">
          <div className="f27">
            {this.isUpdate ? "Edit" : "Create new"} Subscriber
          </div>

          <Form
            ref={this.formRef}
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <div className="inputs-wrapper paddingHor20">
              <div>
                <div className="flex" style={{ maxWidth: "980px" }}>
                  <div className="form-row flex1 br-right br-grayM paddingRight30">
                    <div className="marginTop20 flex">
                      <Avatar
                        src={this.logo_preview || this.subscription.logo}
                        size={75}
                        className="margin10 transparent br br-gray2"
                      />
                      <UploadFile
                        title={"Upload Logo (.jpg, .png)"}
                        accept={".jpg, .png"}
                        multiple={false}
                        maxCount={1}
                        onChange={(file) => {
                          let fileObj = file.file;

                          this.getBase64(
                            fileObj.originFileObj,
                            (imageUrl: any) => (this.logo_preview = imageUrl)
                          );

                          this.subscription.logo = fileObj.originFileObj;
                        }}
                      />
                    </div>
                    <Form.Item
                      className="full"
                      label="Company Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please add your name",
                        },
                      ]}
                    >
                      <Input
                        disabled={this.loading}
                        className="show full"
                        value={this.subscription.name}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.subscription.name = event.target.value;
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="full"
                      label="Industrial Field"
                      name="profession"
                      rules={
                        [
                          // {
                          //   required: true,
                          //   message: "Please add your industrial field",
                          // },
                        ]
                      }
                    >
                      <Input
                        disabled={this.loading}
                        className="show full"
                        value={this.subscription.profession}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.subscription.profession = event.target.value;
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="full"
                      label="Address"
                      name="territory"
                      rules={
                        [
                          // {
                          //   required: true,
                          //   message: "Please add your territory",
                          // },
                        ]
                      }
                    >
                      <Input
                        disabled={this.loading}
                        className="show full"
                        value={this.subscription.territory}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.subscription.territory = event.target.value;
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="full"
                      name="subscription_period"
                      label="Subscription Period"
                      rules={[
                        {
                          required: true,
                          message: "Please add your subscription period",
                        },
                      ]}
                    >
                      <RangePicker
                        value={this.subscription.subscription_period}
                        // value={this.subscription.name}
                        onChange={(
                          value: any,
                          dateString: [string, string]
                        ) => {
                          this.subscription.start_date = dateString[0];
                          this.subscription.end_date = dateString[1];
                          // this.subscription.name = event.target.value;
                        }}
                      />
                    </Form.Item>
                    {/* <div className="marginTop20">
                      <UploadFile
                        title={"Upload Contract file (.pdf)"}
                        accept={"application/pdf"}
                        multiple={false}
                        action={`${process.env.REACT_APP_API_URL}/users/import`}
                        onChange={(file) => {
                          this.subscription.file = file.file.originFileObj;
                        }}
                      />
                    </div> */}
                  </div>

                  <div className="form-row flex1 paddingVer30">
                    <Form.Item
                      className="full contact_name"
                      label="Contact Name"
                      name="contact_name"
                    >
                      <Input
                        disabled={this.loading}
                        className="show full"
                        value={this.subscription.contact_name}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.subscription.contact_name = event.target.value;
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="full phone"
                      label="Contact Phone Number"
                      name="phone"
                      rules={[
                        {
                          // required: true,
                          //  message: "Parent name is required"
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            // it optional
                            if (!value || value.length === 0) {
                              return Promise.resolve();
                            }
                            if (value && Validation.allowNumbersOnly(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Please add a valid phone");
                          },
                        }),
                      ]}
                    >
                      <Input
                        disabled={this.loading}
                        className="show full"
                        value={this.subscription.phone}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.subscription.phone = event.target.value;
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="full contact_email_address"
                      label="Contact Email Address"
                      name="email"
                      rules={[
                        {
                          //  required: true, message: "Parent name is required"
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            // it optional
                            if (!value || value.length === 0) {
                              return Promise.resolve();
                            }
                            if (value && Validation.validateEmail(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Please add a valid email");
                          },
                        }),
                      ]}
                    >
                      <Input
                        disabled={this.loading}
                        className="show full"
                        type="email"
                        value={this.subscription.email || ""}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.subscription.email = event.target.value;
                        }}
                      />
                    </Form.Item>

                    <Divider className="divider b-grayM2" />
                    {!this.isUpdate ? (
                      <>
                        <Form.Item
                          className="full"
                          label="Subscriber login email"
                          name="subscriber_email_login"
                          rules={[
                            {
                              required: true,
                              message: "Subscriber email  is required",
                            },
                          ]}
                        >
                          <Input
                            disabled={this.loading}
                            className="show full"
                            type="text"
                            value={this.subscription.subscriber_email_login}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              this.subscription.subscriber_email_login =
                                event.target.value;
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          className="full"
                          label="Initial Password"
                          name="password"
                          rules={[
                            { required: true, message: "Password is required" },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  value &&
                                  Validation.validateSubscriberPassword(value)
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  !value || value.length === 0
                                    ? ""
                                    : "8 charachter, Minimum 1 capital and 1 digit"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            disabled={this.loading}
                            className="show full"
                            type="password"
                            value={this.subscription.password}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              this.subscription.password = event.target.value;
                            }}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <div className="flex white">
                        <div className="">Subscription Email:</div>
                        <div className="bold marginLeft10">
                          {this.subscription.subscriber_email_login}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <Form.Item className="full marginTop20">
                  <Link to="/subscribers">
                    <Button className="br gray radius marginVer10 lineHeight0">
                      <StopOutlined /> Cancel
                    </Button>
                  </Link>

                  <Button
                    disabled={this.loading}
                    htmlType="submit"
                    type="primary"
                    className="br-red red b-gray"
                    // onClick={this.isUpdate ? this.update : this.create}
                  >
                    {this.loading ? (
                      <LoadingOutlined />
                    ) : !this.isUpdate ? (
                      <PlusOutlined />
                    ) : (
                      <SaveOutlined />
                    )}
                    Save
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </CreateSubscriberStyled>
    );
  }
}
export default withRouter(CreateSubscriber);
