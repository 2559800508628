import { message } from "antd";
import { action, computed, observable, runInAction } from "mobx";
import BaseHttpService, { OptionsRequest } from "../services/base.http.service";
import parseObjectToUrlParams from "../utils/parseObjectToUrlParams";
import BaseModel from "./base.model";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
abstract class BaseStore {
  apiService: any;
  _status: "pending" | "reject" | "done" = "pending";

  @observable currentPageNumber: number = 1;
  @observable visible: boolean = false;
  @observable cantDeleteEntityMessageVisible: boolean = false;
  @observable cantDeleteEntityMessageVisibleLoading: boolean = false;
  @observable selectedRecord: any = false;
  @observable user: any = null;
  @observable filter: any = {};
  @observable allItems: any = [];

  constructor(apiService: BaseHttpService) {
    this.apiService = apiService;
  }

  get status() {
    return this._status;
  }

  @observable models: BaseModel[] = [];

  @computed get isAuthenticated(): boolean {
    return this.user !== null;
  }

  @action onFilterChanged = (filter: any) => {
    this.filter = filter;
  };

  @action
  del = (model: BaseModel) => {
    const index = this.models.indexOf(model);

    if (index > -1) {
      this.models.splice(index, 1);
    }
  };

  abstract url(): string;

  abstract get model(): BaseModel;

  create = (data: any = {}) => {
    const model: any = this.model;
    return new model(this.apiService, this, data);
  };

  @action
  fetchOne = (id: number): OptionsRequest => {
    return this.apiService.get(`${this.url()}/${id}`);
  };

  @action
  fetch = async (where?: any) => {
    const { promise } = this.apiService.get(
      `${this.url()}?${parseObjectToUrlParams(where || {})}`
    );
    const response: any = await promise;
    const { status } = response;

    if (status === 200) {
      const dataKey = this.url().replace("/", "");
      const data = await response.json();

      runInAction(() => {
        const _data = Array.isArray(data) ? data : data[dataKey] || [data];
        this.models = _data.map((data: any) => {
          const model: any = this.model;

          return new model(this.apiService, this, data);
        });
      });
    }
    return promise;
  };

  abstract async fetchPage({}: {
    page?: number;
    pageSize?: number;
    startTime?: number | undefined;
    endTime?: number | undefined;
    params?: any;
    filter?: any;
    customPath?: string;
  }): Promise<OptionsRequest>;

  @action
  changeUsersStatus = async (
    status: string,
    usersIds: any,
    options: any = {}
  ): Promise<any> => {
    const { promise } = this.apiService.post(`${this.url()}/change-status`, {
      status: status,
      usersIds: usersIds,
    });

    const response: any = await (await promise).json();
    const { updatedRows } = response;

    if (status === "delete") {
      if (updatedRows > 0) {
        this.fetchPage({ page: this.currentPageNumber, ...options });
        message.info(`Success deleted ${updatedRows} records`);
      } else {
        message.error("Something went wrong when create new category.");
      }
    } else {
      if (updatedRows[0] > 0) {
        this.fetchPage({ page: this.currentPageNumber, ...options });
        message.info(`Success updated ${updatedRows[0]} records`);
      } else {
        message.error("Something went wrong when create new category.");
      }
    }
  };

  @action
  downloadCsv(rows: any, file_name: string) {
    try {
      if (!rows.length) {
        message.error("Please select rows to export");
      } else {
        const csv = [rows[0]]
          .map((i: any) => {
            return Object.keys(i);
          })
          .concat(
            rows.map((i: any) => {
              return Object.values(i).map((v) => `"${v}"`);
            })
          )
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
        var hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = `wiser-market-${file_name}.csv`;
        hiddenElement.click();
      }
    } catch (error) {}
  }

  @action
  clearFilters = () => {
    return (this.filter = {});
  };
  @action
  onSearch = (pageOptions: any = {}): Promise<OptionsRequest> => {
    return this.fetchPage({ filter: this.filter, ...pageOptions });
  };

  @action
  deleteItem = async (params?: any, fetchPageOption = {}) => {
    this.visible = false;
    return this.selectedRecord.destroy();
  };

  @action
  cancelDeleteItem = async () => {
    this.visible = false;
  };

  @action
  openDeleteItem = async () => {
    this.visible = true;
  };

  @action
  toggleCantDeleteEntityMessageVisible = async () => {
    this.cantDeleteEntityMessageVisible = !this.cantDeleteEntityMessageVisible;
  };

  @action isDeleteAble = async (
    entityName: string,
    id: number
  ): Promise<any> => {
    return await (
      await this.apiService.get(`/validateBeforeDelete/${entityName}/${id}`)
        .promise
    ).json();
  };
}

export default BaseStore;
