export type OptionsRequest = {
  abort: any;
  promise: Promise<any>;
};

interface BaseService {
  get(path: string, options?: {}): OptionsRequest;
  post(path: string, data?: {}, options?: {}): OptionsRequest;
  put(path: string, data?: {}, options?: {}): OptionsRequest;
  del(path: string, options?: {}): OptionsRequest;
}
interface ICommonOptions {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  mode?: "no-cors" | "cors" | "same-origin";
  cache?: "default" | "no-cache" | "reload" | "force-cache" | "only-if-cached";
  credentials?: "include" | "same-origin" | "omit";
  headers?: { [key: string]: string };
  redirect?: "manual" | "follow" | "error";
  referrerPolicy?:
    | "no-referrer"
    | "no-referrer-when-downgrade"
    | "origin"
    | "origin-when-cross-origin"
    | "same-origin"
    | "strict-origin"
    | "strict-origin-when-cross-origin"
    | "unsafe-url";
  body?: any;
}
class BaseHttpService implements BaseService {
  baseUrl: string = "";

  commonOptions: ICommonOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(baseUrl: string = "") {
    this.baseUrl = baseUrl;
  }

  get(path: string, options: {} = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;

    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "GET" },
      signal,
    });

    return {
      promise: response,
      abort: controller.abort.bind(controller),
    } as OptionsRequest;
  }

  post(path: string, data?: {}, options: ICommonOptions = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "POST" },
      signal,
      body: JSON.stringify(data),
    });

    return {
      promise: response,
      abort: controller.abort.bind(controller),
    } as OptionsRequest;
  }

  formData(
    path: string,
    data: any = {},
    options: ICommonOptions = {}
  ): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      method: "POST",
      ...options,
      signal,
      body: data,
    });

    return {
      promise: response,
      abort: controller.abort.bind(controller),
    } as OptionsRequest;
  }

  put(path: string, data?: {}, options: ICommonOptions = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "PUT" },
      signal,
      body: JSON.stringify(data),
    });

    return {
      promise: response,
      abort: controller.abort.bind(controller),
    } as OptionsRequest;
  }

  del(path: string, options: ICommonOptions = {}): OptionsRequest {
    const controller = new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "DELETE" },
      signal,
    });

    return {
      promise: response,
      abort: controller.abort.bind(controller),
    } as OptionsRequest;
  }

  parseObjectToUrlParams = (obj: any, prefix?: any): any => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? this.parseObjectToUrlParams(v, k)
            : // : k + "=" + v
              encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };
}

export default BaseHttpService;
