import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { AlertsSettingsStyled } from "./styled";
import { InputNumber, Select, Button, message } from "antd";
import { observer, inject } from "mobx-react";
import AlertStore from "../../../../stores/alerts/alert.store";
import AlertSettingsStore from "../../../../stores/AlertSettings/AlertSetting.store";
import {
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  SaveOutlined,
} from "@ant-design/icons";

interface Props extends RouteComponentProps<any> {
  alertStore?: AlertStore;
  alertSettingStore?: AlertSettingsStore;
}

const { Option } = Select;

@inject("alertStore", "alertSettingStore")
@observer
class AlertsSettings extends React.PureComponent<Props, any> {
  componentDidMount = async () => {
    await this.props.alertSettingStore!.fetch();
  };

  onSave = async () => {
    const response = await this.props.alertSettingStore!.currentModel.save()
      .promise;

    if (response.status === 200) {
      message.success({
        content: "Settings Saved!",
        className: "wiser-message landing-page-message",
        icon: <CheckOutlined />,
      });
      this.props.history.push("/alerts/");
    } else {
      message.error({
        content: "Cannot Save Settings",
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }
  };

  render() {
    return (
      <AlertsSettingsStyled>
        <div className="f25 white ">Alerts Settings</div>
        <div className="marginTop20">
          <div className="f16 bold">Invalid Scans</div>
          <div>
            Notify when there are over
            <InputNumber
              defaultValue={5}
              min={1}
              max={100}
              className="transparent white radius textCenter marginVer20"
              value={
                this.props.alertSettingStore!.currentModel
                  ?.invalid_scan_condition?.number || 0
              }
              onBlur={() => {}}
              onChange={(value: any) => {
                this.props.alertSettingStore!.currentModel.invalid_scan_condition.number =
                  value;
              }}
            />
            invalid Scans
            <Select
              style={{ width: 120 }}
              className="transparent white radius textCenter marginVer20"
              defaultValue={"or"}
              value={
                this.props.alertSettingStore!.currentModel
                  ?.invalid_scan_condition?.condition || "or"
              }
              onChange={(value: string) =>
                (this.props.alertSettingStore!.currentModel.invalid_scan_condition.condition =
                  value)
              }
            >
              <Option value="and">and</Option>
              <Option value="or">or</Option>
            </Select>
            Notify when there are over
            <InputNumber
              defaultValue={5}
              min={1}
              max={100}
              className="transparent white radius textCenter marginVer20"
              value={
                this.props.alertSettingStore!.currentModel
                  ?.invalid_scan_condition?.percentage || 0
              }
              onChange={(value: any) =>
                (this.props.alertSettingStore!.currentModel.invalid_scan_condition.percentage =
                  value)
              }
              formatter={(value) => `${value}%`}
              parser={(value) => `${value}`.replace("%", "")}
            />
            invalid Scans
          </div>
        </div>

        <div className="marginTop20">
          <div className="f16 bold">Territory Based</div>
          <div>
            Notify when there are over
            <InputNumber
              min={1}
              max={100}
              className="transparent white radius textCenter marginVer20"
              value={
                this.props.alertSettingStore!.currentModel
                  ?.territory_based_condition?.number || 0
              }
              onChange={(value: any) =>
                (this.props.alertSettingStore!.currentModel.territory_based_condition.number =
                  value)
              }
            />
            in invalid territory
            <Select
              style={{ width: 120 }}
              className="transparent white radius textCenter marginVer20"
              value={
                this.props.alertSettingStore!.currentModel
                  ?.territory_based_condition?.condition || "or"
              }
              onChange={(value: string) =>
                (this.props.alertSettingStore!.currentModel.territory_based_condition.condition =
                  value)
              }
            >
              <Option value="and">and</Option>
              <Option value="or">or</Option>
            </Select>
            Notify when there are over
            <InputNumber
              min={1}
              max={100}
              className="transparent white radius textCenter marginVer20"
              value={
                this.props.alertSettingStore!.currentModel
                  ?.territory_based_condition?.percentage || 0
              }
              onChange={(value: any) =>
                (this.props.alertSettingStore!.currentModel.territory_based_condition.percentage =
                  value)
              }
              formatter={(value) => `${value}%`}
              parser={(value) => `${value}`.replace("%", "")}
            />
            in invalid territory
          </div>
        </div>

        <Link to="/alerts">
          <Button className="br  gray radius marginVer10 lineHeight0">
            <StopOutlined /> Cancel
          </Button>
        </Link>

        <Button
          className="b-grayM marginTop20 transparent white radius gray red-button"
          onClick={this.onSave}
        >
          <SaveOutlined /> Save Settings
        </Button>
      </AlertsSettingsStyled>
    );
  }
}
export default withRouter(AlertsSettings);
