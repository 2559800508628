import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ForgotPassStyled } from "./styled";
import { Input, Button, Form } from "antd";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import AuthStore from "../../../stores/auth/auth.store";
import Validation from "../../../services/validation";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class ForgotPass extends React.PureComponent<Props, any> {
  @observable error: null | string = null;
  @observable sucsses: null | string = null;
  @observable reset_password: boolean = false;

  onFinish = async (values: any) => {
    try {
      const { promise } = this.props.authStore!.forgotPassword(values);
      const response = await promise;
      const data = await response.json();

      if (response.status === 200) {
        if (data.status) {
          this.error = "";
          this.reset_password = true;
        } else {
          this.error = "No user found with this email!";
        }
      } else {
        this.error = data.message;
      }
    } catch (error) {
      console.error(error);
    }
  };

  onFinishFailed = (errorInfo: any) => {};

  render() {
    return (
      <ForgotPassStyled>
        <div className="auth b-graym">
          <div>
            <div className="marginHor20 ">
              <div>
                <div className="f35 marginHor10 bold">Reset Password</div>
              </div>
            </div>
            {!this.reset_password ? (
              <Form
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  label="Email Address"
                  className="full"
                  name="email"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && Validation.validateEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Please add a valid email");
                      },
                    }),
                  ]}
                >
                  <Input
                    type="email"
                    size={"large"}
                    prefix={<UserOutlined />}
                    className="marginTop10 full radius br-black input"
                    placeholder={"Email"}
                  />
                </Form.Item>

                <div className="red">{this.error}</div>

                <div className="flex">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      size={"large"}
                      className="b-grayM radius red br-red full marginTop20"
                    >
                      Send
                    </Button>
                  </Form.Item>
                  <Link to="/auth/login" className="full red f12 forgot">
                    login
                  </Link>
                </div>
              </Form>
            ) : (
              <div className="green">Please check your email</div>
            )}
          </div>
        </div>
      </ForgotPassStyled>
    );
  }
}
export default withRouter(ForgotPass);
