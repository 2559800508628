class Validation {
  static validateEmail(email: any) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static validatePhone(value: any) {
    return value.match(/^[0-9\,\-\+]+$/);
  }

  static validateSubscriberPassword(password: any) {
    return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})").test(
      password
    );
  }

  static validateUserPassword(password: any) {
    return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})").test(
      password
    );
  }

  static distributorPhoneNumber(value: any) {
    return value.match(/^[0-9\,\-\+]+$/);
  }

  static onlyLetters(value: any) {
    return value.match(/^[A-Za-zא-ת_-\s]+$/);
  }

  static noEmojies(value: any) {
    var regex = /\p{Emoji}/u;
    console.log(regex.test(value));
    return regex.test(value);
  }

  static validateURL(text: any) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(text);
  }

  static allowNumbersOnly(inputValue: any) {
    return new RegExp("^[0-9]+$").test(inputValue);
  }
}

export default Validation;
