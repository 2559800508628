import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ProductsScansStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import { AutoComplete, Input, Tag, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import { observable, computed, action } from "mobx";
import debounce from "../../utils/debounce";
import moment from "moment";
import ProductScansStore from "../../stores/product-scans/product-scan.store";
import ProductScan from "../../stores/product-scans/product-scan.model";
import FilterPage from "../../components/FilterPage/FilterPage";
import { ReactSVG } from "react-svg";

interface Props extends RouteComponentProps<any> {
  productScansStore?: ProductScansStore;
}

@inject("productScansStore")
@observer
class ProductsScans extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable searchLoading: boolean = false;
  abortRequest: Function | undefined | null;

  columns = [
    {
      title: "Scan Date",
      dataIndex: "createdAt",
      // render: (createdAt: any) => moment(createdAt).format("MMM Do YYYY"),
      render: (createdAt: any) =>
        moment(new Date(createdAt)).format("MMMM DD [at] hh:mm A"),
      key: "createdAt",
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "category",
      render: (product: any, record: any) => record?.category?.name || " - ",
    },

    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (subCategory: any, { sub_category_name }: any = {}) =>
        subCategory?.name || " - ",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product: any, record: any) => product?.name || " - ",
    },
    {
      title: "QR ID",
      dataIndex: "qr",
      key: "qr",
      render: (qr: any, record: any) => qr?.id || " - ",
    },
    {
      title: "Scan Territory",
      dataIndex: "geo",
      render: (geo: any) =>
        geo
          ? 
              geo?.country 
          : " - ",
      key: "geo",
    },

    {
      title: "Scan",
      dataIndex: "scanValid",
      key: "scanValid",
      render: (scanValid: number) =>
        scanValid ? (
          <Tag color="green">Valid</Tag>
        ) : (
          <Tag color="red">InValid</Tag>
        ),
    },

    // {
    //   title: "",
    //   dataIndex: "actions",
    //   className: "f16 table_action",
    //   render: (text: string, record: any) => (
    //     <TableActionsCellStyled>
    //       <Tooltip placement="bottom" title={"Details"}>
    //         <img
    //         onClick={()=>{

    //         }}
    //           src="/images/action/PlusIcon_blue.svg"
    //           alt="cil-link_green.svg"
    //         />
    //       </Tooltip>
    //     </TableActionsCellStyled>
    //   ),
    //   key: "action",
    // },
  ];

  componentDidMount = async () => {
    try {
      await this.props.productScansStore?.fetchPage({ page: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { productScansStore } = this.props;
    await productScansStore!.fetchPage({
      page,
      pageSize,
      filter: productScansStore!.filter,
    });
    // router.navigate("templates", { page, pageSize });
  };

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  // onSearchDebounce = debounce(async (value: string) => {
  //   try {
  //     if (this.abortRequest) {
  //       this.abortRequest();
  //     }
  //     this.searchLoading = true;
  //     const { abort, promise } = await this.props!.productScansStore!.onSearch({
  //       filter: { ...this.props!.productScansStore!.filter, name: value },
  //     });

  //     this.abortRequest = abort;
  //     await promise;
  //     this.abortRequest = null;
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   this.searchLoading = false;
  // });

  onSearchDebounce = debounce(async (value: string) => {
    this.props.productScansStore!.searchLoading = true;
    this.props.productScansStore!.onFilterChanged({
      ...this.props!.productScansStore!.filter,
      name: value,
    });
  });

  renderExpandIcon = ({ expanded, onExpand, record }: any) => {
    return expanded ? (
      <Tooltip placement="bottom" title={"Close"}>
        <ReactSVG
          className="plus_icon"
          onClick={(e) => onExpand(record, e)}
          src="/images/action/close/Blue-Minus.svg"
        />
      </Tooltip>
    ) : (
      <Tooltip placement="bottom" title={"Details"}>
        <ReactSVG
          className="plus_icon"
          onClick={(e) => onExpand(record, e)}
          src="/images/action/PlusIcon_blue.svg"
        />
      </Tooltip>
    );
  };
  expandedRowRender = (record: ProductScan) => {
    return (
      <div className="grid  details" style={{ padding: "0 60px" }}>
        <div>
          {/* <div className="flex">
            <div className="bold label">Product</div>
            <div>{record.qr.category.name || " - "}</div>
          </div> */}
          <div className="flex">
            <div className="bold label">Serial No.</div>
            <div> {record.qr.series || " - "} </div>
          </div>
        </div>

        <div>
          <div className="flex ">
            <div className="bold label">Distributor</div>
            <div>{record.qr.distributor.name || " - "}</div>
          </div>
          {/* <div className="flex">
            <div className="bold label">Scan Territory</div>
            <div> - </div>
          </div> */}
        </div>
        <div></div>
        <div></div>
        <div>
          <div className="flex ">
            <div className="bold label">Distributor's Territory</div>
            <div>{record.distributer_territories}</div>
          </div>
        </div>
        {/* <div>
          <div className="flex">
            <div className="bold label">Numbers of Scans</div>
            <div>-</div>
          </div>
          <div className="flex">
            <div className="bold label">Invalid scans</div>
            <div>-</div>
          </div>
        </div> */}
      </div>
    );
  };

  render() {
    const { productScansStore } = this.props;
    return (
      <ProductsScansStyled>
        <Header
          title={"Product Scans"}
          rightMenu={
            <div className="flex flexVerCenter">
              <FilterPage
                color="#63c2de"
                store={productScansStore}
                filters={["category", "territory", "distributor", "scanValid"]}
              />
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    productScansStore?.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          expandIcon={this.renderExpandIcon}
          loading={this.props?.productScansStore?.fetchPageLoading}
          NotAllActionOption={true}
          dataSource={this.props?.productScansStore?.currentPage.map(
            (row: any) => {
              row.key = row.id;
              return row;
            }
          )}
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.productScansStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.productScansStore!.changeUsersStatus(action, rowsIds);
          }}
          handleExportData={() => {
            let rows = this.props!.productScansStore!.selectedRows.map(
              (row: any) => ({
                "Scan Date": moment(row.createdAt).format(
                  "MMMM DD [at] hh:mm A"
                ),
                Category: row.category.name,
                "Sub Category": row.subCategory?.name || " - ",
                "QR ID": row?.qr?.id || " - ",
                Product: row.product?.name || " - ",
                "Scan Territory": row.geo.country.name || " - ",
                "Distributor Territory": row.distributer_territories || "-",
                "Serial No": row.qr.series || "-",
                Distributor: row.qr.distributor.name || "-",
                Scan: row.scanValid ? "valid" : "invalid",
              })
            );
            this.props!.productScansStore!.downloadCsv(rows, "products_scans");
          }}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.productScansStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.productScansStore!.selectedRows = selectedRows;
          }}
          total={this.props!.productScansStore!.total}
          disableMultiSelectAction={true}
          pageSize={this.props!.productScansStore!.pageSize}
          currentPageNumber={this.props!.productScansStore!.currentPageNumber}
          onPageSettingsChange={this.onPageSettingsChange}
          expandedRowRender={this.expandedRowRender}
          expandIconColumnIndex={8}
          // expandIcon={this.renderExpandIcon}
        />
      </ProductsScansStyled>
    );
  }
}
export default withRouter(ProductsScans);
