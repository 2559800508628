import styled from "styled-components";

const LandingPagesDetailsStyled = styled.div`
  .active {
    border-bottom: 1px solid #5edc89;
    &.step2 {
      border-bottom: 1px solid #ce7375;
    }
  }

  .active {
    .white {
      color: #5edc89 !important;
    }
    &.step2 {
      .white {
        color: #ce7375 !important;
      }
    }
  }
`;

export { LandingPagesDetailsStyled };
