import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { AlertsStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import { Button, AutoComplete, Input, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import { observable, computed, action } from "mobx";
import debounce from "../../utils/debounce";
import AlertStore from "../../stores/alerts/alert.store";
import moment from "moment";
import Alert from "../../stores/alerts/alert.model";
import FilterPage from "../../components/FilterPage/FilterPage";
import { SettingOutlined } from "@ant-design/icons";
import { TableActionsCellStyled } from "../../styled";
import { ReactSVG } from "react-svg";
interface Props extends RouteComponentProps<any> {
  alertStore?: AlertStore;
}
@inject("alertStore")
@observer
class Alerts extends React.PureComponent<Props, any> {
  /**
   * Set is row critical
   */
  CRITICAL_ALERT_COUNT = 1000000;

  @observable _width: number = 200;
  @observable loading: boolean = false;

  @observable searchLoading: boolean = false;
  abortRequest: any;

  columns = [
    {
      title: "Alert Type",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "First Scan",
      dataIndex: "first_scan",
      render: (value: string, record: any) =>
        // moment(record.first_scan.createdAt).format("MMM Do YYYY"),
        moment(record.first_scan).format("MMMM D [at] hh:mm A"),
    },
    {
      title: "Last Scan",
      dataIndex: "last_scan",
      key: "last_scan",
      render: (value: string, record: any) =>
        // moment(record.last_scan.createdAt).format("MMM Do YYYY"),
        moment(record.last_scan).format("MMMM D [at] hh:mm A"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category: any) => category?.name || " - ",
    },

    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (subCategory: any) => subCategory?.name || " - ",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product: any) => product?.name || " - ",
    },
    {
      title: "QR ID",
      dataIndex: "qr_code_id",
      key: "qr_code_id",
      render: (qr_code_id: any) =>
        qr_code_id && qr_code_id !== 1 ? qr_code_id : " - ",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },

    // {
    //   title: "",
    //   dataIndex: "actions",
    //   className: "f16 table_action",
    //   render: (text: string, record: any) => (
    //     <TableActionsCellStyled>
    //       <Tooltip placement="bottom" title={"Details"}>
    //         <img
    //           src="/images/action/PlusIcon_blue.svg"
    //           alt="cil-link_green.svg"
    //         />
    //       </Tooltip>
    //     </TableActionsCellStyled>
    //   ),
    //   key: "action",
    // },
  ];

  componentDidMount = async () => {
    try {
      await this.props.alertStore?.fetchPage({ page: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { alertStore } = this.props;
    await alertStore!.fetchPage({ page, pageSize, filter: alertStore!.filter });
    // router.navigate("templates", { page, pageSize });
  };

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  // onSearchDebounce = debounce(async (value: string) => {
  //   try {
  //     if (this.abortRequest) {
  //       this.abortRequest();
  //     }
  //     this.searchLoading = true;
  //     const { abort, promise } = await this.props!.alertStore!.onSearch({
  //       filter: { ...this.props!.alertStore!.filter, name: value },
  //     });

  //     this.abortRequest = abort;
  //     await promise;
  //     this.abortRequest = null;
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   this.searchLoading = false;
  // });

  onSearchDebounce = debounce(async (value: string) => {
    this.props.alertStore!.searchLoading = true;
    this.props.alertStore!.onFilterChanged({
      ...this.props!.alertStore!.filter,
      name: value,
    });
  });

  expandedRowRender = (record: Alert) => {
    return (
      <div className="grid grid3 alert-details">
        <div>
          <div className="flex">
            <div className="bold label">Product</div>
            <div>{record?.product?.name || " - "}</div>
          </div>
          <div className="flex">
            <div className="bold label">Serial No.</div>
            <div> {record?.qr?.series || " - "}</div>
          </div>
        </div>

        <div>
          <div className="flex ">
            <div className="bold label">Distributor</div>
            <div>{record?.distributor?.name || " - "}</div>
          </div>
          <div className="flex">
            <div className="bold label">Distributor territories</div>
            <div>{record?.distributor?.territories?.join(", ") || " - "}</div>
          </div>
        </div>

        <div>
          <div className="flex">
            <div className="bold label">Numbers of Scans</div>
            <div>{record?.scan_count || " - "}</div>
          </div>
          <div className="flex">
            <div className="bold label">Invalid Scans</div>
            <div>{record?.inValid_scan_count || " - "}</div>
          </div>
        </div>
      </div>
    );
  };

  setIsCriticalAlert = (record: any, index: number) => {
    return record.scan_count > this.CRITICAL_ALERT_COUNT ? "critical" : "";
  };

  renderExpandIcon = ({ expanded, onExpand, record }: any) => {
    return expanded ? (
      <>
        <Tooltip placement="bottom" title={"Close"}>
          <ReactSVG
            className="plus_icon"
            onClick={(e) => onExpand(record, e)}
            src="/images/action/close/Red-Minus.svg"
          />
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip placement="bottom" title={"Details"}>
          <ReactSVG
            className="plus_icon"
            onClick={(e) => onExpand(record, e)}
            src="/images/action/PlusIcon_red.svg"
          />
        </Tooltip>
      </>
    );
  };

  render() {
    const { alertStore } = this.props;
    return (
      <AlertsStyled>
        <Header
          title={"Alerts - Important Product Scans"}
          rightMenu={
            <div className="flex flexVerCenter">
              <Link to="/alerts/settings">
                <Button
                  className="br br-blue blue b-gray radius marginVer10 lineHeight0 settings-button"
                  size="large"
                >
                  <SettingOutlined /> Alert settings
                </Button>
              </Link>
              <FilterPage
                color="#ce3b41"
                store={alertStore}
                filters={["category", "territory", "distributor"]}
              />
              <AutoComplete
                size={"large"}
                className={"br-gray2 radius"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    alertStore?.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          expandIcon={this.renderExpandIcon}
          loading={this.props.alertStore?.fetchPageLoading}
          dataSource={this.props?.alertStore?.currentPage.map((row: any) => {
            row.key = row.id;
            return row;
          })}
          handleRowSelection={(selectedRows: any) => {
            this.props!.alertStore!.selectedRows = selectedRows;
          }}
          handleExportData={() => {
            let rows = this.props!.alertStore!.selectedRows.map((row: any) => ({
              "Alert Type": row.name,
              "First Scan": moment(row.first_scan).format("MMM Do YYYY"),
              "Last Scan": moment(row.last_scan).format("MMM Do YYYY"),
              Category: row.category.name,
              Details: row.details,
            }));
            this.props!.alertStore!.downloadCsv(rows, "alerts");
          }}
          columns={this.columns}
          handleTableAction={(action: any) => {}}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.alertStore!.currentPageNumber,
              number_of_results
            );
          }}
          total={this.props!.alertStore!.total}
          disableMultiSelectAction={true}
          pageSize={this.props!.alertStore!.pageSize}
          currentPageNumber={this.props!.alertStore!.currentPageNumber}
          onPageSettingsChange={this.onPageSettingsChange}
          expandedRowRender={this.expandedRowRender}
          expandIconColumnIndex={8}
          rowClassName={this.setIsCriticalAlert}
        />
      </AlertsStyled>
    );
  }
}
export default withRouter(Alerts);
