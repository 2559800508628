import styled from "styled-components";

const TableDynamicStyled = styled.div`
  /* min-height: calc(100vh - 188px); */
  display: flex;
  flex-direction: column;
  > div:first-child {
    flex: 1;
  }
  footer {
    border-top: 1px solid #2f363a;
  }
  background-color: #3e444c;
  .ant-pagination-item a {
    color: white;
  }
  .ant-select-selector {
    background: transparent !important;
  }

  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  span.anticon.anticon-down {
    color: #fff;
  }
  footer {
    display: flex;
  }
  .actions_table {
    flex: 1;
  }
  .ant-pagination {
  }
  .ant-checkbox-inner {
    background: transparent;
    border-radius: 5px;
    padding: 10px;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: transparent !important;
  }

  .ant-select-item-option-content {
  }

  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    background: transparent !important;
    border: 1px solid #6f6b6b !important;
    border-radius: 5px !important;
  }

  .ant-select-item.ant-select-item-option {
    color: #fff !important;
    background: transparent !important;
  }

  li.ant-pagination-item {
    background: #818286;
    border: 1px solid #727579 !important;
  }

  li.ant-pagination-next.ant-pagination-disabled {
    background: transparent !important;
  }

  a.ant-pagination-item-link {
    border-color: #727579;
    color: white;
    background: transparent;
  }

  .divider {
    height: 30px !important;
    margin-top: 2px;
    background: #727579 !important;
  }

  .ant-select-selector {
    border-radius: 6px !important;
  }

  th.ant-table-cell {
    font-size: 21px;
  }
  footer {
    padding: 20px 10px 20px 20px;
  }
`;

export { TableDynamicStyled };
