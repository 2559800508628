import styled from "styled-components";

const LandingPagesStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #4dbd74 !important;
  }

  .title {
    padding: 20px;
  }
  .landing-pages {
    button {
      svg {
        fill: #4dbd74;
      }
    }
  }
`;

export { LandingPagesStyled };
