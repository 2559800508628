import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { DownloadModalStyled } from "./styled";
import { BarcodeOutlined } from "@ant-design/icons";
import { observer, inject } from "mobx-react";
import QrCodesStore from "../../../../stores/qr-codes/qr-codes.store";

interface Props extends RouteComponentProps<any> {
  qrCodeStore?: QrCodesStore;
  onSelected: any;
  selectedValue: any;
}

@inject("qrCodeStore")
@observer
class DownloadModal extends React.PureComponent<Props, any> {
  render() {
    return (
      <DownloadModalStyled>
        <div className="flex">
          <div
            className={`pointer type ${
              this.props.selectedValue === "single" ? " selected" : ""
            }`}
            onClick={() => {
              this.props.onSelected("single");
            }}
          >
            <div className="title">Single</div>
            <BarcodeOutlined />
          </div>
          <div
            className={`pointer type ${
              this.props.selectedValue === "multi" ? " selected" : ""
            }`}
            onClick={() => {
              this.props.onSelected("multi");
            }}
          >
            <div className="title">Multi</div>
            <div className="grid grid3 pointer">
              <BarcodeOutlined />
              <BarcodeOutlined />
              <BarcodeOutlined />
              <BarcodeOutlined />
              <BarcodeOutlined />
              <BarcodeOutlined />
              <BarcodeOutlined />
              <BarcodeOutlined />
              <BarcodeOutlined />
            </div>
          </div>
        </div>
      </DownloadModalStyled>
    );
  }
}
export default withRouter(DownloadModal);
