import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Popover, Button } from "antd";
import FilterData from "./components/FilterData/FilterData";
// import PageStore from "../../stores/page.store";
import { LoadingOutlined, FilterOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";

interface Props extends RouteComponentProps<any> {
  // store: PageStore;
  store: any;
  filters: string[];
  color?: string;
  title?: string;
  date?: boolean;
}

@observer
class FilterPage extends React.PureComponent<Props, any> {
  componentDidMount = () => {
    this.props?.store?.clearFilters();
  };
  render() {
    const { store, filters } = this.props;
    return (
      <Popover
        placement="bottom"
        content={
          <FilterData
            color={this.props.color}
            loading={store.fetchPageLoading}
            date={
              typeof this.props.date !== "undefined" ? this.props.date : true
            }
            filter={filters}
            onFilterChanged={this.props.store!.onFilterChanged}
            filterSelected={this.props.store!.filter}
          />
        }
      >
        <Button
          className="br br-grayM white b-gray radius marginRight10 lineHeight0"
          size="large"
        >
          {store.fetchPageLoading ? <LoadingOutlined /> : <FilterOutlined />}
          {this.props.title || "Filter"}
        </Button>
      </Popover>
    );
  }
}
export default withRouter(FilterPage);
