import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import { Button, AutoComplete, Input, Divider, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import { observable, computed, action } from "mobx";
import debounce from "../../utils/debounce";
import DistributorsStore from "../../stores/distributors/distributor.store";
import { DistributorsStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import { TableActionsCellStyled } from "../../styled";
import { ReactComponent as DeleteIcon } from "../../assets/action/cil-trash.svg";
import DeleteEntityButton from "../../components/DeleteEntityButton/DeleteEntityButton";

interface Props extends RouteComponentProps<any> {
  distributorsStore?: DistributorsStore;
}

@inject("distributorsStore")
@observer
class Distributors extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable searchLoading: boolean = false;
  abortRequest: any;

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "10%",
      key: "name",
    },

    {
      title: "Phone Number",
      dataIndex: "phone_number",
      width: "20%",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
      key: "email",
    },

    {
      title: "Territory",
      dataIndex: "territories",
      key: "territories",
      render: (territories: any) => territories.join(" ,"),
    },

    {
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      render: (text: string, record: any) => (
        <TableActionsCellStyled>
          <Tooltip placement="bottom" title={"Edit"}>
            <Link to={`/distributors/${record.id}/edit/`}>
              <img
                src="/images/action/cil-pencil_yellow.svg"
                alt="cil-pencil_yellow.svg"
              />
            </Link>
          </Tooltip>

          <Divider type="vertical" className={"b-grayM2"} />

          <DeleteEntityButton
            className="distributors"
            isDeleteAbleFn={() => this.isDeleteAble("distributor", record.id)}
            onDelete={() => this.onDelete(record)}
            itemName={"Distributor"}
            connectedItemsNames={["Landing pages"]}
            icon={<DeleteIcon />}
          />
        </TableActionsCellStyled>
      ),
      key: "action",
    },
  ];

  isDeleteAble = (entityType: string, recordId: number): Promise<boolean> => {
    return this.props!.distributorsStore!.isDeleteAble(entityType, recordId);
  };

  onDelete = (record: any) => record.destroy();

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { distributorsStore } = this.props;
    await distributorsStore!.fetchPage({
      filter: distributorsStore!.filter,
      page,
      pageSize,
      params: { child_number: [3] },
    });
    // router.navigate("templates", { page, pageSize });
  };

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  componentDidMount = async () => {
    try {
      await this.props.distributorsStore!.fetchPage({
        page: 1,
        params: { child_number: [3] },
      });
    } catch (error) {
      console.error(error);
    }
  };

  deleteItem = async () => {
    this.loading = true;
    await this.props?.distributorsStore?.deleteItem();
    this.loading = false;
  };

  onSearchDebounce = debounce(async (value: string) => {
    this.props.distributorsStore!.searchLoading = true;
    this.props.distributorsStore!.onFilterChanged({
      ...this.props!.distributorsStore!.filter,
      name: value,
    });
  });

  render() {
    const { distributorsStore } = this.props;
    return (
      <DistributorsStyled>
        {this.loading}
        <Header
          title={"Distributors"}
          rightMenu={
            <div className="flex flexVerCenter">
              <Link to="/distributors/create">
                <Button className="br br-yellow yellow b-gray radius marginVer10 lineHeight0">
                  + Add New Distributor
                </Button>
              </Link>

              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    distributorsStore?.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          loading={this.props?.distributorsStore?.fetchPageLoading}
          dataSource={this.props?.distributorsStore?.currentPage.map(
            (row: any) => {
              row.key = row.id;
              return row;
            }
          )}
          disableMultiSelectAction={true}
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.distributorsStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.distributorsStore!.changeUsersStatus(action, rowsIds);
          }}
          handleExportData={() => {
            let rows = this.props!.distributorsStore!.selectedRows.map(
              (row: any) => ({
                Id: row.id,
                Name: row.name,
                "Phone Number": row.phone_number,
                Email: row.email,
                Territory: row.territories,
              })
            );
            this.props!.distributorsStore!.downloadCsv(rows, "distributers");
          }}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.distributorsStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.distributorsStore!.selectedRows = selectedRows;
          }}
          total={this.props?.distributorsStore?.total || 0}
          pageSize={this.props?.distributorsStore?.pageSize || 0}
          currentPageNumber={
            this.props?.distributorsStore?.currentPageNumber || 0
          }
          onPageSettingsChange={this.onPageSettingsChange}
        />

        <ConfirmPopup
          visible={this.props?.distributorsStore?.visible}
          handledeleteItem={this.deleteItem}
          handleCancelDeleteItem={
            this.props?.distributorsStore?.cancelDeleteItem
          }
        />
      </DistributorsStyled>
    );
  }
}
export default withRouter(Distributors);
