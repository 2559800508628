import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IAlertSetting } from "./AlertSetting.interface";
import { observable, computed } from "mobx";

class AlertSettings extends BaseModel implements IAlertSetting {
  @observable invalid_scan_condition: any = {};
  @observable territory_based_condition: any = {};
  @observable id: number = 0;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "alertSetting";
  }

  responseModelsKey(): string {
    return "AlertSettings";
  }

  get url(): string {
    return "/alert-settings";
  }

  @computed get toJson(): { [key: string]: any } {
    return this._toJson({
      invalid_scan_condition: this.invalid_scan_condition,
      territory_based_condition: this.territory_based_condition,
      id: this.id,
    });
  }
}

export default AlertSettings;
