import styled from "styled-components";

const CategoriesStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);
  /* thead {
    th:nth-child(3) {
      display: none;
    }
  } */

  .sub-category {
    .ant-table-row-indent.indent-level-1,
    .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
      display: none;
    }
  }

  .ant-table-row.ant-table-row-level-1.sub-category {
    background: #282e33;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #e8b20f !important;
  }

  td.table_action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .pointer {
      height: 22px;
    }
  }

  .categories {
    button {
      svg {
        fill: #eeb41d;
      }
    }
  }
`;

export { CategoriesStyled };
