import BaseService from "../base.service";

class AlertService extends BaseService {
  constructor(baseUrl: string = "") {
    super(baseUrl);
  }

  saveSettings = (data: any) => {
    return this.post("/alerts", data);
  };
}

export default AlertService;
