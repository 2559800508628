/* eslint-disable @typescript-eslint/no-useless-constructor */
import AlertService from "./alert.service";
import Alert from "./alert.model";
import BaseStore from "../base.store";
import PageStore from "../page.store";

class AlertStore extends PageStore {
  constructor(alertService: AlertService) {
    super(alertService);
  }

  url(): string {
    return `/alerts`;
  }

  get model(): any {
    return Alert;
  }

  saveSettings = (data: any) => {
    return this.apiService.saveSettings(data);
  };
}

export default AlertStore;
