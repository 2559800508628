import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { TableDynamicStyled } from "./styled";
import { Table, Button, Divider, Select, InputNumber, Pagination } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { toJS } from "mobx";
const { Option } = Select;
interface Props extends RouteComponentProps<any> {
  dataSource: any;
  columns: any;
  handleTableAction?: any;
  handleRowSelection?: any;
  handleExportData?: any;
  handleNumberOfResult?: any;
  currentPageNumber?: number;
  NotAllActionOption?: Boolean;
  disableMultiSelectAction?: Boolean;
  pageSize?: number;
  total?: number;
  expandedRowRender?: any;
  loading?: boolean;
  footer?: boolean;
  expandIconColumnIndex?: number;
  expandIcon?: React.ReactNode;
  rowClassName?: any;
  childrenColumnName?: string;
  onPageSettingsChange?: (page: number, pageSize?: number | undefined) => void;
}

const ObserverString = observer(
  ({
    record,
    prop,
    style,
    renderWrapper,
  }: {
    record: any;
    prop: string;
    style?: any;
    renderWrapper?: boolean;
  }) => {
    return !renderWrapper ? (
      <>{record[prop]}</>
    ) : (
      <div style={style}>{record[prop]}</div>
    );
  }
);

const render = (prop: any, style?: any, renderWrapper: boolean = false) => (
  value: any,
  record: any
) => (
  <ObserverString
    record={record}
    prop={prop}
    style={style || {}}
    renderWrapper={renderWrapper}
  />
);

function itemRender(current: any, type: any, originalElement: any) {
  if (type === "prev") {
    return <a className="previous-button">Previous</a>;
  }
  if (type === "next") {
    return <a className="next-button">Next</a>;
  }
  return originalElement;
}
@observer
class DynamicTable extends React.PureComponent<Props, any> {
  static defaultProps = {
    footer: true,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      action: "null",
    };
  }

  render() {
    const {
      dataSource,
      columns,
      currentPageNumber,
      pageSize,
      onPageSettingsChange,
      total,
      footer,
      loading,
      expandIconColumnIndex,
      expandIcon,
      rowClassName,
      childrenColumnName,
    } = this.props;

    const props: any = {};
    if (expandIcon) {
      props.expandIcon = expandIcon;
    }
    if (expandIconColumnIndex) {
      props.expandIconColumnIndex = expandIconColumnIndex;
    }
    if (rowClassName) {
      props.rowClassName = rowClassName;
    }
    return (
      <TableDynamicStyled>
        <Table
          childrenColumnName={childrenColumnName}
          {...props}
          loading={loading}
          pagination={{ pageSize }}
          expandedRowRender={this.props.expandedRowRender}
          rowSelection={
            this.props.handleRowSelection
              ? {
                  type: "checkbox",
                  onChange: (selectedRowKeys: any, selectedRows: any) => {
                    this.props.handleRowSelection &&
                      this.props.handleRowSelection(selectedRows);
                  },
                }
              : null
          }
          columns={columns.map((column: any) => {
            if (!column.render) {
              column.render = render(column.dataIndex);
            }
            return column;
          })}
          dataSource={dataSource}
        />

        {!footer ? null : (
          <footer className="">
            <div className="flex white  actions_table">
              <div className="paddingTop5 marginRight10">Action</div>
              {!this.props.disableMultiSelectAction ? (
                <>
                  <div className="">
                    <Select
                      className="transparent white radius marginRight10"
                      placeholder="select"
                      style={{ width: 120 }}
                      onChange={(e) => this.setState({ action: e })}
                    >
                      {this.props.NotAllActionOption ? (
                        <Option value="delete">Delete</Option>
                      ) : (
                        <>
                          <Option value="delete">Delete</Option>
                          <Option value="disable">Disable</Option>
                          <Option value="active">Active</Option>
                        </>
                      )}
                    </Select>
                  </div>

                  <div className="">
                    <Button
                      className="transparent white radius"
                      onClick={() =>
                        this.props.handleTableAction(this.state.action)
                      }
                    >
                      SUBMIT
                    </Button>
                  </div>
                  <Divider className="divider" type="vertical" />
                </>
              ) : null}
              <div className="">
                <Button
                  onClick={(e) => this.props.handleExportData()}
                  className="transparent white radius"
                  icon={<ExportOutlined />}
                >
                  Export Data
                </Button>
              </div>
              <Divider className="divider" type="vertical" />
              <div className="flex">
                <div className="paddingTop5 marginRight10">Items per page</div>
                <InputNumber
                  className="transparent white radius"
                  onChange={(e) => this.props.handleNumberOfResult(e)}
                  placeholder="10"
                  value={pageSize}
                />
              </div>
            </div>

            <Pagination
              total={Math.max(1, total || 0)}
              onChange={onPageSettingsChange}
              onShowSizeChange={onPageSettingsChange}
              current={Number(toJS(currentPageNumber))}
              pageSize={Number(toJS(pageSize))}
              pageSizeOptions={["30", "40", "100"]}
              itemRender={itemRender}
            />
          </footer>
        )}
      </TableDynamicStyled>
    );
  }
}

export default withRouter(DynamicTable);
