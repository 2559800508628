import BaseService from "../base.service";

class ProductScansService extends BaseService {
  constructor(baseUrl: string = "") {
    super(baseUrl);
  }

  scanPageAndGetLandingPage = (group_id: string, qr_id: number) => {
    return this.post(`/product-scans/${group_id}/${qr_id}/`).promise;
  };
}

export default ProductScansService;
