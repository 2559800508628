import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { CreateLandingPagesStyled } from "./styled";
import { Typography, Radio, Button, message, Input, Select } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { observable, action, computed, runInAction } from "mobx";
import { observer, inject } from "mobx-react";
import LandingPagesStore from "../../../../stores/landing-pages/landing-page.store";
import CategoriesStore from "../../../../stores/categories/category.store";
import LandingPage from "../../../../stores/landing-pages/landing-page.model";
import LandingPagesDetails from "./components/LandingPagesDetails/LandingPagesDetails";
import RedirectUrl from "./components/RedirectUrl/RedirectUrl";
import Form, { FormInstance } from "antd/lib/form";
import {
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  PlusOutlined,
  SaveOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Option, OptGroup } = Select;

interface Props extends RouteComponentProps<any> {
  landingPagesStore?: LandingPagesStore;
  categoriesStore?: CategoriesStore;
}

@inject("landingPagesStore", "categoriesStore")
@observer
class CreateLandingPages extends React.PureComponent<Props, any> {
  @observable loading: boolean = false;
  @observable data: any = {};
  @observable method: "Generate from scratch" | "Re-direct to existing page" =
    "Generate from scratch";

  @observable currentStep: number = 1;
  @observable landingPage: LandingPage = this.props.landingPagesStore?.create();
  @observable fetchDataLoading: boolean = false;
  @observable isUpdate: boolean = false;

  methodOptions = ["Generate from scratch", "Re-direct to existing page"];
  colorTitleOptions = ["black", "white"];

  formRef: any = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.isUpdate =
      typeof props.match.params.id !== "undefined" &&
      props.match.params.id !== "create";
  }

  componentDidMount = async () => {
    this.props!.categoriesStore!.fetchLandingPageCategories();

    if (this.isUpdate) {
      this.fetchLandingPage(Number(this.props.match.params.id));
    }
  };

  @computed get isRedirectMethod() {
    return this.method === "Re-direct to existing page";
  }

  @action fetchLandingPage = async (id: number) => {
    try {
      this.fetchDataLoading = true;
      const response = await this.props.landingPagesStore!.fetchOne(id).promise;
      const { status } = response;
      if (status === 200) {
        this.data = await response.json();
        this.formRef?.current?.setFieldsValue(this.data);
        runInAction(() => {
          if (this.data.is_redirect) {
            this.method = "Re-direct to existing page";
          }
          this.landingPage._updateData(this.data);
        });
      }
    } catch (error) {
      console.error(error);
    }

    this.fetchDataLoading = false;
  };

  @action setMethod = (event: RadioChangeEvent) => {
    this.method = event.target.value;
    this.landingPage.is_redirect =
      this.method === "Re-direct to existing page" ? 1 : 0;
  };

  @computed get displayRedirectComponent(): boolean {
    return this.method === "Re-direct to existing page";
  }

  @action onChangeData = (data: any) => {
    // console.log("onChangeData -> before update");
    // console.log(
    //   JSON.parse(
    //     JSON.stringify({
    //       valid_page_qa: this.landingPage.valid_page_qa.length,
    //       inValid_page_qa: this.landingPage.inValid_page_qa.length,
    //     })
    //   )
    // );
    this.landingPage?._updateData(data);
    // console.log("onChangeData -> after update");
    // console.log(
    //   JSON.parse(
    //     JSON.stringify({
    //       valid_page_qa: this.landingPage.valid_page_qa.length,
    //       inValid_page_qa: this.landingPage.inValid_page_qa.length,
    //     })
    //   )
    // );
  };

  save = async () => {
    if (!this.isRedirectMethod && this.currentStep === 1) {
      this.currentStep = 2;
      return;
    }

    console.log("on save");
    console.log(
      JSON.parse(
        JSON.stringify({
          valid_page_qa: this.landingPage.valid_page_qa,
          inValid_page_qa: this.landingPage.inValid_page_qa,
        })
      )
    );
    const userData = {
      id: this.landingPage.id,
      page_name: this.landingPage.page_name,
      page_title: this.landingPage.page_title,
      page_type: this.landingPage.page_type,
      redirect_url: this.landingPage.redirect_url,
      category_id: this.landingPage.category_id,
      valid_page_photo: this.landingPage.valid_page_photo,
      inValid_page_title: this.landingPage.inValid_page_title,
      valid_page_title: this.landingPage.valid_page_title,
      inValid_page_photo: this.landingPage.inValid_page_photo,
      color_title: this.landingPage.color_title,
      valid_page_qa: this.landingPage.valid_page_qa,
      inValid_page_qa: this.landingPage.inValid_page_qa,
      Category: this.landingPage.Category,
      valid_page_sub_title: this.landingPage.valid_page_sub_title,
      inValid_page_sub_title: this.landingPage.inValid_page_sub_title,
      is_redirect: this.landingPage.is_redirect,
    };

    this.loading = true;
    const { promise } = this.props.landingPagesStore!.createLandingPage(
      userData
    );

    const response = await promise;

    if (response.status === 200) {
      message.success({
        content: "Success to create new landing page",
        // duration: 1000000,
        className: "wiser-message landing-page-message",
        icon: <CheckOutlined />,
      });
      this.props.history.push("/landing-pages/");
    } else {
      message.error({
        content: "Something went wrong when creating new landing page.",
        // duration: 1000000,
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }
    this.loading = false;
  };

  @action update = async () => {
    if (this.landingPage) {
      const { promise } = this.landingPage.save();
      const response = await promise;
    }
  };
  changeStep = (stepNumber: number) => {
    this.currentStep = stepNumber;
  };

  render() {
    const cc: any = [...this.props!.categoriesStore!.landingPageCategories];
    const currentCategory = this.props!.categoriesStore!.allItems.find(
      (c: any) => c.id === this.data.category_id
    );
    if (currentCategory) {
      cc.push(currentCategory);
    }

    const {
      categories,
      subCategories,
      products,
    } = this.props!.categoriesStore!.separateCategoryTree(cc);

    return (
      <CreateLandingPagesStyled>
        <Form
          ref={this.formRef}
          name="control-ref"
          onFinish={(values: any) => this.save()}
        >
          <Title level={2}>
            {this.isUpdate ? "Edit" : "Generate New"} Landing Page
          </Title>

          <div className="full flex relative">
            <Form.Item
              label="Page Name"
              name="page_name"
              rules={[{ required: true, message: "Page name is required" }]}
            >
              <Input
                className="show"
                value={this.data.page_name || ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  this.data = { ...this.data, page_name: event.target.value };
                  this.onChangeData({ page_name: event.target.value });
                }}
              />
            </Form.Item>
          </div>

          <div className="marginTop20">
            <Form.Item
              label="Select Product or Category"
              name="category_id"
              rules={[{ required: true, message: "Category  is required" }]}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onSelect={(value: any) =>
                  this.onChangeData({ category_id: value })
                }
                onChange={() => {}}
                onFocus={() => {}}
                onBlur={() => {}}
                onSearch={() => {}}
                value={this.data.category_id || ""}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {/* {this.props!.categoriesStore?.landingPageCategories.map(
                  (category: any) => (
                    <Option value={category.id} key={category.id}>
                      {category.name}
                    </Option>
                  )
                )} */}
                <>
                  {categories.length && (
                    <OptGroup label="Category">
                      {categories
                        .filter((c: any) => c.parent_id === null)
                        .map((row: any) => (
                          <Option value={row.id} key={row.id}>
                            {row.name}
                          </Option>
                        ))}
                    </OptGroup>
                  )}
                  {subCategories.length && (
                    <OptGroup label="Sub Category">
                      {subCategories.map((row: any) => (
                        <Option value={row.id} key={row.id}>
                          {row.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                  {products.length && (
                    <OptGroup label="Products">
                      {products.map((row: any) => (
                        <Option value={row.id} key={row.id}>
                          {row.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </>
              </Select>
            </Form.Item>
          </div>
          {/* <div className="">
            <div className="f21 white">Color title</div>
            <Radio.Group
              options={this.colorTitleOptions}
              onChange={(event: any) => {
                this.landingPage.color_title = event.target.value;
              }}
              value={this.landingPage.color_title}
            />
          </div> */}
          <Title level={3} className="white marginTop20">
            Method
          </Title>

          <div>
            <div className="marginHor20">
              <Radio.Group
                options={this.methodOptions}
                onChange={this.setMethod}
                value={this.method}
              />
            </div>

            <div className="inputs-wrapper paddingHor20">
              {this.displayRedirectComponent ? (
                <RedirectUrl
                  onChangeData={this.onChangeData}
                  landingPage={this.landingPage}
                />
              ) : (
                <LandingPagesDetails
                  onChangeData={this.onChangeData}
                  landingPage={this.landingPage}
                  changeStep={this.changeStep}
                  currentStep={this.currentStep}
                />
              )}
            </div>
            <div className={this.displayRedirectComponent ? "marginHor20" : ""}>
              <Link to="/landing-pages">
                <Button className="br  gray radius marginVer10 lineHeight0">
                  <StopOutlined /> Cancel
                </Button>
              </Link>

              {this.currentStep === 1 && !this.isRedirectMethod ? (
                <Button
                  className="gray radius br-green green b-gray"
                  htmlType="submit"
                  // onClick={() =>
                  //   this.ChangeValidOrInvalidModeLocal("Invalid Scan")
                  // }
                >
                  Next
                </Button>
              ) : (
                <Button
                  className="gray radius br-green green b-gray"
                  htmlType="submit"
                >
                  {this.loading ? (
                    <LoadingOutlined />
                  ) : !this.isUpdate ? (
                    <PlusOutlined />
                  ) : (
                    <SaveOutlined />
                  )}
                  {this.isUpdate ? "Save" : "Create"}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </CreateLandingPagesStyled>
    );
  }
}
export default withRouter(CreateLandingPages);
