import styled from "styled-components";

const ProductsScansStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);

  .details {
    max-width: 1300px;
    margin: 0 auto;
    .label {
      width: 180px;
      font-size: 16px;
    }
  }
  .table_action .ant-divider {
    margin-left: 0 !important;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #63c2de;
  }
`;

export { ProductsScansStyled };
