import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { InfoBoxStyled } from "./styled";
import { inject, observer } from "mobx-react";
import SuperAdminDashboardStore from "../../../../stores/SuperAdminDashboard/SuperAdminDashboard.store";

interface Props extends RouteComponentProps<any> {
  superAdminDashboardStore?: SuperAdminDashboardStore;
}

@inject("superAdminDashboardStore")
@observer
class InfoBox extends React.PureComponent<Props, any> {
  componentDidMount = () => {
    this.props.superAdminDashboardStore!.fetchBoxInfo();
  };
  render() {
    const { superAdminDashboardStore } = this.props;
    return (
      <InfoBoxStyled>
        <div className="grid grid3 gap30">
          <div className="radius flex b-blue padding10 white info-box">
            <div className="textLeft f21 flex1">QRs</div>
            <div className="textRight f35 ">
              {superAdminDashboardStore?.loadingBoxInfo ? (
                <img
                  src="/images/spinners/box-info-spinner-1s-200px.svg"
                  alt="loader"
                />
              ) : (
                superAdminDashboardStore!.boxInfo.qrCount
              )}
            </div>
          </div>
          <div className="radius flex b-green padding10 white info-box">
            <div className="textLeft flex1">
              <div className="f21"> Distributors</div>
              {/* <div className="f12">92, 40% of Total</div> */}
            </div>
            <div className="textRight f35 flex info-box-count">
              {/* <span>
                <input type="range" min="0" max="100" value="50" />
              </span> */}
              <span>
                {superAdminDashboardStore?.loadingBoxInfo ? (
                  <img
                    src="/images/spinners/box-info-spinner-1s-200px.svg"
                    alt="loader"
                  />
                ) : (
                  superAdminDashboardStore!.boxInfo.distributorsCount
                )}
              </span>
            </div>
          </div>
          <div className="radius flex b-red padding10 white info-box">
            <div className="textLeft flex1">
              <div className="f21"> Categories</div>
              {/* <div className="f12">92, 40% of Total</div> */}
            </div>
            <div className="textRight f35 flex info-box-count">
              {/* <span>
                <input type="range" min="0" max="100" value="50" />
              </span> */}
              <span>
                {superAdminDashboardStore?.loadingBoxInfo ? (
                  <img
                    src="/images/spinners/box-info-spinner-1s-200px.svg"
                    alt="loader"
                  />
                ) : (
                  superAdminDashboardStore!.boxInfo.categoriesCount
                )}
              </span>
            </div>
          </div>
          <div className="radius flex b-yellow padding10 white info-box">
            <div className="textLeft flex1">
              <div className="f21"> Sub Categories</div>
              {/* <div className="f12">92, 40% of Total</div> */}
            </div>
            <div className="textRight f35 flex info-box-count">
              {/* <span>
                <input type="range" min="0" max="100" value="50" />
              </span> */}
              <span>
                {superAdminDashboardStore?.loadingBoxInfo ? (
                  <img
                    src="/images/spinners/box-info-spinner-1s-200px.svg"
                    alt="loader"
                  />
                ) : (
                  superAdminDashboardStore!.boxInfo.subCategoriesCount
                )}
              </span>
            </div>
          </div>
          <div className="radius flex b-blue padding10 white info-box">
            <div className="textLeft f21 flex1">Landing pages</div>
            <div className="textRight f35 ">
              {superAdminDashboardStore?.loadingBoxInfo ? (
                <img
                  src="/images/spinners/box-info-spinner-1s-200px.svg"
                  alt="loader"
                />
              ) : (
                superAdminDashboardStore!.boxInfo.landingPagesCount
              )}
            </div>
          </div>
          <div className="radius flex b-blue padding10 white info-box">
            <div className="textLeft f21 flex1">Subscribers</div>
            <div className="textRight f35 ">
              {superAdminDashboardStore?.loadingBoxInfo ? (
                <img
                  src="/images/spinners/box-info-spinner-1s-200px.svg"
                  alt="loader"
                />
              ) : (
                superAdminDashboardStore!.boxInfo.subscribersCount
              )}
            </div>
          </div>
        </div>
      </InfoBoxStyled>
    );
  }
}
export default withRouter(InfoBox);
