import { Button, Modal } from "antd";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { DeleteableModalStyled } from "./styled";

interface Props {
  isConnected: boolean;
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  itemName: string;
  connectedItemsNames: string[];
}

class DeleteableModal extends React.PureComponent<Props, any> {
  @observable loading: boolean = false;

  onDelete = async () => {
    try {
      this.loading = true;
      await this.props.onOk();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  };
  render() {
    return (
      <DeleteableModalStyled>
        <Modal
          footer={null}
          visible={this.props.visible}
          afterClose={this.props.onCancel}
          className="are_you_sure textCenter padding30"
        >
          <div className="f35">CAUTION!</div>

          <div className="f27">
            {this.props.isConnected && (
              <>
                Deleting this {this.props.itemName} will delete all{" "}
                {this.props.connectedItemsNames.join(", ")} saved under it.
                <br />
                <br />
              </>
            )}
            Deleted data is lost forever and cannot be restored!
            <br />
            <br />
            ARE YOU SURE YOU WANT TO DELETE?
          </div>

          <br />

          <div className="flex justify-center">
            <Button
              type="primary"
              size="large"
              className="br-grayM transparent white radius"
              loading={this.loading}
              onClick={this.onDelete}
            >
              Yes, Delete
            </Button>

            <Button
              type="primary"
              size="large"
              className="br-grayM transparent white radius"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </DeleteableModalStyled>
    );
  }
}
export default observer(DeleteableModal);
