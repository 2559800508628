import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { UsersStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import {
  Button,
  AutoComplete,
  Input,
  Divider,
  Avatar,
  Tag,
  message,
  Tooltip,
} from "antd";
import { inject, observer } from "mobx-react";
import { observable, computed, action } from "mobx";
import debounce from "../../utils/debounce";
import UsersStore from "../../stores/users/user.store";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import User from "../../stores/users/user.model";
import { TableActionsCellStyled } from "../../styled";
import FilterPage from "../../components/FilterPage/FilterPage";
import AuthStore from "../../stores/auth/auth.store";

interface Props extends RouteComponentProps<any> {
  usersStore?: UsersStore;
  authStore?: AuthStore;
}

@inject("usersStore", "authStore")
@observer
class Users extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable searchLoading: boolean = false;

  abortRequest: Function | undefined;

  @action toggleActive = async (record: User) => {
    try {
      if (record.loading) return;
      record.loading = true;
      this.loading = true;
      record.status = record.status === 0 ? 1 : 0;
      await record.save().promise;
      // (await this.props.usersStore!.fetchPage({})).promise;
      // if (response.status === 200) {
      //   message.info("Success update user");
      // } else {
      //   message.error("Something went wrong when update user.");
      // }
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    record.loading = false;
  };
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name: any, record: any) => (
        <div className="flex">
          <Avatar src={record.profile_img || "/images/Avatar.svg"} />
          <div className="margin5">{name}</div>
        </div>
      ),
      key: "name",
    },
    {
      title: "role",
      dataIndex: "role",
      key: "role",
    },

    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "password",
      dataIndex: "password",
      width: "15%",
      render: (text: string, record: any) => <div>***********</div>,
      key: "password",
    },

    {
      title: "status",
      dataIndex: "status",
      render: (status: number, record: User) =>
        this.loading && record.loading ? (
          <LoadingOutlined />
        ) : (
          <div>
            {status ? (
              <Tag color="green" onClick={() => this.toggleActive(record)}>
                active
              </Tag>
            ) : (
              <Tag color="red" onClick={() => this.toggleActive(record)}>
                disabled
              </Tag>
            )}
          </div>
        ),
      key: "status",
    },

    {
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      render: (text: string, record: any) => (
        <TableActionsCellStyled>
          <Tooltip placement="bottom" title={"Edit"}>
            <Link to={`/users/${record.id}/edit/`}>
              <img src="/images/action/cil-pencil.svg" alt="123" />
            </Link>
          </Tooltip>
          <Divider type="vertical" className={"b-grayM2"} />

          <Tooltip placement="bottom" title={"Delete"}>
            <img
              src="/images/action/cil-trash.svg"
              alt="123"
              className={"action-icon"}
              onClick={async () => {
                this.props?.usersStore?.openDeleteItem();
                this.props!.usersStore!.selectedRecord = record;
              }}
            />
          </Tooltip>
        </TableActionsCellStyled>
      ),
      key: "action",
    },
  ];

  componentDidMount = async () => {
    try {
      await this.props.usersStore?.fetchPage({ page: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { usersStore } = this.props;

    await usersStore!.fetchPage({ page, pageSize, filter: usersStore!.filter });
  };

  @action
  setWidth = (width: number) => {
    this._width = width;
  };

  @computed
  get width() {
    return `${this._width}px`;
  }

  onSearchDebounce = debounce(this.props!.usersStore!.onSearch);

  render() {
    let users = this.props?.usersStore?.currentPage.filter(
      (user: any) => user.email !== this.props!.authStore!.user?.email
    );
    return (
      <UsersStyled>
        {this.loading}
        <Header
          title={"Users"}
          rightMenu={
            <div className="flex flexVerCenter right-header">
              <Link to="/users/create">
                <Button
                  className="br br-red red b-gray radius marginVer10 lineHeight0"
                  size="large"
                >
                  + New User
                </Button>
              </Link>

              <FilterPage
                color="#ff8585"
                store={this.props!.usersStore}
                filters={["status"]}
              />
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                className={"br-gray2 radius"}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    this.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          loading={this.props?.usersStore?.fetchPageLoading}
          dataSource={users?.map((row: any) => {
            row.key = row.id;
            return row;
          })}
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.usersStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.usersStore!.changeUsersStatus(action, rowsIds);
          }}
          // handleExportData={() => {
          //   let fields = ["profile_img", "name", "role", "email", "status"];
          //   let rows = this.props!.usersStore!.selectedRows;

          //   this.props!.usersStore!.downloadCsv(fields, rows, "users");
          // }}
          disableMultiSelectAction={true}
          handleExportData={() => {
            let rows = this.props!.usersStore!.selectedRows.map((row: any) => ({
              name: row.name,
              role: row.role,
              email: row.email,
              status: row.status ? "Active" : "Inactive",
            }));
            this.props!.usersStore!.downloadCsv(rows, "users");
          }}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.usersStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.usersStore!.selectedRows = selectedRows;
          }}
          total={this.props?.usersStore?.total || 0}
          pageSize={this.props?.usersStore?.pageSize || 0}
          currentPageNumber={this.props?.usersStore?.currentPageNumber || 0}
          onPageSettingsChange={this.onPageSettingsChange}
        />

        <ConfirmPopup
          visible={this.props?.usersStore?.visible}
          handledeleteItem={this.props?.usersStore?.deleteItem}
          handleCancelDeleteItem={this.props?.usersStore?.cancelDeleteItem}
        />
      </UsersStyled>
    );
  }
}
export default withRouter(Users);
