import React from "react";
import "./App.scss";
import { Avatar, Layout } from "antd";
import styled from "styled-components";
import { Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import Sidebar from "./components/Sidebar/Sidebar";
import PagesRouter from "./pages/PagesRouter/PagesRouter";
import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs";
import { Provider, observer, inject } from "mobx-react";
import AlertStore from "./stores/alerts/alert.store";
import AlertService from "./stores/alerts/alert.service";
import QrCodesStore from "./stores/qr-codes/qr-codes.store";
import QrCodesService from "./stores/qr-codes/qr-codes.service";
import CategoriesStore from "./stores/categories/category.store";
import CategoriesService from "./stores/categories/category.service";
import LandingPagesStore from "./stores/landing-pages/landing-page.store";
import LandingPagesService from "./stores/landing-pages/landing-page.service";
import LeadsStore from "./stores/leads/lead.store";
import LeadsPagesService from "./stores/leads/lead.service";
import DistributorsStore from "./stores/distributors/distributor.store";
import DistributorsService from "./stores/distributors/distributor.service";
import ProductScansStore from "./stores/product-scans/product-scan.store";
import ProductScansService from "./stores/product-scans/product-scan.service";
import UsersStore from "./stores/users/user.store";
import UsersService from "./stores/users/user.service";
import { Route } from "react-router-dom";
import Scan from "./pages/Scan/Scan";
import SubscriptionsStore from "./stores/subscriptions/subscription.store";
import SubscriptionsService from "./stores/subscriptions/subscription.service";
import ActivitiesStore from "./stores/Activities/Activities.store";
import ActivitiesService from "./stores/Activities/Activities.service";
import Login from "./pages/Auth/Login/Login";
import ForgotPass from "./pages/Auth/ForgotPass/ForgotPass";
import PublicOnlyRoute from "./components/Auth/PublicOnlyRoute";
import AuthStore from "./stores/auth/auth.store";
import AuthService from "./stores/auth/auth.service";
import PrivateRoute from "./components/Auth/PrivateRoute";
import AlertSettingsService from "./stores/AlertSettings/AlertSetting.service";
import AlertSettingsStore from "./stores/AlertSettings/AlertSetting.store";
import DashboardStore from "./stores/dashboard/dashboard.store";
import DashboardService from "./stores/dashboard/dashboard.service";
import ResetPass from "./pages/Auth/ResetPass/ResetPass";
import UiStore from "./stores/ui/ui.store";
import SuperAdminDashboardStore from "./stores/SuperAdminDashboard/SuperAdminDashboard.store";
import SuperAdminDashboardService from "./stores/SuperAdminDashboard/SuperAdminDashboard.service";
import AppLoader from "./components/AppLoader/AppLoader";

const apiUrl = process.env.REACT_APP_API_URL;

const usersStore = new UsersStore(new UsersService(apiUrl));
const authStore = new AuthStore(new AuthService(apiUrl), usersStore);
const dashboardStore = new DashboardStore(new DashboardService(apiUrl));
const superAdminDashboardStore = new SuperAdminDashboardStore(
  new SuperAdminDashboardService(apiUrl)
);
const distributorsStore = new DistributorsStore(
  new DistributorsService(apiUrl),
  authStore
);
const qrCodeStore = new QrCodesStore(new QrCodesService(apiUrl), authStore);
const alertStore = new AlertStore(new AlertService(apiUrl));
const landingPagesStore = new LandingPagesStore(
  new LandingPagesService(apiUrl)
);
const alertSettingStore = new AlertSettingsStore(
  new AlertSettingsService(apiUrl)
);
const leadsStore = new LeadsStore(new LeadsPagesService(apiUrl));
const productScansStore = new ProductScansStore(
  new ProductScansService(apiUrl)
);
const subscriptionsStore = new SubscriptionsStore(
  new SubscriptionsService(apiUrl)
);
const activitiesStore = new ActivitiesStore(new ActivitiesService(apiUrl));

const categoriesStore = new CategoriesStore(
  new CategoriesService(apiUrl),
  authStore
);
const uiStore = new UiStore();

const stores = {
  superAdminDashboardStore,
  dashboardStore,
  alertSettingStore,
  alertStore,
  distributorsStore,
  qrCodeStore,
  categoriesStore,
  landingPagesStore,
  leadsStore,
  productScansStore,
  usersStore,
  authStore,
  subscriptionsStore,
  activitiesStore,
  uiStore,
};
const history = createBrowserHistory();
const { Header, Content } = Layout;
const AppContainer = styled.div``;

const Comp = inject("authStore")(
  observer(({ authStore }: any) => {
    return (
      <AppContainer>
        <Layout>
          <Sidebar />
          <Layout style={{ background: "#2f353a" }}>
            <Header className="main-header">
              <Breadcrumbs />
              {typeof authStore.user?.subscription?.logo === "string" &&
              !authStore.user?.subscription?.logo.includes("object") &&
              !authStore.user?.superAdmin ? (
                <img
                  className="logo-company"
                  src={authStore.user?.subscription?.logo}
                  alt=" "
                />
              ) : (
                <Avatar src={"/images/Avatar.svg"} />
              )}
            </Header>

            <Content
              style={{
                // margin: 20,
                // background: "#3b4149",
                padding: "20px",
              }}
            >
              <PagesRouter />
            </Content>
          </Layout>
        </Layout>
      </AppContainer>
    );
  })
);

const AppWrapper = inject("authStore")(
  observer(({ authStore }: any) => {
    return authStore.init ? (
      <Switch>
        <PublicOnlyRoute path={`/auth/login`} component={Login} />
        <PublicOnlyRoute
          path={`/auth/forgot-password`}
          component={ForgotPass}
        />
        <PublicOnlyRoute
          path={`/auth/reset-password/:id`}
          component={ResetPass}
        />

        <Route path={`/scan/:group_id/:qr_id/`} component={Scan} exact />

        <PrivateRoute path={"/"} component={Comp} />
      </Switch>
    ) : (
      <AppLoader />
    );
  })
);

function App() {
  return (
    <Provider {...stores}>
      <Router history={history}>
        <AppWrapper />
      </Router>
    </Provider>
  );
}

export default App;
