import styled from "styled-components";

const AlertsSettingsStyled = styled.div`
  max-width: 1200px;
  background: #3b4149;
  padding: 20px;

  .ant-btn.red-button {
    color: #ce3b41 !important;
    border-color: #ce3b41 !important;
    svg {
      fill: #ce3b41;
    }
  }
`;

export { AlertsSettingsStyled };
