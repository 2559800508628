import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { ISubscription } from "./subscription.interface";
import { observable, computed } from "mobx";

class Subscription extends BaseModel implements ISubscription {
  @observable name: string = "";
  @observable contact_name: string = "";
  @observable adress: string = "";
  @observable start_date: string = "";
  @observable end_date: string = "";
  @observable profession: string = "";
  @observable territory: string = "";
  @observable phone: string = "";
  @observable email: string | null = null;
  @observable password: string = "";
  @observable membership: string = "";
  @observable logo: any = {};
  @observable status: number = 1;
  @observable subscriber_email_login: string = "";
  @observable subscription_period: any = [];
  @observable createdAt: string = "";
  @observable contact_email: string = "";

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "subscription";
  }
  responseModelsKey(): string {
    return "subscriptions";
  }

  get url(): string {
    return "/subscriptions";
  }

  @computed get toJson(): { [key: string]: any } {
    return this._toJson({
      name: this.name,
      contact_name: this.contact_name,
      adress: this.adress,
      start_date: this.start_date,
      end_date: this.end_date,
      profession: this.profession,
      territory: this.territory,
      phone: this.phone,
      email: this.email,
      password: this.password,
      membership: this.membership,
      status: this.status,
    });
  }
}

export default Subscription;
