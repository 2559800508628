
	import BaseService from "../base.service";

	class SuperAdminDashboardService extends BaseService {
		constructor(baseUrl: string = "") {
		super(baseUrl);
		}
	}
	
	export default SuperAdminDashboardService;
		
	