import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { ILandingPage } from "./landing-page.interface";
import { observable, computed } from "mobx";

class LandingPage extends BaseModel implements ILandingPage {
  @observable id = null;
  @observable key: string = "asd";
  @observable page_name: string = "";
  @observable page_title: string = "";
  @observable page_type: string = "";
  @observable redirect_url: string = "";
  @observable category_id: number = 0;
  @observable valid_page_title: string = "";
  @observable valid_page_sub_title: string = "";
  @observable valid_page_photo: string = "";
  @observable valid_page_qa: any[] = [];
  @observable inValid_page_title: string = "";
  @observable inValid_page_sub_title: string = "";
  @observable inValid_page_photo: string = "";
  @observable color_title: string = "";
  @observable inValid_page_qa: any[] = [];
  @observable createdAt: any = "";
  @observable Category: any = {};
  @observable is_redirect: number = 0;
  @observable category_name: string = "";
  @observable sub_category_name: string = "";
  @observable product_name: string = "";
  @observable product: any = {};
  @observable category: any = {};
  @observable subCategory: any = {};

  model: any;
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "landingPage";
  }

  responseModelsKey(): string {
    return "landing-pages";
  }

  get url(): string {
    return "/landing-pages";
  }

  @computed
  get toJson(): { [key: string]: any } {
    return this._toJson({
      id: this.id,
      key: this.key,
      page_name: this.page_name,
      page_title: this.page_title,
      page_type: this.page_type,
      redirect_url: this.redirect_url,
      category_id: this.category_id,
      valid_page_title: this.valid_page_title,
      valid_page_sub_title: this.valid_page_sub_title,
      inValid_page_sub_title: this.inValid_page_sub_title,
      valid_page_photo: this.valid_page_photo,
      valid_page_qa: this.valid_page_qa,
      inValid_page_photo: this.inValid_page_photo,
      inValid_page_qa: this.inValid_page_qa,
      color_title: this.color_title,
    });
  }
}

export default LandingPage;
