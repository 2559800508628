/* eslint-disable @typescript-eslint/no-useless-constructor */
import LandingPagesService from "./landing-page.service";
import LandingPages from "./landing-page.model";
import BaseStore from "../base.store";
import PageStore from "../page.store";
import { OptionsRequest } from "../../services/base.http.service";

class LandingPagesStore extends PageStore {
  constructor(landingPagesService: LandingPagesService) {
    super(landingPagesService);
  }

  url(): string {
    return "/landing-pages";
  }

  get model(): any {
    return LandingPages;
  }

  createLandingPage = (landingPage: any): OptionsRequest => {
    let formData = new FormData();

    for (var key in landingPage) {
      if (Array.isArray(landingPage[key])) {
        for (var i = 0; i < landingPage[key].length; i++) {
          formData.append(`${key}[]`, landingPage[key][i]);
        }
      } else {
        formData.append(key, landingPage[key]);
      }
    }

    return this.apiService.formData(
      `/landing-pages/${landingPage.id ? landingPage.id : ""}`,
      formData,
      { method: landingPage.id ? "PUT" : "post" }
    );
  };
}

export default LandingPagesStore;
