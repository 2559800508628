import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { HeaderStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  title: string;
  rightMenu?: any;
}

class Header extends React.PureComponent<Props, any> {
  render() {
    let { title, rightMenu } = this.props;
    return (
      <HeaderStyled className="marginBottom20">
        <div className="flex">
          <div className="f25 white flex1">
            <div className="f25 white ">{title}</div>
          </div>
          <div className="f25 white">{rightMenu}</div>
        </div>
      </HeaderStyled>
    );
  }
}
export default withRouter(Header);
