import styled from "styled-components";

const ActivityLogStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  min-height: calc(100vh - 93px);

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover,
  .ant-input-number-input:not([disabled]):hover,
  .ant-btn:not([disabled]):hover {
    border-color: #ce7274 !important;
  }
`;

export { ActivityLogStyled };
