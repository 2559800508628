import styled from "styled-components";

const DistributorDetailsStyled = styled.div`
  .ant-input,
  .ant-input-number {
    width: 100% !important;
  }

  #react-google-places-autocomplete-input {
    border: 1px solid #818286 !important;
    background: #282d32;

    border-radius: 5px !important;
    height: 35px !important;
  }

  .ant-radio-inner::after {
    background: #ffc107 !important;
  }

  .autocomplete-dropdown-container {
    border: 1px solid #818286 !important;
    border-top: none !important;
  }

  .autocomplete-dropdown-container * {
    background: #282d32 !important;
    color: #fff !important;
  }

  .autocomplete-dropdown-container span {
    padding-left: 10px !important;
    margin-top: 10px !important;
  }
  .ant-row.ant-form-item {
    display: block !important;
    margin-right: 0 !important;
  }
`;

export { DistributorDetailsStyled };
