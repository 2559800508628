import React from "react";
import { observer } from "mobx-react";
import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip, Divider } from "antd";
import { TableActionsCellStyled } from "../../../styled";
import { Link } from "react-router-dom";

const ActionsCell = observer(({ record, handleDelete }: any) => (
  <TableActionsCellStyled>
    <Tooltip placement="bottom" title={"Edit"}>
      <Link to={`/subscribers/${record.id}/edit/`}>
        <img src="/images/action/cil-pencil.svg" alt="edit-model" />
      </Link>
    </Tooltip>

    <Divider type="vertical" className={"b-grayM2"} />

    <Tooltip placement="bottom" title={"Download"}>
      <a href={record.file} download>
        <img src="/images/action/cil-data-transfer-down.svg" alt="a" />
      </a>
    </Tooltip>

    <Divider type="vertical" className={"b-grayM2"} />

    <Tooltip placement="bottom" title={"Delete"}>
      {record.deleteLoading ? (
        <LoadingOutlined />
      ) : (
        <img
          src="/images/action/cil-trash.svg"
          alt="cil-trash.svg"
          className={"action-icon"}
          onClick={() => handleDelete(record)}
        />
      )}
    </Tooltip>
  </TableActionsCellStyled>
));

export default ActionsCell;
