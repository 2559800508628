import styled from "styled-components";

const DashboardCustomersStyled = styled.div`
  display: grid;
  gap: 30px;

  .ant-layout-content {
    background: transparent !important;
  }
`;

export { DashboardCustomersStyled };
