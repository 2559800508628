import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Redirect,
  Link,
} from "react-router-dom";
import { CreateProductStyled } from "./styled";
import { Typography, Radio, Button, message, Form } from "antd";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import { observable, action, computed } from "mobx";
import Category from "../../../../stores/categories/category.model";
import CategoriesStore from "../../../../stores/categories/category.store";
import { RadioChangeEvent } from "antd/lib/radio";
import { observer, inject } from "mobx-react";
import { FormInstance } from "antd/lib/form";
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
const { Title } = Typography;
interface Props extends RouteComponentProps<any> {
  categoriesStore: CategoriesStore;
}

@inject("categoriesStore")
@observer
class CreateProduct extends React.PureComponent<Props, any> {
  @observable data: any = {};
  @observable method: string = "Manually";
  @observable isUpdate: boolean = false;
  @observable loading: boolean = false;
  @observable category: Category = this.props.categoriesStore.create({
    child_number: 3,
  });
  @observable uploaded: boolean = false;

  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.isUpdate =
      typeof props.match.params.id !== "undefined" &&
      props.match.params.id !== "create";
  }

  @action onChangeData = (data: any) => {
    this.category?._updateData({ ...this.data, ...data });
  };

  @action save = async () => {
    this.loading = true;
    const { promise } = this.category.save();
    const response = await promise;
    if (response.status === 200) {
      const data = await response.clone().json();
      this.category._updateData(data);
      if (!this.isUpdate) {
        this.props.categoriesStore.allItems.push(this.category);
      } else {
        this.props.categoriesStore.onUpdateCategory(this.category.toJson);
      }
      this.props.history.push("/products/");
      message.success({
        content: `Successfully to ${
          this.isUpdate ? "update" : "create"
        } product .`,
        className: "wiser-message landing-page-message",
        icon: <CheckOutlined color="#ffd147" />,
      });
    } else {
      message.error({
        content: "Something went wrong when create new product.",
        // duration: 1000000,
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }
    this.loading = false;
  };

  @computed get disableActionButton() {
    return this.category.name.length < 1 || this.category.parent_id === null;
  }

  render() {
    return (
      <CreateProductStyled>
        <Form ref={this.formRef} onFinish={this.save}>
          <Title level={2}>{this.isUpdate ? "Edit" : "Add New"} Product</Title>

          <div>
            <div className="inputs-wrapper paddingHor20">
              <ProductDetails
                category={this.category}
                onChangeData={this.onChangeData}
                isUpdate={this.isUpdate}
                formRef={this.formRef}
              />
            </div>
            <div>
              <Link to="/products">
                <Button className="br  gray radius marginVer10 lineHeight0">
                  <StopOutlined /> Cancel
                </Button>
              </Link>
              <Button
                disabled={this.loading || this.disableActionButton}
                htmlType="submit"
                type="primary"
                className="br-yellow yellow b-gray"
                // onClick={this.isUpdate ? this.update : this.create}
              >
                {this.loading ? (
                  <LoadingOutlined />
                ) : !this.isUpdate ? (
                  <PlusOutlined />
                ) : (
                  <SaveOutlined />
                )}
                Save
              </Button>
            </div>
          </div>
        </Form>
      </CreateProductStyled>
    );
  }
}
export default withRouter(CreateProduct);
