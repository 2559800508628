import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Input, Select, Form } from "antd";
import { action, observable } from "mobx";
import { observer, inject } from "mobx-react";
import { DistributorDetailsStyled } from "./styled";
import Countries from "../../../../../../services/countries";
import DistributorsStore from "../../../../../../stores/distributors/distributor.store";
import Distributor from "../../../../../../stores/distributors/distributor.model";
import CategoriesStore from "../../../../../../stores/categories/category.store";
import Validation from "../../../../../../services/validation";

const { Option } = Select;

const children: any = [];
for (let i = 10; i < Countries.length; i++) {
  children.push(<Option value={Countries[i]}>{Countries[i]}</Option>);
}

interface Props extends RouteComponentProps<any> {
  distributorsStore?: DistributorsStore;
  categoriesStore?: CategoriesStore;
  isUpdate: boolean;
  distributor: Distributor;
  formRef: any;
}

@inject("distributorsStore", "categoriesStore")
@observer
class DistributorDetails extends React.PureComponent<Props, any> {
  @observable address: string = "";
  @observable addresses_list: any = [];

  @action fetchCategory = async (id: number) => {
    const { promise } = this.props.distributorsStore!.fetchOne(id);
    const response: any = await promise;
    const { status } = response;

    if (status === 200) {
      const data = await response.json();
      this.props.distributor._updateData(data);
      this.props.formRef?.current?.setFieldsValue(data);
    }
  };

  componentDidMount = async () => {
    try {
      if (this.props.isUpdate) {
        this.fetchCategory(Number(this.props.match.params.id));
      }
      await this.props!.categoriesStore!.fetch();
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (address: any) => {
    this.address = address;
  };

  render() {
    return (
      <DistributorDetailsStyled>
        <div className="marginHor20">
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input
              value={this.props.distributor.name || ""}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.props.distributor._updateData({
                  name: event.target.value,
                });
              }}
            />
          </Form.Item>
        </div>
        <div className="marginHor20">
          <Form.Item
            label="Phone Number"
            name="phone_number"
            rules={[
              { required: true, message: "Product number is required" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && Validation.distributorPhoneNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Only numbers and , + -");
                },
              }),
            ]}
          >
            <Input
              value={this.props.distributor.phone_number || ""}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.props.distributor._updateData({
                  phone_number: event.target.value,
                });
              }}
            />
          </Form.Item>
        </div>
        <div className="marginHor20">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && Validation.validateEmail(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please add a valid email");
                },
              }),
            ]}
          >
            <Input
              value={this.props.distributor.email || ""}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.props.distributor._updateData({
                  email: event.target.value,
                });
              }}
            />
          </Form.Item>
        </div>

        <div className="marginHor20">
          <Form.Item
            label="Territory"
            name="territories"
            rules={[{ required: true, message: "Territory is required" }]}
          >
            <Select
              mode="multiple"
              onChange={(values: any) => {
                this.props.distributor._updateData({
                  territories: values,
                });
              }}
              style={{ width: "100%" }}
            >
              {children}
            </Select>
          </Form.Item>
        </div>
      </DistributorDetailsStyled>
    );
  }
}
export default withRouter(DistributorDetails);
