import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { SubscribersStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import { Button, AutoComplete, Input, Divider, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import { observable, computed, action } from "mobx";
import moment from "moment";
import debounce from "../../utils/debounce";
import SubscriptionsStore from "../../stores/subscriptions/subscription.store";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";

import Subscription from "../../stores/subscriptions/subscription.model";
import FilterPage from "../../components/FilterPage/FilterPage";

import ActionsCell from "./components/ActionsCell";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import { TableActionsCellStyled } from "../../styled";

interface Props extends RouteComponentProps<any> {
  subscriptionsStore: SubscriptionsStore;
}

@inject("subscriptionsStore")
@observer
class Subscribers extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;
  @observable searchLoading: boolean = false;

  abortRequest: Function | undefined;

  toggleActive = async (record: Subscription) => {
    try {
      if (record.loading) return;
      record.loading = true;
      record.status = record.status === 0 ? 1 : 0;
      await record.save().promise;
      // await (await this.props.subscriptionsStore!.fetchPage({})).promise;
      // if (response.status === 200) {
      //   message.info("Success update subscriber");
      // } else {
      //   message.error("Something went wrong when subscriber.");
      // }
    } catch (error) {
      console.error(error);
    }
    record.loading = false;
  };

  columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Territory",
      dataIndex: "territory",
      key: "territory",
    },
    {
      title: "Contact",
      dataIndex: "contact_name",
      render: (contact_name: any) => <div>{contact_name}</div>,
      key: "contact_name",
    },
    {
      colSpan: 2,
      className: "textLeft",
      title: "Subscription Period",
      dataIndex: "subscription_period",
      render: (subscription_period: any, record: any) => {
        let startDate = new Date(record.start_date),
          endDate = new Date(record.end_date),
          now = new Date(),
          expiresSoonDate = moment().add(60, "days").toDate();
        let valid_subscription = now > startDate && now < endDate;
        let expiresSoon = expiresSoonDate > endDate;

        return (
          <div>
            {moment(record.start_date).format("DD/MM/YY")} -
            {moment(record.end_date).format("DD/MM/YY")}
          </div>
        );
      },
      key: "subscription_period",
    },

    {
      title: "Status",
      dataIndex: "subscription_period",
      width: "15%",
      render: (subscription_period: any, record: any) => {
        let startDate = new Date(record.start_date),
          endDate = new Date(record.end_date),
          now = new Date(),
          expiresSoonDate = moment().add(60, "days").toDate();
        let valid_subscription = now > startDate && now < endDate;
        let expiresSoon = expiresSoonDate > endDate;

        return (
          <div>
            <span
              className={
                "period_status " +
                (!valid_subscription
                  ? "b-red"
                  : expiresSoon
                  ? "b-orange"
                  : "b-green")
              }
            ></span>
          </div>
        );
      },
      key: "status",
    },

    {
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      render: (text: string, record: any) => (
        <ActionsCell handleDelete={this.handleDelete} record={record} />
      ),
      key: "action",
    },
  ];

  handleDelete = async (record: any) => {
    this.props?.subscriptionsStore?.openDeleteItem();
    this.props!.subscriptionsStore!.selectedRecord = record;
  };

  componentDidMount = async () => {
    try {
      await this.props.subscriptionsStore?.fetchPage({ page: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { subscriptionsStore } = this.props;
    await subscriptionsStore!.fetchPage({
      page,
      pageSize,
      filter: subscriptionsStore!.filter,
    });
  };

  @action
  setWidth = (width: number) => {
    this._width = width;
  };

  @computed
  get width() {
    return `${this._width}px`;
  }

  onSearchDebounce = debounce(async (value: string) => {
    this.props.subscriptionsStore!.searchLoading = true;
    this.props.subscriptionsStore!.onFilterChanged({
      ...this.props!.subscriptionsStore!.filter,
      name: value,
    });
  });

  deleteItem = async () => {
    await this.props?.subscriptionsStore?.deleteItem();
  };

  handleExportData = () => {
    const store = this.props!.subscriptionsStore;
    let rows = store.selectedRows.map((row: Subscription) => {
      const toJson = row.dataToExport;
      const data: any = {};
      const excludeFields = [
        "group_id",
        "scanValid",
        "createdAt",
        "end_date",
        "start_date",
        "profession",
        "status",
        "password",
      ];
      for (let [key, value] of Object.entries(toJson)) {
        if (!excludeFields.includes(key)) {
          data[`${capitalizeFirstLetter(key).split("_").join(" ")}`] = value;
        }
      }
      data.Status = row.status ? "active" : "disable";
      data["Start date"] = moment(row.end_date).format("YYYY-MM-DD HH:mm:ss");
      data["End date"] = moment(row.start_date).format("YYYY-MM-DD HH:mm:ss");
      data["Created at"] = moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss");
      return data;
    });
    store.downloadCsv(rows, "leads");
  };

  renderExpandIcon = ({ expanded, onExpand, record }: any) => {
    return expanded ? (
      <TableActionsCellStyled>
        <Tooltip placement="bottom" title={"Close"}>
          <img
            style={{ maxWidth: 25 }}
            className="pointer"
            src="/images/action/close/Red-Minus.svg"
            alt="cil-link_green.svg"
            onClick={(e) => onExpand(record, e)}
          />
        </Tooltip>
        <Divider type="vertical" className={"b-grayM2"} />
      </TableActionsCellStyled>
    ) : (
      <TableActionsCellStyled>
        <Tooltip placement="bottom" title={"Details"}>
          <img
            style={{ maxWidth: 25 }}
            className="pointer"
            src="/images/action/PlusIcon_red.svg"
            alt="cil-link_green.svg"
            onClick={(e) => onExpand(record, e)}
          />
        </Tooltip>
        <Divider type="vertical" className={"b-grayM2"} />
      </TableActionsCellStyled>
    );
  };

  render() {
    const { subscriptionsStore } = this.props;
    return (
      <SubscribersStyled>
        {/* {this.loading} */}
        <Header
          title={"Subscribers"}
          rightMenu={
            <div className="flex flexVerCenter">
              <Link to="/subscribers/create">
                <Button
                  className="br br-red red b-gray radius marginVer10 lineHeight0"
                  size="large"
                >
                  + New Subscriber
                </Button>
              </Link>
              <FilterPage
                color="#ff8585"
                store={subscriptionsStore}
                filters={["subscription_period", "date_custom"]}
              />
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    subscriptionsStore.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          loading={this.props?.subscriptionsStore?.fetchPageLoading}
          expandIconColumnIndex={6}
          expandIcon={this.renderExpandIcon}
          dataSource={this.props?.subscriptionsStore?.currentPage.map(
            (row: any) => {
              row.key = row.id;
              return row;
            }
          )}
          expandedRowRender={(record: any) => (
            <div className="marginCenter padding20" style={{ width: "400px" }}>
              <div className="flex">
                <div className="f19 bold first">Phone No.</div>
                <div className="f19 ">{record.phone}</div>
              </div>

              <div className="flex">
                <div className="f19 bold first">Email Address</div>
                <div className="f19 ">{record.email}</div>
              </div>
            </div>
          )}
          columns={this.columns}
          handleTableAction={(action: any) => {
            let rows = this.props!.subscriptionsStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props?.subscriptionsStore!.changeUsersStatus(action, rowsIds);
          }}
          handleExportData={this.handleExportData}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props?.subscriptionsStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.subscriptionsStore!.selectedRows = selectedRows;
          }}
          total={this.props?.subscriptionsStore!?.total || 0}
          pageSize={this.props?.subscriptionsStore!?.pageSize || 0}
          currentPageNumber={
            this.props?.subscriptionsStore?.currentPageNumber || 0
          }
          onPageSettingsChange={this.onPageSettingsChange}
        />

        <ConfirmPopup
          visible={this.props?.subscriptionsStore?.visible}
          handledeleteItem={this.deleteItem}
          handleCancelDeleteItem={
            this.props?.subscriptionsStore?.cancelDeleteItem
          }
        />
      </SubscribersStyled>
    );
  }
}
export default withRouter(Subscribers);
