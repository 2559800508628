import styled from "styled-components";

const CreateSubscriberStyled = styled.div`
  max-width: 1180px;
  background: #3b4149;
  padding: 20px;
  .form-row {
    width: 50%;
  }

  #react-google-places-autocomplete-input {
    border: 1px solid #818286 !important;
    border-radius: 5px !important;
    height: 35px !important;
  }

  .ant-avatar-circle {
    min-width: 75px;
  }

  .contact_email_address,
  .phone,
  .contact_name {
    label:after {
      display: none !important;
    }
  }
`;

export { CreateSubscriberStyled };
