import styled from "styled-components";

const CreateUserStyled = styled.div`
  background: #3b4149;
  padding: 20px;

  .ant-select-selector {
    border-radius: 4px !important;
  }

  .filed {
    max-width: 500px;
    width: 100%;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item-label > label::after {
    position: static !important;
    display: inline-block !important;
    margin-right: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    font-family: SimSun, sans-serif !important;
    line-height: 1 !important;

    content: "*" !important;
  }

  .ant-row {
    width: 100%;
  }
`;

export { CreateUserStyled };
