import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IUser } from "./user.interface";
import { observable, computed } from "mobx";

class User extends BaseModel implements IUser {
  @observable file: any = {};
  @observable name: string = "";
  @observable profile_img: string = "";
  @observable email: string = "";
  @observable password: string = "";
  @observable role: string = "";
  @observable permission: string[] = [];
  @observable activities: any[] = [];
  @observable status: number = 1;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  get regularModelKey(): string {
    return "user";
  }

  responseModelsKey(): string {
    return "users";
  }

  get url(): string {
    return "/users";
  }

  @computed
  get toJson(): { [key: string]: any } {
    return this._toJson({
      file: this.file,
      name: this.name,
      username: this.email,
      password: this.password,
      status: this.status,
      role: this.role,
      permission: this.permission,
    });
  }
}

export default User;
