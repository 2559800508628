import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { UploadFileStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  title: string;
  multiple: boolean;
  accept: any;
  collection?: string;
  action?: string;
  maxCount?: number;
  fileList?: any[];
  defaultFileList?: any[];
  disabled?: boolean;
  beforeUpload?: any;
  onChange?: (state: any) => void;
}

class UploadFile extends React.PureComponent<Props, any> {
  render() {
    return (
      <UploadFileStyled>
        <div className="f21 white">{this.props.title}</div>

        <Upload {...this.props}>
          <Button
            size="large"
            className="b-grayM br br-grayM white radius marginTop10"
          >
            <UploadOutlined /> Upload
          </Button>
        </Upload>
      </UploadFileStyled>
    );
  }
}
export default withRouter(UploadFile);
