/* eslint-disable @typescript-eslint/no-useless-constructor */
import SubscriptionsService from "./subscription.service";
import Subscriptions from "./subscription.model";
import BaseStore from "../base.store";
import PageStore from "../page.store";
import { OptionsRequest } from "../../services/base.http.service";

class SubscriptionsStore extends PageStore {
  constructor(subscriptionsService: SubscriptionsService) {
    super(subscriptionsService);
  }

  url(): string {
    return "/subscriptions";
  }

  get model(): any {
    return Subscriptions;
  }

  createSubscription = (subscription: any): OptionsRequest => {
    let formData: any = new FormData();

    for (var key in subscription) {
      formData.append(key, subscription[key]);
    }

    return this.apiService.formData(
      `/subscriptions/${subscription.id ? subscription.id : ""}`,
      formData,
      { method: subscription.id ? "PUT" : "post" }
    );
  };
}

export default SubscriptionsStore;
