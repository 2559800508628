import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { SuperAdminDashboardStyled } from "./styled";
import InfoBox from "./components/InfoBox/InfoBox";

interface Props extends RouteComponentProps<any> {}

class SuperAdminDashboard extends React.PureComponent<Props, any> {
  render() {
    return (
      <SuperAdminDashboardStyled>
        <InfoBox />
      </SuperAdminDashboardStyled>
    );
  }
}
export default withRouter(SuperAdminDashboard);
