import { Button, Tooltip } from "antd";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import DeleteableModal from "./components/DeleteableModal/DeleteableModal";
import NotDeleteabbleModal from "./components/NotDeleteabbleModal/NotDeleteabbleModal";
import { DeleteEntityButtonStyled } from "./styled";

interface Props {
  isDeleteAbleFn: () => Promise<any>;
  onDelete: () => void;
  itemName: string;
  connectedItemsNames: string[];
  className?: string;
  icon: React.ReactNode;
}

class DeleteEntityButton extends React.PureComponent<Props, any> {
  @observable loading: boolean = false;
  /**
   * Model is Connected to another model
   */
  @observable isConnected: boolean = false;
  @observable deleteAbleModalVisible: boolean = false;
  @observable notDeleteAbleModalVisible: boolean = false;

  onClick = async () => {
    try {
      this.loading = true;
      const { isDeleteAble, isConnected } = await this.props.isDeleteAbleFn();

      this.isConnected = isConnected;
      if (isDeleteAble) {
        this.deleteAbleModalVisible = true;
      } else {
        this.notDeleteAbleModalVisible = true;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  };

  onCancel = () => {
    this.deleteAbleModalVisible = false;
    this.notDeleteAbleModalVisible = false;
  };

  render() {
    const { icon, className } = this.props;
    return (
      <DeleteEntityButtonStyled className={className || ""}>
        <Tooltip placement="bottom" title={"Delete"}>
          <Button
            type={"link"}
            icon={icon}
            loading={this.loading}
            onClick={this.onClick}
          />
        </Tooltip>
        <DeleteableModal
          isConnected={this.isConnected}
          visible={this.deleteAbleModalVisible}
          onCancel={this.onCancel}
          onOk={this.props.onDelete}
          connectedItemsNames={this.props.connectedItemsNames}
          itemName={this.props.itemName}
        />
        <NotDeleteabbleModal
          itemName={this.props.itemName}
          visible={this.notDeleteAbleModalVisible}
          onCancel={this.onCancel}
        />
      </DeleteEntityButtonStyled>
    );
  }
}
export default observer(DeleteEntityButton);
