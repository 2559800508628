import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { QrDetailItemStyled } from "./styled";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Select, Button } from "antd";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import UploadFile from "../../../../../../../components/UploadFile/UploadFile";
import csv2array from "../../../../../../../utils/csv2Array";
const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  onRemoveDistributer: (index: number) => void;
  onAddDistributer: (distributerDetails: any) => void;
  distributors: any[];
  item: any;
  displayMinus: boolean;
  displayPlus: boolean;
  index?: number;
  disablePlus?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

@observer
class QrDetailItem extends React.PureComponent<Props, any> {
  @observable quantity: number | null = null;
  @observable distributer_id: number | null = null;
  @observable serieses: any[] = [];

  getData = () => {
    return {
      quantity: this.quantity,
      distributer_id: this.distributer_id,
      serieses: this.serieses,
    };
  };

  @action _onAddDistributer = () => {
    this.props.onAddDistributer(this.getData());
    this.quantity = null;
    this.distributer_id = null;
    this.serieses = [];
  };

  @computed get plusButtonIsDisable() {
    return (
      this.props.disablePlus ||
      this.quantity === null ||
      this.distributer_id === null
    );
  }
  render() {
    const {
      index,
      disabled,
      displayMinus,
      onRemoveDistributer,
      loading,
      item: { quantity, distributer_id } = {},
    } = this.props;
    const isAdd = !displayMinus;
    return (
      <QrDetailItemStyled className="flex">
        <div className="quantity">
          <span className="red marginBottom5"> *</span>
          <span className="f16 white marginBottom5">Set Quantity</span>
          <Input
            disabled={
              loading || this.serieses.length ? true : false || disabled
            }
            value={isAdd ? this.quantity : quantity}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              (this.quantity = Number(event.target.value))
            }
          />
        </div>
        <div className="flex1">
          <div className="flex flex1 flexVerCenter spaceAround">
            <div className="or">OR</div>
            <UploadFile
              disabled={disabled}
              title={"Upload Serial Numbers from file"}
              accept={
                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
              multiple={false}
              action={`${process.env.REACT_APP_API_URL}/qr-codes/import`}
              onChange={(info: any) => {
                if (info.fileList.length) {
                  var reader = new FileReader();
                  reader.onload = () => {
                    function tsvJSON(tsv: any) {
                      var lines = tsv.split("\n");

                      var result = [];

                      var headers = lines[0].split("\t");

                      for (var i = 1; i < lines.length; i++) {
                        var obj: any = {};
                        var currentline = lines[i].split("\t");

                        for (var j = 0; j < headers.length; j++) {
                          obj[headers[j]] = currentline[j];
                        }
                        if (currentline[0].length) result.push(obj);
                      }

                      //return result; //JavaScript object
                      return JSON.stringify(result); //JSON
                    }
                    let seriess: any = JSON.parse(tsvJSON(reader.result));
                    // seriess.shift();
                    this.serieses = seriess.map(
                      (row: any) => Object.values(row)[0]
                    );
                    this.quantity = this.serieses.length;
                  };
                  reader.readAsBinaryString(info.file.originFileObj);
                } else {
                  this.serieses = [];
                }
              }}
            />
          </div>
        </div>
        <div className="flex1">
          <span className="red marginBottom5"> *</span>
          <span className="f16 white marginBottom5">Distributor</span>
          <Select
            disabled={disabled}
            showSearch
            style={{ width: 200 }}
            placeholder=""
            optionFilterProp="children"
            onSelect={(value: string) => (this.distributer_id = Number(value))}
            onFocus={() => {}}
            onBlur={() => {}}
            onSearch={() => {}}
            value={isAdd ? this.distributer_id : distributer_id}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.distributors.map((distributor: any) => (
              <Option value={distributor.id} key={distributor.id}>
                {distributor.name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="flexVerCenter flex plus-minus-wrapper">
          {this.props.displayPlus && (
            <Button
              className="white b-gray br-green"
              onClick={
                !this.plusButtonIsDisable ? this._onAddDistributer : () => {}
              }
            >
              <PlusOutlined /> add
            </Button>
          )}
          {this.props.displayMinus && (
            <span className="green">
              <CheckOutlined
                className="ok"
                onClick={() => onRemoveDistributer(index || 0)}
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  fill: "#5edc89",
                }}
              />
              Saved
            </span>
          )}
        </div>
      </QrDetailItemStyled>
    );
  }
}
export default withRouter(QrDetailItem);
