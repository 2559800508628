import styled from "styled-components";

const FilterDashboardDataStyled = styled.div`
  background: #3b4148;
  padding: 20px;
  margin: -19px -19px 0px -18px;
  .filters {
    button {
      height: 40px;
      text-transform: capitalize;
      margin: 0 10px;
      padding: 0;
      display: flex;
      color: white;
      align-items: center;
      padding-right: 20px;
      border-color: #7c7c7c !important;
      font-size: 16px;
      span.anticon {
        height: 100%;
        border-right: 1px solid #7c7c7c !important;
        padding: 0 10px;
        align-items: center;
        display: flex;
      }
      span:not(.anticon) {
        margin-left: 20px;
      }
    }

    .ant-btn-primary:not([disabled]):hover,
    .ant-btn-primary:not([disabled]):focus {
      background: transparent !important;
      color: white !important;
      border-color: #7c7c7c !important;
    }

    .filter-title {
      padding: 0 5px 0 15px;
    }
  }
`;

export { FilterDashboardDataStyled };
