import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Redirect,
  Link,
} from "react-router-dom";
import { CreateQrCodeStyled } from "./styled";
import { Typography, Radio, Button, message } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { observable, action, computed } from "mobx";
import { observer, inject } from "mobx-react";
import GenerateQrDetails from "./components/GenerateQrDetails/GenerateQrDetails";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import QrCodesStore from "../../../../stores/qr-codes/qr-codes.store";
import { CheckOutlined, CloseOutlined, StopOutlined } from "@ant-design/icons";

const { Title } = Typography;

interface QrsDataItem {
  distributer: number | string;
  quantity: number | string;
}

interface QrsData {
  category_id: number;
  items: QrsDataItem[];
}
interface Props extends RouteComponentProps<any> {
  qrCodeStore: QrCodesStore;
}
@inject("qrCodeStore")
@observer
class CreateQrCode extends React.PureComponent<Props, any> {
  @observable loading: boolean = false;
  @observable uploaded: boolean = false;
  @observable group: QrsData = {
    category_id: 0,
    items: [],
  };
  @observable method: "Manually" | "Upload from CSV/Excel file" = "Manually";
  methodOptions = ["Manually", "Upload from CSV/Excel file"];

  @action setMethod = (event: RadioChangeEvent) => {
    this.method = event.target.value;
  };

  @computed get displayUploadComponent(): boolean {
    return this.method === "Upload from CSV/Excel file";
  }

  onRemoveDistributer = (index: number) => {
    this.group.items.splice(index, 1);
  };

  onAddDistributer = (distributerDetails: any) => {
    this.group.items.push(distributerDetails);
    const element: any = document.querySelector("[title='Remove file']");
    element?.click();
  };
  onChangeCategory = (category_id: number) => {
    this.group.category_id = category_id;
  };

  create = async () => {
    try {
      this.loading = true;
      const { promise } = this.props.qrCodeStore.createGroup(this.group);
      const response = await promise;
      const data = await response.json();
      this.props?.qrCodeStore?.onCreateGroup(data);

      if (response.status === 200) {
        message.success({
          content: "Success generate new QR codes",
          // duration: 1000000,
          className: "wiser-message landing-page-message",
          icon: <CheckOutlined />,
        });
        this.props.history.push("/qr-code-managers/");
      }
    } catch (error) {
      message.error({
        content: "Something went wrong when create new QR code.",
        // duration: 1000000,
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
      console.error(error);
    }
    this.loading = false;
  };

  render() {
    return (
      <CreateQrCodeStyled>
        <Title level={2}>Generate New QR Code</Title>
        {/* <Title level={3} className="white">
          Method
        </Title> */}
        <div>
          {/* <div className="marginHor20">
            <Radio.Group
              options={this.methodOptions}
              onChange={this.setMethod}
              value={this.method}
            />
          </div> */}
          <div className="inputs-wrapper paddingHor20">
            {this.displayUploadComponent ? (
              this.uploaded ? (
                <Redirect to={"/qr-code-managers"} />
              ) : (
                <div className="flex relative">
                  <UploadFile
                    title={"Upload CSV/Excel file"}
                    accept={
                      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    }
                    multiple={false}
                    action={`${process.env.REACT_APP_API_URL}/qr-codes/import`}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        this.uploaded = true;
                        message.success({
                          content: "Successfully to import QR",
                          // duration: 1000000,
                          className: "wiser-message landing-page-message",
                          icon: <CheckOutlined />,
                        });
                      }
                    }}
                  />

                  <a
                    href="/files/qr_series_template.csv"
                    className="download-template"
                    target="_blank"
                  >
                    CSV Template
                  </a>
                </div>
              )
            ) : (
              <GenerateQrDetails
                loading={this.loading}
                group={this.group}
                onChangeCategory={this.onChangeCategory}
                onRemoveDistributer={this.onRemoveDistributer}
                onAddDistributer={this.onAddDistributer}
              />
            )}
          </div>

          <div
            className={
              this.displayUploadComponent ? "marginHor20 flex" : "flex"
            }
          >
            <Link to="/qr-code-managers">
              <Button className="br  gray radius marginVer10 lineHeight0">
                <StopOutlined /> Cancel
              </Button>
            </Link>

            <Button
              className="br-green green b-gray"
              onClick={this.create}
              loading={this.loading}
              disabled={this.loading || !this.group.items.length}
            >
              Generating QR Codes
            </Button>

            <a
              href="/files/qr_series_template.csv"
              className="download-template"
              target="_blank"
            >
              CSV Template
            </a>
          </div>
        </div>
      </CreateQrCodeStyled>
    );
  }
}
export default withRouter(CreateQrCode);
