import styled from "styled-components";

const ProductDetailsStyled = styled.div`
  .ant-input-number {
    width: 100% !important;
  }
  .ant-row.ant-form-item {
    display: block !important;
    margin-right: 0 !important;
  }
`;

export { ProductDetailsStyled };
