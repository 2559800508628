import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { DemolandingPageStyled } from "./styled";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import { Input, Button, message, Radio, Form, DatePicker, Select } from "antd";
import LandingPagesStore from "../../../../stores/landing-pages/landing-page.store";
import { LeftSquareOutlined } from "@ant-design/icons";
import AuthStore from "../../../../stores/auth/auth.store";
const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
  landingPagesStore?: LandingPagesStore;
}

@inject("authStore", "landingPagesStore")
@observer
class DemolandingPage extends React.PureComponent<Props, any> {
  @observable data: any = {};
  @observable scanValid: boolean = true;
  @observable purchase_location: string = "online";

  formFileds: any = [
    "Please Select",
    "Online advertising",
    "Online search",
    "From a friend",
    "Tv ad",
    "Newspaper ad",
  ];

  componentDidMount = async () => {
    var url = window.location.href;
    var parts = url.split("/");
    const page_id = parts[parts.length - 2];

    try {
      const { promise } = await this.props.landingPagesStore!.fetchOne(
        Number(page_id)
      );
      const response: any = await promise;
      const { status } = response;
      if (status === 200) {
        const data = await response.json();
        this.data = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  generateForm(fields: any) {
    if (!fields) return;
    let form: any = <div className="" />;
    for (let i: number = 0; i < fields.length; i++) {
      switch (fields[i]) {
        case "First Name":
          form = [
            form,
            <Form.Item label="First Name" name="first_name">
              <Input type="text" />
            </Form.Item>,
          ];

          break;

        case "Last Name":
          form = [
            form,
            <Form.Item label="Last Name" name="last_name">
              <Input type="text" />
            </Form.Item>,
          ];

          break;

        case "Phone":
          form = [
            form,
            <Form.Item label="Phone" name="phone">
              <Input type="phone" />
            </Form.Item>,
          ];
          break;

        case "Mobile Phone":
          form = [
            form,

            <Form.Item label="Mobile Phone" name="mobile_phone">
              <Input type="mobile_phone" />
            </Form.Item>,
          ];
          break;

        case "Email":
          form = [
            form,
            <Form.Item label="Email" name="email">
              <Input type="email" />
            </Form.Item>,
          ];
          break;

        case "Address":
          form = [
            form,
            <Form.Item label="Address" name="address">
              <Input type="text" />
            </Form.Item>,
          ];

          break;

        case "Where did you purchase the product?":
          form = [
            form,
            <>
              <Form.Item
                label="Where did you purchase the product?"
                name="purchase_location"
              >
                <Radio.Group
                  className="flex"
                  onChange={(event: any) => {
                    this.purchase_location = event.target.value;
                  }}
                  value={this.purchase_location}
                >
                  <Radio.Button value="online">Online</Radio.Button>
                  <Radio.Button value="local">Local Store</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {this.purchase_location === "online" ? (
                <>
                  <Form.Item
                    label="On which website did you make this purchase?"
                    name="which_site"
                  >
                    <Input type="text" />
                  </Form.Item>

                  <Form.Item
                    rules={[
                      { required: true, message: "Please add the seller name" },
                    ]}
                    label="What is the seller name?"
                    name="seller_name"
                  >
                    <Input type="text" />
                  </Form.Item>

                  <Form.Item
                    label="When did you purchase the product?"
                    name="when_did_purchase"
                  >
                    <DatePicker format={"MM/DD/YYYY"} />
                  </Form.Item>
                </>
              ) : (
                <Form.Item label="What is the store name?" name="store_name">
                  <Input type="text" />
                </Form.Item>
              )}
            </>,
          ];

          break;

        case "What was the price that you paid for this product?":
          form = [
            form,
            <Form.Item
              label="What was the price that you paid for this product?"
              name="purchase_price"
            >
              <Input type="text" />
            </Form.Item>,
          ];

          break;

        case "Where did you hear about this product?":
          form = [
            form,

            <Form.Item
              label="Where did you hear about this product?"
              name={"purchase_hear_about"}
            >
              <Select
                placeholder=""
                optionFilterProp="children"
                // onChange={() => {}}
                onFocus={() => {}}
                onBlur={() => {}}
                onSearch={() => {}}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.formFileds.map((filed: any, i: number) => (
                  <Option value={filed} key={i}>
                    {filed}
                  </Option>
                ))}
              </Select>
            </Form.Item>,
          ];

          break;
      }
    }

    form = [
      form,
      <div>
        <Button className="transparent radius" disabled>
          SUBMIT
        </Button>
      </div>,
    ];

    return form;
  }

  changePage = (e: any) => {
    if (e.target.value === "valid") {
      this.scanValid = true;
    } else {
      this.scanValid = false;
    }
  };

  render() {
    let LandingPage = this.data;
    if (LandingPage?.redirect_url) {
      window.open(LandingPage.redirect_url, "_blank");
    }

    return (
      <DemolandingPageStyled>
        <div className="demo-header flex">
          <Link className="back" to="/landing-pages">
            <LeftSquareOutlined />
          </Link>

          <Radio.Group className="flex1" onChange={this.changePage}>
            <Radio.Button value="invalid">Invalid</Radio.Button>
            <Radio.Button value="valid">Valid</Radio.Button>
          </Radio.Group>
        </div>
        {LandingPage ? (
          <div className="scan-page">
            <div className="header">
              <div className="header-content">
                {/* {this.props!.authStore!.user?.subscription?.logo ? (
                  <img
                    alt="svg"
                    src={this.props!.authStore!.user?.subscription?.logo}
                    className="company-logo"
                  />
                ) : null}


                
                {LandingPage[
                  this.scanValid ? "valid_page_photo" : "inValid_page_photo"
                ] && (
                  <img
                    alt="1"
                    className="scan-image"
                    src={
                      LandingPage[
                        this.scanValid
                          ? "valid_page_photo"
                          : "inValid_page_photo"
                      ]
                    }
                  />
                )} */}

                {this.props!.authStore!.user?.subscription?.logo ? (
                  <img
                    alt="svg"
                    src={this.props!.authStore!.user?.subscription?.logo}
                    className="company-logo"
                  />
                ) : null}

                {this.scanValid &&
                LandingPage["valid_page_photo"] !== "undefined" &&
                !!LandingPage["valid_page_photo"] ? (
                  <img
                    alt="#123"
                    className="scan-image"
                    src={LandingPage["valid_page_photo"]}
                  />
                ) : null}

                {!this.scanValid &&
                LandingPage["inValid_page_photo"] !== "undefined" &&
                !!LandingPage["inValid_page_photo"] ? (
                  <img
                    alt="#123"
                    className="scan-image"
                    src={LandingPage["inValid_page_photo"]}
                  />
                ) : null}

                <div
                  className="f55 title"
                  style={{ color: LandingPage.color_title }}
                >
                  {this.scanValid
                    ? LandingPage.valid_page_title
                    : LandingPage.inValid_page_title}
                </div>
                {(LandingPage.valid_page_sub_title ||
                  LandingPage.inValid_page_sub_title) && (
                  <div
                    className="f35 title"
                    style={{ color: LandingPage.color_title }}
                  >
                    {this.scanValid
                      ? LandingPage.valid_page_sub_title
                      : LandingPage.inValid_page_sub_title}
                  </div>
                )}
              </div>
            </div>
            <div className="body">
              <div className="box-message">
                {this.scanValid
                  ? this.generateForm(LandingPage.valid_page_qa)
                  : this.generateForm(LandingPage.inValid_page_qa)}
              </div>
              <div className="footer">
                <div className="flex">
                  <div>Powered by</div>
                  <img alt="svg" src="/images/wiser-logo.png" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </DemolandingPageStyled>
    );
  }
}
export default withRouter(DemolandingPage);
