import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ProductDetailsStyled } from "./styled";
import CategoriesStore from "../../../../../../stores/categories/category.store";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import { Input, Select, Typography, Form } from "antd";
import Category from "../../../../../../stores/categories/category.model";

const { Title } = Typography;
const { Option, OptGroup } = Select;

interface Props extends RouteComponentProps<any> {
  onChangeData: (data: any) => any;
  categoriesStore?: CategoriesStore;
  isUpdate: boolean;
  category: Category;
  formRef: any;
}

@inject("categoriesStore")
@observer
class ProductDetails extends React.PureComponent<Props, any> {
  @observable data: any = { child_number: 3 };

  constructor(props: Props) {
    super(props);

    this.fetchCategories();
  }

  fetchCategories = async () => {
    try {
      await this.props!.categoriesStore!.fetch({ child_number: [2] });
    } catch (error) {
      console.error(error);
    }
  };

  @action onSelectParent = (value: number) => {
    const selected = this.props.categoriesStore?.models.find(
      (category: any) => category.id === Number(value)
    );
    if (selected) {
      //this.props.onChangeData({ parent_id: selected.id });
      this.props.category._updateData({ parent_id: selected.id });
    }
  };

  componentDidMount = () => {
    if (this.props.isUpdate) {
      this.fetchCategory(Number(this.props.match.params.id));
    }
  };

  @action fetchCategory = async (id: number) => {
    const { promise } = this.props.categoriesStore!.fetchOne(id);
    const response: any = await promise;
    const { status } = response;

    if (status === 200) {
      this.data = await response.json();
      this.props.onChangeData(this.data);
      this.props.formRef?.current?.setFieldsValue(this.data);
    }
  };

  render() {
    const { category, categoriesStore } = this.props;
    // const currentParentCategory = categoriesStore!.allItems.find(
    //   (_category: any) => {
    //     return _category.id === category.parent_id;
    //   }
    // );

    const { categories, subCategories } = categoriesStore!.separateCategoryTree(
      categoriesStore!.productParents
    );
    return (
      <ProductDetailsStyled>
        <Title level={3} className="white">
          Type
        </Title>
        <div className="marginHor20">
          <Form.Item
            label="Product Name"
            name="name"
            rules={[{ required: true, message: "Product name is required" }]}
          >
            <Input
              value={category.name || ""}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                category._updateData({ name: event.target.value });
              }}
            />
          </Form.Item>
          <div className="marginHor20">
            <Form.Item
              label="Select Sub-Category"
              name="parent_id"
              rules={[{ required: true, message: "Parent name is required" }]}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder=""
                optionFilterProp="children"
                onSelect={this.onSelectParent}
                onChange={() => {}}
                onFocus={() => {}}
                onBlur={() => {}}
                onSearch={() => {}}
                value={category.parent_id || undefined}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {/* {categories.map((category: any) => (
                  <Option value={category.id} key={category.id}>
                    {category.name}
                  </Option>
                ))} */}

                {categories.length && (
                  <OptGroup label="Category">
                    {categories.map((row: any) => (
                      <Option value={row.id} key={row.id}>
                        {row.name}
                      </Option>
                    ))}
                  </OptGroup>
                )}
                {subCategories.length && (
                  <OptGroup label="Sub Category">
                    {subCategories.map((row: any) => (
                      <Option value={row.id} key={row.id}>
                        {row.name}
                      </Option>
                    ))}
                  </OptGroup>
                )}
              </Select>
            </Form.Item>
          </div>
        </div>
      </ProductDetailsStyled>
    );
  }
}
export default withRouter(ProductDetails);
