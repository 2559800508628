import styled from "styled-components";

const FilterDataStyled = styled.div`
  .ant-radio-wrapper {
    width: 100%;
    margin-top: 13px;
  }
  .date {
    border-right: 1px solid #676767;
    padding-right: 35px !important;
    width: 250px;
    margin-right:25px; !important
  }

  /* .fileds {
    padding-left: 25px !important;
  } */

  .ant-radio-inner::after {
    background-color: ${(props) => props.color || "#3b4149"};
  }
`;

export { FilterDataStyled };
