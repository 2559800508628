
	import BaseService from "../base.service";

	class ActivitiesService extends BaseService {
		constructor(baseUrl: string = "") {
		super(baseUrl);
		}
	}
	
	export default ActivitiesService;
		
	