/* eslint-disable @typescript-eslint/no-useless-constructor */
import ActivitiesService from "./Activities.service";
import Activities from "./Activities.model";
import PageStore from "../page.store";

class ActivitiesStore extends PageStore {
  constructor(activitiesService: ActivitiesService) {
    super(activitiesService);
  }

  url(): string {
    return "/activities";
  }

  get model(): any {
    return Activities;
  }
}

export default ActivitiesStore;
