/* eslint-disable @typescript-eslint/no-useless-constructor */
import SuperAdminDashboardService from "./SuperAdminDashboard.service";
import PageStore from "../page.store";
import { observable } from "mobx";

interface IBoxInfo {
  qrCount: number;
  distributorsCount: number;
  categoriesCount: number;
  subCategoriesCount: number;
  landingPagesCount: number;
  subscribersCount: number;
}

class SuperAdminDashboardStore extends PageStore {
  @observable loadingBoxInfo: boolean = false;
  @observable boxInfo: IBoxInfo = {
    qrCount: 0,
    distributorsCount: 0,
    categoriesCount: 0,
    subCategoriesCount: 0,
    landingPagesCount: 0,
    subscribersCount: 0,
  };

  constructor(SuperAdminDashboardService: SuperAdminDashboardService) {
    super(SuperAdminDashboardService);
  }

  url(): string {
    return "/SuperAdminDashboard";
  }

  get model(): any {
    return {};
  }

  fetchBoxInfo = async () => {
    try {
      this.loadingBoxInfo = true;
      const response = await this.apiService.get(
        "/superAdminDashboard/info-counter"
      ).promise;
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }
      this.boxInfo = data as IBoxInfo;
    } catch (error) {
      console.error(error);
    }
    this.loadingBoxInfo = false;
  };
}

export default SuperAdminDashboardStore;
