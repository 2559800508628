import React from "react";
import {
  withRouter,
  RouteComponentProps,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import ProductsScans from "../ProductsScans/ProductsScans";
import Distributors from "../Distributors/Distributors";
import LandingPages from "../LandingPages/LandingPages";
import Subscribers from "../Subscribers/Subscribers";
import Products from "../Products/Products";
import Categories from "../Categories/Categories";
import QrCodes from "../QrCodes/QrCodes";
import ActivityLog from "../ActivityLog/ActivityLog";
import Users from "../Users/Users";
import Alerts from "../Alerts/Alerts";
import Leads from "../Leads/Leads";
import AlertsSettings from "../Alerts/components/AlertsSettings/AlertsSettings";
import CreateQrCode from "../QrCodes/components/CreateQrCode/CreateQrCode";
import CreateLandingPages from "../LandingPages/components/CreateLandingPages/CreateLandingPages";
import CreateProduct from "../Products/components/CreateProduct/CreateProduct";
import CreateDistributor from "../Distributors/components/CreateDistributor/CreateDistributor";
import CreateCategory from "../Categories/components/CreateCategory/CreateCategory";
import CreateSubscriber from "../Subscribers/components/CreateSubscriber/CreateSubscriber";
import CreateUser from "../Users/components/CreateUser/CreateUser";
import EditUser from "../Users/components/EditUser/EditUser";
import DemolandingPage from "../LandingPages/components/DemolandingPage/DemolandingPage";

interface Props extends RouteComponentProps<any> {}

class PagesRouter extends React.PureComponent<Props, any> {
  render() {
    return (
      <Switch>
        <Route
          path="/categories"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={Categories} exact />
              <Route
                path={`${url}/:id/edit/`}
                component={CreateCategory}
                exact
              />
              <Route path={`${url}/create`} component={CreateCategory} exact />
            </>
          )}
        />

        <Route
          path="/distributors"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={Distributors} exact />
              <Route
                path={`${url}/:id/edit/`}
                component={CreateDistributor}
                exact
              />
              <Route
                path={`${url}/create`}
                component={CreateDistributor}
                exact
              />
            </>
          )}
        />

        <Route
          path="/products"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={Products} exact />
              <Route
                path={`${url}/:id/edit/`}
                component={CreateProduct}
                exact
              />

              <Route path={`${url}/create`} component={CreateProduct} exact />
            </>
          )}
        />

        <Route
          path="/qr-code-managers"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={QrCodes} exact />
              <Route path={`${url}/create`} component={CreateQrCode} exact />
            </>
          )}
        />

        <Route
          path="/landing-pages"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={LandingPages} exact />
              <Route
                path={`${url}/:id/edit/`}
                component={CreateLandingPages}
                exact
              />
              <Route
                path={`${url}/create`}
                component={CreateLandingPages}
                exact
              />

              <Route
                path={`${url}/demo/:id`}
                component={DemolandingPage}
                exact
              />
            </>
          )}
        />

        <Route
          path="/subscribers"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={Subscribers} exact />
              <Route
                path={`${url}/:id/edit/`}
                component={CreateSubscriber}
                exact
              />
              <Route
                path={`${url}/create`}
                component={CreateSubscriber}
                exact
              />
            </>
          )}
        />

        <Route
          path="/users"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={Users} exact />
              <Route path={`${url}/create`} component={CreateUser} exact />
              <Route path={`${url}/:id/edit/`} component={EditUser} exact />
            </>
          )}
        />

        <Route
          path="/alerts"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={Alerts} exact />
              <Route
                path={`${url}/settings`}
                component={AlertsSettings}
                exact
              />
            </>
          )}
        />

        <Route path={`/dashboard`} component={Dashboard} exact />
        <Route path={`/product-scans`} component={ProductsScans} exact />
        <Route path={`/activity-log`} component={ActivityLog} exact />
        <Route path={`/leads`} component={Leads} exact />
        <Route
          path="/"
          render={({ match: { url } }) => (
            <>
              <Redirect to="/dashboard" />
            </>
          )}
        />
      </Switch>
    );
  }
}
export default withRouter(PagesRouter);
