import styled from "styled-components";

const QrDetailItemStyled = styled.div`
  margin: 5px 0;
  &:not(:nth-child(2)) {
    border-top: 1px solid #2f353a;
    padding-top: 20px;
    margin-top: 20px;
  }
  .ok {
    svg {
      color: #5edc89;
      fill: #5edc89;
    }
  }

  .f21.white {
    font-size: 14px !important;
    position: relative;
    left: -30px;
  }
  .marginBottom5 {
    display: inline-block;
    margin-bottom: 10px;
  }

  .or {
    margin-top: 37px;
    position: relative;
    left: -11px;
    align-self: flex-start;
  }

  .ant-btn.b-grayM.br.br-grayM.white.radius.marginTop10.ant-btn-lg {
    padding-top: 3px;
  }
`;

export { QrDetailItemStyled };
