import { observable, runInAction, action, computed, reaction } from "mobx";
import BaseStore from "./base.store";
import parseObjectToUrlParams from "../utils/parseObjectToUrlParams";
import { OptionsRequest } from "../services/base.http.service";
import BaseModel from "./base.model";

export type fetchPageResponse = {
  total: number;
  items: any[];
};
abstract class PageStore extends BaseStore {
  readonly DEFAULT_PAGE_SIZE = 10;
  readonly pageSizeOptions: any = ["10", "30", "40", "100"];
  @observable selectedRows: any = {};
  @observable pageSize: number = this.DEFAULT_PAGE_SIZE;

  @observable filter: any = {};
  @observable fetchPageLoading: boolean = false;

  /**
   * Hold loading when fetch loading react to search input checked
   */
  @observable searchLoading: boolean = false;

  abortFetchPageRequest: Function | undefined | null;

  constructor(props: any) {
    super(props);
    reaction(
      () => this.filter,
      (filter, reaction) => {
        this.fetchPage({
          params: { filter: JSON.parse(JSON.stringify(filter)) },
          customPath: filter.path,
        });
      }
    );
  }

  @action onFilterChanged = (filter: any) => {
    this.filter = filter;
  };

  /**
   * Total items
   */
  @observable total: number = 0;
  @observable currentPageNumber: number = 1;
  @observable pages: { [key: string]: any } = {};

  async fetchPage({
    page = 1,
    pageSize = this.DEFAULT_PAGE_SIZE,
    startTime,
    endTime,
    params,
    filter,
    customPath,
  }: {
    page?: number;
    pageSize?: number;
    startTime?: number | undefined;
    endTime?: number | undefined;
    params?: any;
    filter?: any;
    customPath?: string;
  }): Promise<OptionsRequest> {
    // pageSize = Math.max(this.DEFAULT_PAGE_SIZE, pageSize);
    pageSize = pageSize < 1 ? 10 : pageSize;
    pageSize = Math.max(0, pageSize);
    page = Math.max(1, page);
    const offset = Math.max(0, (page - 1) * pageSize - 1);
    const limit = pageSize;
    let url = `${
      customPath ? customPath : this.url()
    }?offset=${offset}&limit=${limit}`;

    startTime && (url = `${url}&startTime=${startTime}`);
    endTime && (url = `${url}&endTime=${endTime}`);
    params && (url = `${url}&${parseObjectToUrlParams(params || {})}`);
    filter &&
      Object.keys(filter).length &&
      (url = `${url}&${parseObjectToUrlParams({ filter } || {})}`);

    // url = url.replace(/filter=&/g, "");

    if (this.abortFetchPageRequest) this.abortFetchPageRequest();

    const { promise, abort } = this.apiService.get(url);

    this.abortFetchPageRequest = abort;

    // if searchLoading = true filter fetch page react from search input
    if (!this.searchLoading) {
      this.fetchPageLoading = true;
    }

    promise
      .then(async (response: any) => {
        if (this.abortFetchPageRequest) this.abortFetchPageRequest = null;
        if (this.fetchPageLoading) this.fetchPageLoading = false;
        if (this.searchLoading) this.searchLoading = false;

        const { status } = response;

        if (status === 200) {
          const dataKey = this.url().replace("/", "");
          const data = await response.clone().json();

          runInAction(() => {
            this.pages[page] = data[dataKey].map((data: any) =>
              this.create(data)
            );
            this.total = data.total;
            this.currentPageNumber = page;
            this.pageSize = pageSize;
          });
        }
      })
      .catch((rejected: any) => rejected);

    return { promise, abort };
  }

  @action setCurrentPageNumber = (page: number) => {
    this.currentPageNumber = page;
  };

  @computed get currentPage() {
    return this.pages[this.currentPageNumber] || [];
  }

  @action
  clearFilters = () => {
    return (this.filter = {});
  };

  @action del = (model: BaseModel) => {
    runInAction(() => {
      if (this.pages[this.currentPageNumber]) {
        this.pages[this.currentPageNumber] = this.pages[
          this.currentPageNumber
        ].filter((m: any) => m.id !== model.id);
      }
      if (this.models.length) {
        this.models = this.models.filter((m: any) => m.id !== model.id);
      }

      if (this.allItems.length) {
        this.allItems = this.allItems.filter((m: any) => m.id !== model.id);
      }
      console.log("after", this.allItems.length);
    });
  };
}

export default PageStore;
