import React, { ChangeEvent } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { CreateCategoryStyled } from "./styled";
import { Typography, message, Radio, Form, Button, Input, Select } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { observable, action, computed, runInAction, reaction } from "mobx";
import { observer, inject } from "mobx-react";
import CategoriesStore from "../../../../stores/categories/category.store";
import Category from "../../../../stores/categories/category.model";
import { FormInstance } from "antd/lib/form";
import {
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  categoriesStore: CategoriesStore;
}

enum Type {
  "Category" = "Category Name",
  "Sub Category" = "Sub Category Name",
}

@inject("categoriesStore")
@observer
class CreateCategory extends React.PureComponent<Props, any> {
  @observable type: Type = Type.Category;
  @observable data: any = {};
  @observable isUpdate: boolean = false;
  category: Category = this.props.categoriesStore.create();
  @observable uploaded: boolean = false;
  @observable fetchDataLoading: boolean = false;
  @observable loading: boolean = false;

  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.isUpdate =
      typeof props.match.params.id !== "undefined" &&
      props.match.params.id !== "create";

    reaction(
      () => this.isSubCategory,
      async (isSubCategory, reaction) => {
        if (isSubCategory) {
          try {
            await this.props!.categoriesStore!.fetch({ child_number: [1] });
            reaction.dispose();
          } catch (error) {
            console.error(error);
          }
        }
      }
    );
  }

  typeOptions = [Type.Category, Type["Sub Category"]];

  @action setType = (type: Type) => {
    this.type = type;
  };

  @computed get isSubCategory(): boolean {
    return this.type === Type["Sub Category"];
  }

  @action onSelectParent = (value: number) => {
    const selected = this.props.categoriesStore?.models.find(
      (category: any) => category.id === Number(value)
    );
    if (selected) {
      this.category?._updateData({ parent_id: selected.id });
    }
  };

  componentDidMount = () => {
    if (this.isUpdate) {
      this.fetchCategory(Number(this.props.match.params.id));
    }
  };

  @action fetchCategory = async (id: number) => {
    try {
      this.fetchDataLoading = true;
      const response = await this.props.categoriesStore!.fetchOne(id).promise;
      const { status } = response;

      if (status === 200) {
        const data = await response.json();
        this.formRef?.current?.setFieldsValue(data);
        runInAction(() => this.category._updateData(data));
        this.setType(
          this.category.parent_id ? Type["Sub Category"] : Type["Category"]
        );
      }
    } catch (error) {}

    this.fetchDataLoading = false;
  };

  @action save = async () => {
    this.category._updateData({
      child_number: this.category.parent_id ? 2 : 1,
    });

    try {
      const response = await this.category.save().promise;
      const data = await response.clone().json();
      if (data.code && data.code === 400) {
        message.error({
          content: data.message,
          // duration: 1000000,
          className: "wiser-message subscriber-message",
          icon: <CloseOutlined />,
        });
        return;
      }
      this.category._updateData(data);
      message.success({
        content: "Success to create new category",
        // duration: 1000000,
        className: "wiser-message landing-page-message",
        icon: <CheckOutlined />,
      });

      if (!this.isUpdate) {
        this.props.categoriesStore.allItems.push(this.category);
      } else {
        this.props.categoriesStore.onUpdateCategory(this.category.toJson);
      }
      this.category = this.props.categoriesStore.create();
      this.props.history.push("/categories/");
    } catch (error) {
      message.error({
        content: "Something went wrong when create new category.",
        // duration: 1000000,
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
      console.error(error);
    }
  };

  render() {
    return (
      <CreateCategoryStyled>
        <Title level={2}>{this.isUpdate ? "Update" : "Add New"} Category</Title>

        <div>
          <div className="inputs-wrapper paddingHor20">
            <Title level={3} className="white">
              Type
            </Title>
            <div className="marginHor20">
              <Radio.Group
                options={this.typeOptions}
                onChange={(event: RadioChangeEvent) =>
                  this.setType(event.target.value)
                }
                value={this.type}
              />
            </div>
            <Form
              ref={this.formRef}
              name="control-ref"
              onFinish={(values: any) => this.save()}
            >
              <div className="marginHor20">
                <Form.Item
                  label={this.isSubCategory ? "Sub Category Name" : "Name"}
                  name="name"
                  rules={[
                    { required: true, message: "Category Name is required" },
                  ]}
                >
                  <Input
                    value={this.category.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      this.category._updateData({
                        name: event.target.value,
                      });
                    }}
                  />
                </Form.Item>
                {this.isSubCategory && (
                  <>
                    <Form.Item
                      label="Category Name"
                      name="parent_id"
                      rules={[
                        { required: true, message: "Parent name is required" },
                      ]}
                    >
                      <Select
                        className="full"
                        showSearch
                        style={{ width: 200 }}
                        placeholder=""
                        optionFilterProp="children"
                        onSelect={this.onSelectParent}
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.props.categoriesStore?.models.map(
                          (category: any) => (
                            <Option value={category.id} key={category.id}>
                              {category.name}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </>
                )}
              </div>
              <Link to="/categories">
                <Button className="br  gray radius marginVer10 lineHeight0">
                  <StopOutlined /> Cancel
                </Button>
              </Link>

              <Button
                disabled={this.loading}
                htmlType="submit"
                type="primary"
                className="br-yellow yellow b-gray"
                // onClick={this.isUpdate ? this.update : this.create}
              >
                {this.loading ? (
                  <LoadingOutlined />
                ) : !this.isUpdate ? (
                  <PlusOutlined />
                ) : (
                  <SaveOutlined />
                )}
                Save
              </Button>
            </Form>
          </div>
        </div>
      </CreateCategoryStyled>
    );
  }
}

export default withRouter(CreateCategory);
