import { CloseOutlined } from "@ant-design/icons";
import { Form, Input, message, Select, Typography } from "antd";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import UploadFile from "../../../../../../components/UploadFile/UploadFile";
import CategoriesStore from "../../../../../../stores/categories/category.store";
import LandingPagesStore from "../../../../../../stores/landing-pages/landing-page.store";
import { LandingPagesDetailsStyled } from "./styled";
const { Option } = Select;
const { Title } = Typography;

interface Props extends RouteComponentProps<any> {
  onChangeData: (data: any) => any;
  landingPage?: any;
  landingPagesStore?: LandingPagesStore;
  categoriesStore?: CategoriesStore;
  changeStep: (method: any) => void;
  currentStep: number;
}

@inject("landingPagesStore", "categoriesStore")
@observer
class LandingPagesDetails extends React.PureComponent<Props, any> {
  @observable data: any = {};
  @observable selectedQeustions: any = {};
  @observable currentStep: number = 1;
  formFileds: any = [
    "First Name",
    "Last Name",
    "Phone",
    "Mobile Phone",
    "Email",
    "Address",
    "Where did you purchase the product?",
    "What was the price that you paid for this product?",
    "Where did you hear about this product?",
  ];

  componentDidMount = () => {
    this.data = this.props.landingPage;
  };

  checkValidOrNotValidMethod() {
    if (this.props.currentStep === 1) {
      return true;
    } else {
      return false;
    }
  }

  beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.success({
        content: "You can only upload JPG/PNG file!",
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }

    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      message.success({
        content: "Image must be smaller than 500k!",
        className: "wiser-message subscriber-message",
        icon: <CloseOutlined />,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  render() {
    let key: any = this.checkValidOrNotValidMethod()
      ? "valid_page_photo"
      : "inValid_page_photo";
    let files = [];
    if (this.props!.landingPage[key]) {
      if (typeof this.props!.landingPage[key] === "string") {
        const nameArr = this.props!.landingPage[key].split("/");
        files.push({
          uid: "-xxx",
          name: decodeURI(nameArr[nameArr.length - 1]),
        });
      } else {
        files.push({
          uid: "-xxx",
          name: this.props!.landingPage[key].name,
        });
      }
    }

    console.log(key, this.props!.landingPage[key], files);
    return (
      <LandingPagesDetailsStyled className="details ">
        <div className="f21 white">Use this Landing Page for</div>
        <div className="marginHor20">
          <div className="flex">
            <div
              className={
                "pointer step step1 marginRight100 " +
                (this.props.currentStep === 1 ? "active" : "")
              }
              onClick={() => this.props.changeStep(1)}
            >
              <div className="white f16">STEP1</div>
              <div className="white f15">Valid Scan</div>
            </div>

            <div
              className={
                "step step2 marginRight20 " +
                (this.props.currentStep === 2 ? "active" : "")
              }
              // onClick={() => this.props.changeStep(2)}
            >
              <div className="white f16">STEP2</div>
              <div className="white f15">Invalid Scan</div>
            </div>
          </div>
          {/* <Radio.Group
            options={this.methodOptions}
            onChange={this.setMethod}
            value={this.method}
          /> */}
        </div>

        <div className="marginTop20 marginBottom20">
          <UploadFile
            title={"Upload a Photo (.jpg , .png)"}
            accept={".jpg, .png"}
            fileList={files}
            multiple={false}
            beforeUpload={this.beforeUpload}
            onChange={(file) => {
              let fileObj = file.file;
              let key = this.checkValidOrNotValidMethod()
                ? "valid_page_photo"
                : "inValid_page_photo";

              this.props.onChangeData({
                [key]: fileObj.originFileObj || null,
              });
            }}
          />
        </div>

        <div className="full">
          <Form.Item
            label="Page Heading - Line 1"
            name={
              this.checkValidOrNotValidMethod()
                ? "valid_page_title"
                : "inValid_page_title"
            }
            rules={[{ required: true, message: "Page title required" }]}
          >
            <Input
              className="show"
              value={
                this.checkValidOrNotValidMethod()
                  ? this.data.valid_page_title
                  : this.data.inValid_page_title
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                let key = this.checkValidOrNotValidMethod()
                  ? "valid_page_title"
                  : "inValid_page_title";
                this.data = {
                  ...this.data,
                  [key]: event.target.value,
                };
                this.props.onChangeData({ [key]: event.target.value });
              }}
            />
          </Form.Item>

          <Form.Item
            className="not-require"
            label="Page Heading - Line 2"
            name={
              this.checkValidOrNotValidMethod()
                ? "valid_page_sub_title"
                : "inValid_page_sub_title"
            }
          >
            <Input
              className="show"
              value={
                this.checkValidOrNotValidMethod()
                  ? this.data.valid_page_sub_title
                  : this.data.inValid_page_sub_title
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                let key = this.checkValidOrNotValidMethod()
                  ? "valid_page_sub_title"
                  : "inValid_page_sub_title";
                this.data = {
                  ...this.data,
                  [key]: event.target.value,
                };
                this.props.onChangeData({ [key]: event.target.value });
              }}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            label="Select Questions to Display on Page"
            name={
              this.checkValidOrNotValidMethod()
                ? "valid_page_qa"
                : "inValid_page_qa"
            }
            rules={[{ required: true, message: "Questions required" }]}
          >
            <Select
              placeholder=""
              optionFilterProp="children"
              mode="multiple"
              onChange={(values: any) => {
                let key = this.checkValidOrNotValidMethod()
                  ? "valid_page_qa"
                  : "inValid_page_qa";

                this.data = {
                  ...this.data,
                  [key]: values,
                };
                this.props.onChangeData({ [key]: values });
              }}
              // onChange={() => {}}
              onFocus={() => {}}
              onBlur={() => {}}
              onSearch={() => {}}
              value={
                this.checkValidOrNotValidMethod()
                  ? this.data.valid_page_qa
                  : this.data.inValid_page_qa
              }
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.formFileds.map((filed: any, i: number) => (
                <Option value={filed} key={i}>
                  {filed}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </LandingPagesDetailsStyled>
    );
  }
}
export default withRouter(LandingPagesDetails);
