import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { LeadsStyled } from "./styled";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import moment from "moment";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Divider, AutoComplete, Input, Tag, Tooltip } from "antd";
import Header from "../../components/Header/Header";
import { action, observable, computed } from "mobx";
import LeadsStore from "../../stores/leads/lead.store";
import { inject, observer } from "mobx-react";
import debounce from "../../utils/debounce";
import { TableActionsCellStyled } from "../../styled";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";
import Lead from "../../stores/leads/lead.model";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import FilterPage from "../../components/FilterPage/FilterPage";
import { ReactSVG } from "react-svg";

interface Props extends RouteComponentProps<any> {
  leadsStore?: LeadsStore;
}

@inject("leadsStore")
@observer
class Leads extends React.PureComponent<Props, any> {
  @observable _width: number = 200;
  @observable loading: boolean = false;

  columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (createdAt: any) =>
        moment(new Date(createdAt)).format("MMMM DD [at] hh:mm A"),
      key: "createdAt",
    },
    {
      title: "Distributor's Territory",
      dataIndex: "geo",
      // render: (geo: any) =>
      //   geo
      //     ? `${geo?.city?.name || ""} ${
      //         geo?.country?.name && geo?.city?.name ? ", " : ""
      //       } ${geo?.country?.name}`
      //     : "",
      render: (_leads: any, lead: any) =>
        lead?.qr?.distributor?.territories.join(", "),
      key: "geo",
    },
    {
      title: "Distributor",
      dataIndex: "qr",
      key: "qr",
      render: (qr: any) => qr?.distributor?.name,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category: any) => category?.name || " - ",
    },

    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (subCategory: any) => subCategory?.name || " - ",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product: any) => product?.name || " - ",
    },
    {
      title: "LP Type",
      dataIndex: "scanValid",
      key: "scans",
      render: (scanValid: number) =>
        scanValid ? (
          <Tag color="green">Valid</Tag>
        ) : (
          <Tag color="red">InValid</Tag>
        ),
    },
    {
      width: "80px",
      title: "",
      dataIndex: "actions",
      className: "f16 table_action",
      render: (text: string, record: any) => (
        <TableActionsCellStyled style={{ justifyContent: "flex-start" }}>
          <Divider type="vertical" className={"b-grayM2"} />
          <Tooltip placement="bottom" title={"Link to demo"}>
            <Link to={`/landing-pages/demo/${record.landing_page.id}/`}>
              <img
                src="/images/action/cil-link_blue.svg"
                alt="cil-link_green.svg"
              />
            </Link>
          </Tooltip>

          <Divider type="vertical" className={"b-grayM2"} />

          <Tooltip placement="bottom" title={"Delete"}>
            <img
              src="/images/action/cil-trash_blue.svg"
              alt="123"
              className={"action-icon"}
              onClick={async () => {
                this.props?.leadsStore?.openDeleteItem();
                this.props!.leadsStore!.selectedRecord = record;
              }}
            />
          </Tooltip>
        </TableActionsCellStyled>
      ),
      key: "action",
    },
  ];

  @action setWidth = (width: number) => {
    this._width = width;
  };

  @computed get width() {
    return `${this._width}px`;
  }

  componentDidMount = async () => {
    try {
      await this.props.leadsStore?.fetchPage({ page: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  onPageSettingsChange = async (
    page: number,
    pageSize: number | undefined = 10
  ) => {
    const { leadsStore } = this.props;
    await leadsStore!.fetchPage({ page, pageSize, filter: leadsStore!.filter });
    // router.navigate("templates", { page, pageSize });
  };

  onSearchDebounce = debounce(async (value: string) => {
    this.props.leadsStore!.searchLoading = true;
    this.props.leadsStore!.onFilterChanged({
      ...this.props!.leadsStore!.filter,
      name: value,
    });
  });

  handleExportData = () => {
    let rows = this.props!.leadsStore!.selectedRows.map((row: Lead) => {
      const toJson = row.dataToExport;
      const data: any = {};
      const excludeFields = [
        "group_id",
        "scanValid",
        "createdAt",
        "qr",
        "geo",
        "territory",
      ];
      for (let [key, value] of Object.entries(toJson)) {
        if (!excludeFields.includes(key)) {
          data[`${capitalizeFirstLetter(key).split("_").join(" ")}`] = value;
        }
      }
      data["Scanned In"] = row?.territory;
      data.Category = row.category.name;
      // data.Distributor = row.qr.distributor.name;
      data["Created at"] = moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss");
      data["Status"] = row.scanValid ? "valid" : "invalid";
      return data;
    });
    this.props!.leadsStore!.downloadCsv(rows, "leads");
  };

  renderExpandIcon = ({ expanded, onExpand, record }: any) => {
    return expanded ? (
      <Tooltip placement="bottom" title={"Close"}>
        <ReactSVG
          className="plus_icon"
          onClick={(e) => onExpand(record, e)}
          src="/images/action/close/Blue-Minus.svg"
        />
      </Tooltip>
    ) : (
      <Tooltip placement="bottom" title={"Details"}>
        <ReactSVG
          className="plus_icon"
          onClick={(e) => onExpand(record, e)}
          src="/images/action/PlusIcon_blue.svg"
        />
      </Tooltip>
    );
  };

  convertMachinNameToReadableNames = (value: string) => {
    switch (value) {
      case "when_did_purchase":
        value = "Purchase Date";
        break;

      case "purchase_hear_about":
        value = "Where Heard About";
        break;

      case "which_site":
        value = "Website URL";
        break;

      case "territory":
        value = "Scanned In";
        break;
    }
    value = value.replace("_", " ");
    return value;
  };

  render() {
    const { leadsStore } = this.props;
    return (
      <LeadsStyled>
        <Header
          title={"Leads"}
          rightMenu={
            <div className="flex flexVerCenter">
              <FilterPage
                color={"#63c2de"}
                store={leadsStore}
                filters={["territory", "distributor", "scanValid"]}
              />
              {/* <Popover
                placement="bottom"
                content={
                  <FilterData
                    date={true}
                    filter={["territory", "distributor", "scanValid"]}
                    onFilterChanged={this.props.leadsStore!.onFilterChanged}
                    filterSelected={this.props.leadsStore!.filter}
                  />
                }
                title="Filter Data"
              >
                <Button
                  className="br br-grayM white b-gray radius marginRight10 lineHeight0"
                  size="large"
                >
                  <FilterOutlined />
                  filter
                </Button>
              </Popover> */}
              <AutoComplete
                size={"large"}
                onSearch={this.onSearchDebounce}
                style={{ width: this.width }}
                onBlur={() => this.setWidth(200)}
                onFocus={() => this.setWidth(300)}
                placeholder="Search"
                filterOption={(inputValue: any, option: any) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  suffix={
                    leadsStore?.searchLoading ? (
                      <LoadingOutlined />
                    ) : (
                      <SearchOutlined />
                    )
                  }
                />
              </AutoComplete>
            </div>
          }
        />

        <DynamicTable
          expandIconColumnIndex={8}
          expandIcon={this.renderExpandIcon}
          loading={this.props?.leadsStore?.fetchPageLoading}
          dataSource={this.props?.leadsStore?.currentPage.map((row: any) => {
            row.key = row.id;
            return row;
          })}
          columns={this.columns}
          disableMultiSelectAction={true}
          handleTableAction={(action: any) => {
            let rows = this.props!.leadsStore!.selectedRows;
            let rowsIds = rows.map((row: any) => row.id);

            this.props!.leadsStore!.changeUsersStatus(action, rowsIds);
          }}
          expandedRowRender={(record: any) => (
            <div className="marginCenter padding20" style={{ width: "420px" }}>
              {Object.keys(record)
                .filter(
                  (item) =>
                    record[item]?.length > 1 &&
                    "createdAt group_id".indexOf(item) < 0
                )
                .map((item: any) => (
                  <div className="flex">
                    <div className="f19 bold first capitalize marginRight20">
                      {this.convertMachinNameToReadableNames(item)}:
                    </div>
                    <div className="f19">
                      {item === "territory"
                        ? record?.geo?.country?.name
                        : record[item]}
                    </div>
                  </div>
                ))}
              <div className="flex">
                <div className="f19 bold first capitalize marginRight20">
                  Serial No.
                </div>
                <div> {record.qr.series || " - "} </div>
              </div>

              <div className="flex">
                <div className="f19 bold first capitalize marginRight20">
                  Qr ID
                </div>
                <div> {record.qr.id || " - "} </div>
              </div>
            </div>
          )}
          handleExportData={this.handleExportData}
          handleNumberOfResult={(number_of_results: number) => {
            this.onPageSettingsChange(
              this.props!.leadsStore!.currentPageNumber,
              number_of_results
            );
          }}
          handleRowSelection={(selectedRows: any) => {
            this.props!.leadsStore!.selectedRows = selectedRows;
          }}
          total={this.props!.leadsStore!.total}
          pageSize={this.props!.leadsStore!.pageSize}
          currentPageNumber={this.props!.leadsStore!.currentPageNumber}
          onPageSettingsChange={this.onPageSettingsChange}
        />
        <ConfirmPopup
          visible={this.props!.leadsStore!.visible}
          handledeleteItem={this.props!.leadsStore!.deleteItem}
          handleCancelDeleteItem={this.props!.leadsStore!.cancelDeleteItem}
        />
      </LeadsStyled>
    );
  }
}
export default withRouter(Leads);
