import styled from "styled-components";

const EditUserStyled = styled.div`
  background: #3b4149;
  padding: 20px;
  .ant-avatar {
    margin: 8px 15px 10px 0px;
  }

  .password {
    margin: 3px;
  }

  .ant-form-item {
    width: 300px;
  }

  .edit-permmision {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
`;

export { EditUserStyled };
